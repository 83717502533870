import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import LavaLamp from "../components/lavalamp/LavaLamp"
import PVRShell from "../components/webgl/PVRShell"
import WebGLnavigation2DSim from "../components/WebGLNavigation2DSim"
import WebGLnavigation3DSim from "../components/WebGLNavigation3DSim"
import WebGLSkybox2 from "../components/WebGLSkybox2"
import { Paper } from "@material-ui/core"
import WebGLDeferredShading from "../components/WebGLDeferredShading"
import WebGLFilmTV from "../components/WebGLFilmTV"
import WebGLShaders from "../components/WebGLShaders"

// export const query = graphql`{
//   allFile(filter: {extension: {in: ["vsh","fsh", "pvr"]}}) {
//   edges {
//   node {
//   publicURL
//   id
//   }
//   }
//   }
//   }`



class RealityPage extends React.Component {

  constructor(props) {
    super(props);

    this.data = null;

    this.setData = element => {
      this.data = element;
    };
  }


  
  
componentDidMount() {
  
//   // PVRShell.main(new WebGLWater())
//  PVRShell.main(new WebGLnavigation2DSim(this.data));

this.s1 =  new PVRShell();
this.s2 =  new PVRShell();
this.s3 =  new PVRShell();
this.s4 =  new PVRShell();
this.s5 =  new PVRShell();
this.s6 =  new PVRShell();
 // PVRShell.main(new WebGLWater())
this.chosenCanvas = this.s1;
this.s1.main(new WebGLnavigation2DSim(this.data),"surface", this)
this.s2.main(new WebGLnavigation3DSim(this.data),"surface1",this)
this.s5.main(new WebGLSkybox2(this.data),"surface4",this)
this.s3.main(new WebGLDeferredShading(this.data),"surface2",this)
this.s4.main(new WebGLFilmTV(this.data),"surface3",this)
this.s6.main(new WebGLShaders(this.data),"surface5",this)
// this.s2.main(new WebGLSkybox2(),"surface")
//this.s2.main(new WebGLDeferredShading(),"surface");
//this.s2.main(new WebGLFilmTV(),"surface")
}

handleClick = (e) => {

  switch (e){
    case 1: this.chosenCanvas = "surface" ; break;
    case 2: this.chosenCanvas = "surface1";  break;
    case 3: this.chosenCanvas = "surface2";  break;
    case 4: this.chosenCanvas = "surface3";  break;
    case 5: this.chosenCanvas = "surface4";  break;
    case 6: this.chosenCanvas = "surface5";  break;
    default: break;
  }
 
}


render() {


  const styles = {
    root: {
     // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
      border: 10,
      borderRadius: 3,
      boxShadow: '0 10px 10px 10px rgba(255, 105, 135, .3)',
      color: 0x000000,
    //  height: 48,
      padding: '0px 0px 0px 0px',
    },
    normal: {
      // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
       border: 10,
       borderRadius: 3,
      // boxShadow: '0 10px 10px 10px rgba(255, 105, 135, .3)',
       color: 0x000000,
     //  height: 48,
      // padding: '30px 30px 30px 30px',
     },
  };

  return(<>
  <LavaLamp pos = "4"></LavaLamp>
  <Layout myfunc={this.setData}>
    <SEO title="Page two" />
    <div>
    <canvas id="surface" onClick={this.handleClick.bind(null,1) } style={{ border: 0}} width="800" height="600">Your browser doesn't appear to support the HTML5 element.</canvas>     
    <canvas id="surface1" onClick={this.handleClick.bind(null,2)}  style={{border: 0}} width="800" height="600">Your browser doesn't appear to support the HTML5 element.</canvas>     
    <canvas id="surface2" onClick={this.handleClick.bind(null,3)}  style={{border: 0}} width="800" height="600">Your browser doesn't appear to support the HTML5 element.</canvas>
    <canvas id="surface3" onClick={this.handleClick.bind(null,4)}  style={{border: 0}} width="800" height="600">Your browser doesn't appear to support the HTML5 element.</canvas> 
    <canvas id="surface4" onClick={this.handleClick.bind(null,5)}  style={{border: 0}} width="800" height="600">Your browser doesn't appear to support the HTML5 element.</canvas>
    <canvas id="surface5" onClick={this.handleClick.bind(null,6)}  style={{border: 0}} width="800" height="600">Your browser doesn't appear to support the HTML5 element.</canvas>
    
    </div> 
    {/* <h2>Coming Soon</h2> */}
  </Layout>
  </>
)
}
}

// RealityPage.propTypes = {
//   data: PropTypes.node.isRequired,
// }



export default RealityPage
