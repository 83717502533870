import React from 'react'
import PVRFileStream from "./PVRFileStream"
import {PVRVector3} from './PVRMaths'




// struct PVRTBoundingBox2D
// {
// 	// Min and max coordinates
// 	PVRTVec2 minCoords;


let PVRTOcclusionData = function()
{
	this.position = new PVRVector3();
	this.numRefObjects = 0; //unsigned int 
	this.pRefTile = new Uint32Array();  //unsigned int *
	this.pNumRefObject = new Uint32Array();//  unsigned int *
	this.ppRefObjects = new Uint32Array();// unsigned int  **
};



export  class PVROcclusion  extends React.Component
{



	render(){
        return null
    }


    
 static genPixelTypeH(c1Name, c2Name, c3Name, c4Name)
{
    let val = 0;
    val |= c1Name.charCodeAt();

    if(c2Name !== undefined)
        val |= c2Name.charCodeAt() << 8;

    if(c3Name !== undefined)
        val |= c3Name.charCodeAt() << 16;

    if(c4Name !== undefined)
        val |= c4Name.charCodeAt() << 24;

    return val;
}

static genPixelTypeL(c1Bits, c2Bits, c3Bits, c4Bits)
{
    let val = 0;
    val |= c1Bits;

    if(c2Bits !== undefined)
        val |= c2Bits << 8;

    if(c3Bits !== undefined)
        val |= c3Bits << 16;

    if(c4Bits !== undefined)
        val |= c4Bits << 24;

    return val;
}


static getBitsPerPixel(header)
{
    if(header.pixelFormatH !== 0)
    {
        let lowPart = header.pixelFormatL;
        let c1Bits  = (lowPart >> 24) & 0xFF;
        let c2Bits  = (lowPart >> 16) & 0xFF;
        let c3Bits  = (lowPart >> 8)  & 0xFF;
        let c4Bits  = lowPart & 0xFF;
        return c1Bits + c2Bits + c3Bits + c4Bits;
    }

    // TODO: Compressed texture support.

    return 0;
}

static loadFromURI (uri, i, demo, callback)
{
    let fs = new PVRFileStream();
    let args = [uri, true, PVROcclusion.loadFromMemory, i,  demo,  callback];
    args = args.concat(Array.prototype.slice.call(arguments, 4));
    //console.log("rui-args:", args);
    fs.Open.apply(fs, args);
}
   
static loadFromMemory (stream,  header, demo,  callback)
{
//    let mapfileversion =  stream.ReadUInt32();
//    if (mapfileversion !== MAPFILEIO_VERSION) {return false;}
//    let mina =  stream.ReadFloat32(); //  ReadArrayBuffer( sizeof(layer.boundingbox));
//    let minb =  stream.ReadFloat32(); 
//    let maxa =  stream.ReadFloat32(); 
//    let maxb =  stream.ReadFloat32(); 

  

//    layer.boundingbox = new PVRTBoundingBox2D(mina,minb, maxa,maxb);

//    let mapfilescp =  stream.ReadUInt32();
  
//    if (mapfilescp !== MAPFILEIO_SECURITYCHECKPOINT) {return false;}


//    layer.numVertexDataBuckets = stream.ReadUInt32();    //ReadArrayBuffer( sizeof(layer.numVertexDataBuckets));
   
//    if (layer.numVertexDataBuckets > 0){


//     layer.pVertexDataBuckets = new Array(layer.numVertexDataBuckets);
//     for (let i=0; i < layer.numVertexDataBuckets; i++){
    
//         layer.pVertexDataBuckets[i] = new PVRTVertexDataBucket();

//         let mina =  stream.ReadFloat32(); //  ReadArrayBuffer( sizeof(layer.boundingbox));
//         let minb =  stream.ReadFloat32(); 
//         let maxa =  stream.ReadFloat32(); 
//         let maxb =  stream.ReadFloat32(); 
       
   

//         layer.pVertexDataBuckets[i].boundingbox =  new PVRTBoundingBox2D(mina,minb, maxa,maxb);
//         //stream.ReadArrayBuffer( sizeof(layer.pVertexDataBuckets[i].boundingbox));
       
//          mapfilescp =  stream.ReadUInt32();
    
//         if (mapfilescp !== MAPFILEIO_SECURITYCHECKPOINT) {return false;}
//         layer.pVertexDataBuckets[i].size  = stream.ReadUInt32();   //ReadArrayBuffer( sizeof(layer.pVertexDataBuckets[i].size));

//         if (layer.pVertexDataBuckets[i].size === 0) return false;
//         layer.pVertexDataBuckets[i].pData = stream.ReadArrayBuffer(layer.pVertexDataBuckets[i].size);
//         mapfilescp =  stream.ReadUInt32();
//         if (mapfilescp !== MAPFILEIO_SECURITYCHECKPOINT) {return false;}
// 	 	}
//     }
//     else{
//         layer.pVertexDataBuckets = null;
//     }

//     mapfilescp =  stream.ReadUInt32();
  
//     if (mapfilescp !== MAPFILEIO_SECURITYCHECKPOINT) {return false;}

//     layer.numIndexDataBuckets = stream.ReadUInt32();  //ReadArrayBuffer(sizeof(layer.numIndexDataBuckets));

//     if (layer.numIndexDataBuckets > 0)
// 	{
// 	 	layer.pIndexDataBuckets = new Array(layer.numIndexDataBuckets);
// 	 	for (let i=0; i < layer.numIndexDataBuckets; i++)
// 		{
//             layer.pIndexDataBuckets[i] = new PVRTIndexDataBucket();

//             layer.pIndexDataBuckets[i].bucketindex = stream.ReadUInt32();//  ReadArrayBuffer(sizeof(layer.pIndexDataBuckets[i].bucketindex));
           
//              mapfilescp =  stream.ReadUInt32();
          
//             if (mapfilescp !== MAPFILEIO_SECURITYCHECKPOINT) {return false;}
        
//             let mina =  stream.ReadFloat32(); //  ReadArrayBuffer( sizeof(layer.boundingbox));
//             let minb =  stream.ReadFloat32(); 
//             let maxa =  stream.ReadFloat32(); 
//             let maxb =  stream.ReadFloat32(); 
//             layer.pIndexDataBuckets[i].boundingbox =  new PVRTBoundingBox2D(mina,minb, maxa,maxb);
          
//              mapfilescp =  stream.ReadUInt32();
        
//              if (mapfilescp !== MAPFILEIO_SECURITYCHECKPOINT) {return false;}
         
//           //  PVRTuint32 size;
//             let size = stream.ReadUInt32();
     
// 	     	layer.pIndexDataBuckets[i].numIndices = size / 2 ;   //sizeof(index_t);
//             if (layer.pIndexDataBuckets[i].numIndices === 0) return false;
            
//         layer.pIndexDataBuckets[i].pIndices = stream.ReadArrayBuffer(size); //layer.pIndexDataBuckets[i].numIndices); 
//         mapfilescp =  stream.ReadUInt32();
       
//         if (mapfilescp !== MAPFILEIO_SECURITYCHECKPOINT) {return false;}
// 	 	}
// 	 }
//  else  layer.pIndexDataBuckets = null;

//           mapfilescp =  stream.ReadUInt32();
    
//         if (mapfilescp !== MAPFILEIO_SECURITYCHECKPOINT) {return false;}

//         if(typeof callback === "function")
//         {
//             let args = [layer, layer1, header, loadFromLevel, demo];
//             args = args.concat(Array.prototype.slice.call(arguments, 7));
           
//             callback.apply(null, args);
//         }
//     return true;
    




let namelen = stream.ReadUInt32();
stream.ReadString(namelen);
// Skip the name
//pData += namelen;

let  numTiles = stream.ReadUInt32();

for (let  i=0; i < numTiles; i++)
{
    namelen = stream.ReadUInt32();
    stream.ReadString(namelen);
    // pData += sizeof(namelen);
    // // Skip the name
    // pData += namelen;
}

let  numpositions = stream.ReadUInt32();

let m_uiNumOcclusionData = numpositions;

let m_paPVRTOcclusionData = new Array(m_uiNumOcclusionData); //PVRTOcclusionData

for (let i=0; i < m_uiNumOcclusionData; i++)
{
    m_paPVRTOcclusionData[i] = new PVRTOcclusionData()

    let position = stream.ReadFloat32Array(3);//  , pData, sizeof(PVRTVec3));
    m_paPVRTOcclusionData[i].position = new  PVRVector3(position[0],position[1],position[2]);

    let reftiles = stream.ReadUInt32();
   
    m_paPVRTOcclusionData[i].numRefObjects = reftiles;
    m_paPVRTOcclusionData[i].pRefTile = new Array(reftiles);
    m_paPVRTOcclusionData[i].pNumRefObject = new Array(reftiles);
    m_paPVRTOcclusionData[i].ppRefObjects = new Array(reftiles);  //int*

    for (let j=0; j < reftiles; j++)
    {
        let tilenum = stream.ReadUInt32();
        m_paPVRTOcclusionData[i].pRefTile[j] = tilenum;

        let num_ref_models= stream.ReadUInt32();
        m_paPVRTOcclusionData[i].pNumRefObject[j] = num_ref_models;
       // m_paPVRTOcclusionData[i].ppRefObjects[j] = new Array(num_ref_models);
        m_paPVRTOcclusionData[i].ppRefObjects[j] =  stream.ReadUInt32Array(num_ref_models) ; //sizeof(unsigned int) * num_ref_models;
   
      
   
    }
}

demo.m_uiNumOcclusionData = m_uiNumOcclusionData;
demo.m_paPVRTOcclusionData = m_paPVRTOcclusionData;

if(typeof callback === "function")
        {
            let args = [header, demo];
            args = args.concat(Array.prototype.slice.call(arguments, 3));
            callback.apply(null, args);
        }
return true;

}

// // CPVRTResourceFile file(pszFilename);
// // if (!file.IsOpen())
// // {
// //     *pErrorStr = "Error: Could not open 3d model hirarchy file!\n";
// //     return false;
// // }

// //const char *pData = (const char *)file.DataPtr();
// let numTiles = stream.ReadUInt32();

// //PVRTuint32 numTiles;
// //memcpy((char *)&numTiles, pData, sizeof(numTiles));
// //pData += sizeof(numTiles);




// let m_uiNumPVRTCityBlocks = numTiles;
// let m_paPVRTCityBlocks = new Array(m_uiNumPVRTCityBlocks); //new PVRTCityBlock[m_uiNumPVRTCityBlocks];

// for (let i=0; i < m_uiNumPVRTCityBlocks; i++)
// {
//     m_paPVRTCityBlocks[i] = new PVRTCityBlock();
//     m_paPVRTCityBlocks[i].boundingbox = PVR3dModelIndex.ReadPVRBoundingBox2D(stream);
//     m_paPVRTCityBlocks[i].numLod = start.ReadUInt32();
//    // memcpy((char *)&m_paPVRTCityBlocks[i].boundingbox, pData, sizeof(PVRTBoundingBox2D));
//    // pData += sizeof(PVRTBoundingBox2D);
    
//   // memcpy((char *)&m_paPVRTCityBlocks[i].numLod, pData, sizeof(PVRTuint32));
//     //pData += sizeof(PVRTuint32);

//     m_paPVRTCityBlocks[i].paLod = new Array(m_paPVRTCityBlocks[i].numLod); //PVRTCityBlockLod

//     for (let j=0; j < m_paPVRTCityBlocks[i].numLod; j++)
//     {
//         m_paPVRTCityBlocks[i].paLod[j] = new PVRTCityBlockLod();
//         m_paPVRTCityBlocks[i].paLod[j].bLoaded = false;

//        let  namelength = stream.ReadUInt32(); //PVRTuint32

//         // memcpy((char *)&namelength, pData, sizeof(namelength));
//         // pData += sizeof(namelength);

//         m_paPVRTCityBlocks[i].paLod[j].pszFilename = stream.readString(namelength+1);   // new char[namelength+1];

//         // memcpy((char *)m_paPVRTCityBlocks[i].paLod[j].pszFilename, pData, sizeof(char) * namelength);
//         // pData += sizeof(char) * namelength;
//        // m_paPVRTCityBlocks[i].paLod[j].pszFilename[namelength] = '\0';

//        m_paPVRTCityBlocks[i].paLod[j].numObjects = stream.ReadUInt32()

//         // memcpy((char *)&m_paPVRTCityBlocks[i].paLod[j].numObjects, pData, sizeof(PVRTuint32));
//         // pData += sizeof(PVRTuint32);

//         m_paPVRTCityBlocks[i].paLod[j].paObjects = new  Array(m_paPVRTCityBlocks[i].paLod[j].numObjects);  //PVRTCityBlockEntity[m_paPVRTCityBlocks[i].paLod[j].numObjects];

//         m_paPVRTCityBlocks[i].paLod[j].paVisibleNodes = new Uint32Array(m_paPVRTCityBlocks[i].paLod[j].numObjects);
//         m_paPVRTCityBlocks[i].paLod[j].numVisibleNodes = 0;

//         for (let k=0; k < m_paPVRTCityBlocks[i].paLod[j].numObjects; k++)
//         {
//             m_paPVRTCityBlocks[i].paLod[j].paObjects[k] = new PVRTCityBlockEntity();
//             m_paPVRTCityBlocks[i].paLod[j].paObjects[k].boundingbox = PVR3dModelIndex.ReadPVRBoundingBox2D(stream); //  pData, sizeof(PVRTBoundingBox2D));
//            // pData += sizeof(PVRTBoundingBox2D);
//             m_paPVRTCityBlocks[i].paLod[j].paObjects[k].numSubObjects =  stream.ReadUInt32();
//             //pData += sizeof(PVRTuint32);

//            // m_paPVRTCityBlocks[i].paLod[j].paObjects[k].pNodeIdx = new Uint32Array(m_paPVRTCityBlocks[i].paLod[j].paObjects[k].numSubObjects);
// //unsigned Int;
//             m_paPVRTCityBlocks[i].paLod[j].paObjects[k].pNodeIdx = stream.ReadArrayBuffer(  4 * m_paPVRTCityBlocks[i].paLod[j].paObjects[k].numSubObjects);
//           //  pData += sizeof(unsigned int) * m_paPVRTCityBlocks[i].paLod[j].paObjects[k].numSubObjects;

//             m_paPVRTCityBlocks[i].paLod[j].paObjects[k].pauiTextures = 0;
//             m_paPVRTCityBlocks[i].paLod[j].paObjects[k].paNumIndices = 0;
//             m_paPVRTCityBlocks[i].paLod[j].paObjects[k].paIndexOffsets = 0;
//         }
//     }
// }

// demo.m_uiNumPVRTCityBlocks = m_uiNumPVRTCityBlocks;
// demo.m_uiNumPVRTCityBlocks = m_paPVRTCityBlocks;
// if(typeof callback === "function")
//         {
//             let args = [header, demo];
//             args = args.concat(Array.prototype.slice.call(arguments, 3));
//             callback.apply(null, args);
//         }


// return true;
// }

}

export default PVROcclusion