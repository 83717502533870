import {PVRPrint3D,EPVRPrint3D} from "./webgl/Tools/PVRPrint3D";
import { PVRMaths , PVRMatrix4x4, PVRMatrix3x3, PVRVector3, PVRVector4, PVRVector2 }  from "./webgl/Tools/PVRMaths";
//import PVRShader from "./webgl/Tools/PVRShader";
import PVRTexture from "./webgl/Tools/PVRTexture";
import PVRFileStream from "./webgl/Tools/PVRFileStream";
import {PVRModel, EPVRModel } from  "./webgl/Tools/PVRModel"
import {PVRPODLoader, EPODErrorCodes} from  "./webgl/Tools/PVRPODLoader";
import {PVRPFXEffect, EPVRTPFXUniformSemantic } from "./webgl/Tools/PVREffect"
import PVRPFXParser from "./webgl/Tools/PVREffectParser"
import PVRMesh from "./webgl/Tools/PVRMesh"

/******************************************************************************


******************************************************************************/

/******************************************************************************
 Constants
******************************************************************************/
const PVR_SUCCESS = true;
const  c_fDemoFrameRate = 1.0  / 80.0 ;

const  c_fPointLightScale = 50.0 ;
const  c_fPointlightIntensity = 100.0 ;

const  c_fDirectionallightIntensity = 3.0 ;

/******************************************************************************
 Defines
******************************************************************************/
 const  VERTEX_ARRAY	 =	0
 const NORMAL_ARRAY	=	1
 const TEXCOORD_ARRAY	=	2
 const TANGENT_ARRAY=		3

const  eFBO = 
{
	FBO_ALBEDO : 0,
	FBO_NORMAL: 1,
	FBO_DEPTH: 2,
	FBO_DEFERRED: 3,
	NUM_FBOS: 4
};

const RenderMode =
{	
	RENDER_ALBEDO : eFBO.FBO_ALBEDO,
	RENDER_NORMALS : eFBO.FBO_NORMAL,		
	RENDER_DEPTH : eFBO.FBO_DEPTH,
	RENDER_DEFERRED: 3,	
	RENDER_GEOMETRY: 4,
	NUM_RENDER_MODES: 5,
};

/******************************************************************************
 Global strings
******************************************************************************/

const   c_sPointLightEffectName       =  "RenderPointLight";
const   c_sDirectionalLightEffectName =   "RenderDirectionalLight" ;
const   c_sSimpleTextureEffectName    =   "RenderSimpleTexture" ;
const   c_sCubeTextureEffectName      =   "RenderCubeTexture" ;
const   c_sSolidColourEffectName      =   "RenderSolidColour" ;

const   c_sAlbedoEffectName     =   "RenderAlbedo" ;
const   c_sNormalEffectName     =   "RenderNormals" ;
const   c_sSplitDepthEffectName =   "RenderDepthChannelSplit" ;
const   c_sDepthEffectName      =   "RenderDepth" ;

const   c_asRenderModeGBufferEffects = [ c_sAlbedoEffectName, c_sNormalEffectName, c_sSplitDepthEffectName ];
const   c_asRenderModeVisualisationEffects = [ c_sAlbedoEffectName, c_sNormalEffectName, c_sDepthEffectName ];

/******************************************************************************
 Structures and enums
******************************************************************************/

const eCustomSemantics = 
{
	eCUSTOMSEMANTIC_FARCLIPDISTANCE : 63,
	eCUSTOMSEMANTIC_SPECULARPOWER : 64,
	eCUSTOMSEMANTIC_DIFFUSECOLOUR : 65,
	eCUSTOMSEMANTIC_POINTLIGHT_VIEWPOSITION : 66,
	eCUSTOMSEMANTIC_DIRECTIONALLIGHT_DIRECTION: 67,
};

const SPVRTPFXUniformSemantic = function(p,n)
{
        this.p = "" ;	// String containing semantic
        if(p)  this.p = p;
        this.n = 0;
        if(n)  this.n = n;	// Application-defined semantic value  //unsigned int
};


const c_CustomSemantics =   
[ 
	new SPVRTPFXUniformSemantic( "CUSTOMSEMANTIC_FARCLIPDISTANCE",            eCustomSemantics.eCUSTOMSEMANTIC_FARCLIPDISTANCE ),
    new	SPVRTPFXUniformSemantic( "CUSTOMSEMANTIC_SPECULARPOWER",	           eCustomSemantics.eCUSTOMSEMANTIC_SPECULARPOWER ),
	new SPVRTPFXUniformSemantic( "CUSTOMSEMANTIC_DIFFUSECOLOUR",              eCustomSemantics.eCUSTOMSEMANTIC_DIFFUSECOLOUR ),
	new SPVRTPFXUniformSemantic( "CUSTOMSEMANTIC_POINTLIGHT_VIEWPOSITION",    eCustomSemantics.eCUSTOMSEMANTIC_POINTLIGHT_VIEWPOSITION ),
	new SPVRTPFXUniformSemantic( "CUSTOMSEMANTIC_DIRECTIONALLIGHT_DIRECTION", eCustomSemantics.eCUSTOMSEMANTIC_DIRECTIONALLIGHT_DIRECTION ),
];
//


const c_uiNumCustomSemantics = c_CustomSemantics.length;   //  sizeof(c_CustomSemantics)/sizeof(c_CustomSemantics[0]);

/******************************************************************************
 Content file names
******************************************************************************/

const c_pszRenderModes = [ "Albedo", "Normals", "Depth", "Deferred", "Geometry" ];

const c_pszPointLightModel     = "pointlight.pod";
const c_pszLightEnvironmentMap = "light_cubemap.pvr";

const c_szSceneFile  = "deferScene.pod";
const c_szPFXSrcFile	= "deferEffect.pfx";

/*!****************************************************************************
 Class implementing the PVRShell functions.
******************************************************************************/

const WebGLDeferredShading  = function(data)
{



	let documentData = data
   
	let dataResult = function(thisdata, a, b)  {
		 
		return   thisdata.filter(function(elt)  {
			return elt.endsWith(b) && elt.includes(a)
		})[0]
		}
	
	
	const myresult = (documentData.allFile.edges.map((file, index) => {return (file.node.publicURL ) }))
	
	
	let getFileLocation = function(name) {
		const dataResult = function(thisdata, a, b)  {
			return   thisdata.filter(function(elt)  {
				return elt.endsWith(b) && elt.includes(a)
			})[0]
			}
			let r1 = name.split(".")
			let l   = r1.length;
			let filename = r1[0];
			for (let i =1; i<l-1; i++) filename += r1[i];
			//let result = data.allFile.edges.map((file, index) => {return (file.node.publicURL ) })
				return (
					dataResult(myresult, r1[0],r1[l-1])
				)
			}
	
	

    let ulPrevTime = 0.0;
	let  m_Print3D  = new PVRPrint3D(myresult);
	let m_sContext = {} //SPVRTContext
	//let  m_sExtensions= {}  //CPVRTgles2Ext

	// The effect file handlers
	let m_pPFXEffectParser  = {} //CPVRTPFXParser

	// Frame counters for animation
	let        m_fFrame = 0.0
	//let          m_bScreenRotated =false;
let        m_bPaused = false;
	let  m_uiCameraId = 0;
	let  m_RenderMode = 0;
					
	// Projection and Model View matrices
	let   m_vCameraPosition = new PVRVector3();
	let   m_mView = new PVRMatrix4x4();
	let    m_mProjection = new PVRMatrix4x4();
	let    m_mViewProjection =  new PVRMatrix4x4();
	let    m_mInverseView=  new PVRMatrix4x4();
	let      m_fFarClipDistance  = 0.0;

	let   m_iWindowWidth  = 0;
	let    m_iWindowHeight = 0;
	let    m_iFboWidth = 0;
	let   m_iFboHeight =0;
	let    m_iViewportOffsets = []; //[2];
	
	// Handles for textures
	let   m_uiDefaultDiffuseTexture ={}
	let  m_uiDefaultBumpTexture ={}
 

	// Handles for FBOs and surfaces
	
	let m_uiGBufferFBOs  = []; //[NUM_FBOS];	
	let m_uiGBufferDepthBuffers = []; //[NUM_FBOS];	
	let m_uiGBufferStencilBuffers = []; //[NUM_FBOS];	
	let m_uiRenderTextures = [] ;//[NUM_FBOS];
	
	// Light proxy models	

	      
	
	function Material()
	{
		   this.uiTexture = {};
		   this.uiBumpmap= {};
		  this.fSpecularPower = 0.0;
	    this.vDiffuseColour = new PVRVector3();
	}
	//let m_pMaterials = [];
	
	function Model()
	{
		this.pod = {};
		this.puiVBOs =[];
		this.puiIBOs = [];
	}
	 
			
	// Point lights
	let  m_uiNumPointLights = 0;
	function PointLight()
	{
		   this.uiNodeIdx = 0;
		     this.vColour = new PVRVector3();
		     this.mProxyScale = new PVRMatrix4x4();
		     this.mTransformation =new PVRMatrix4x4();
	}
    
    let m_pPointLights = [];

	// Directional lights
    let  m_uiNumDirectionalLights = 0;
    
	function DirectionalLight()
	{		
		this.uiNodeIdx = 0;
		 this.vColour = new PVRVector3();
	   this.mTransformation = new PVRMatrix4x4();
        this.vDirection = new PVRMatrix4x4();
    }
    
	 let m_pDirectionalLights = [];
				


		
	// void DrawAxisAlignedQuad(PVRTVec2 afLowerLeft, PVRTVec2 afUpperRight, const CPVRTArray<SPVRTPFXUniform>& aUniforms);

	// bool AllocateGBuffer(CPVRTString *pErrorStr);	
	// void RenderGBuffer(const   &effect);	

	// void SetupVBOAttributes(const SPODMesh &Mesh, const CPVRTArray<SPVRTPFXUniform>& aUniforms, bool bEnableAttribs);

	// void drawSceneDeferred();
	// void DrawSceneFlatColoured();		
	// void DrawLightSources();
	// void DrawPointLightGeometry(const  alpha);

	// void DrawPointLightProxies();	
	// void DrawDirectionalLightProxies();

	// void HandleInput();	
	// void UpdateAnimation();


/*!****************************************************************************
 @Function		loadTextures
 @Output		pErrorStr		A CPVRTString describing the error on failure
 @Return		bool			true if no error occurred
 @Description	Loads the textures required for this example
******************************************************************************/
//bool WebGLDeferredShading.prototype.loadTextures(CPVRTString* const pErrorStr)
  WebGLDeferredShading.prototype.loadTextures = function(gl)
{
	if (this.m_Scene.pod.data.numMaterials === 0)
	{
		console.error("ERROR: The scene does not contain any materials.");
		return false;
	}
    
    this.LoadingTextures = 2* this.m_Scene.pod.data.numMaterial + 1;

   
    let pDiffuse =   new Uint8Array(3);
    pDiffuse[0] =255;
    pDiffuse[1] =255;
    pDiffuse[2] =255;

    m_uiDefaultDiffuseTexture = gl.createTexture();

	gl.bindTexture(gl.TEXTURE_2D, m_uiDefaultDiffuseTexture);
	gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGB, 1, 1, 0, gl.RGB, gl.UNSIGNED_BYTE, pDiffuse);
	gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
	gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);		
	gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.REPEAT);
	gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.REPEAT);

	// Initialise the default (fall back) normal texture as an outward pointing normal
    let pBump =   new Uint8Array(3);
    pBump[0] = 0;
    pBump[1] = 0;
    pBump[2] = 255;
   
	m_uiDefaultBumpTexture = gl.createTexture();
	gl.bindTexture(gl.TEXTURE_2D, m_uiDefaultBumpTexture);
	gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGB, 1, 1, 0, gl.RGB, gl.UNSIGNED_BYTE, pBump);
	gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
	gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);		
	gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.REPEAT);
	gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.REPEAT);

	// Load the cubemap that is used as a light environment map
	// if(PVRTexture.loadFromURI(c_pszLightEnvironmentMap, m_uiLightEnvironmentMap) !== PVR_SUCCESS)
	// {
	// 	console.error("ERROR: Failed to load texture ", c_pszLightEnvironmentMap);
	// 	return false;
    // }	
    (function(demo)
    {
		
        
        PVRTexture.loadFromURI(gl, getFileLocation( c_pszLightEnvironmentMap), 0,
            function(gl, id, h)
            {
        
                demo.m_uiLightEnvironmentMap = id;
              
                if(gl.getError()) { alert("Error while loading texture!"); }

                         gl.texParameteri(gl.TEXTURE_CUBE_MAP, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
                         gl.texParameteri(gl.TEXTURE_CUBE_MAP, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
                         gl.texParameteri(gl.TEXTURE_CUBE_MAP, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
                         gl.texParameteri(gl.TEXTURE_CUBE_MAP, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);


                // gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR_MIPMAP_LINEAR);
                // gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);		
                // gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.REPEAT);
                // gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.REPEAT);
                demo.LoadingTextures  -= 1;//&= ~(1<<1);
           
            });
    })( this);





	// Load the materials from the POD file
	this.m_pMaterials = new Array(this.m_Scene.pod.data.numMaterials);   //Material


	for (let  i=0; i < this.m_Scene.pod.data.numMaterials; i++)
	{
        this.m_pMaterials[i]= new Material();
        const material = this.m_Scene.pod.data.materials[i]; //material
    
        
      
		this.m_pMaterials[i].fSpecularPower = material.data.shininess;
		this.m_pMaterials[i].vDiffuseColour = new PVRVector3(material.data.diffuse[0],material.data.diffuse[1],material.data.diffuse[2] );
		
		if (material.data.diffuseTextureIndex !== -1)
		{
			// Load the diffuse texture map
			 //let header = new  PVRTextureHeaderV3();
			// if(PVRTexture.loadFromURI(this.m_Scene.pod.pTexture[material.nIdxTexDiffuse].pszName, ) !== PVR_SUCCESS)
			// {
			// 	console.error("ERROR: Failed to load texture ", this.m_Scene.pod.pTexture[material.nIdxTexDiffuse].pszName);
			// 	return false;
			// }

		
            (function(demo)
            {
				
                PVRTexture.loadFromURI(gl, getFileLocation( demo.m_Scene.pod.data.textures[material.data.diffuseTextureIndex].data.name), 0,
                    function(gl, id, h)
                    {
                
                        demo.m_pMaterials[i].uiTexture = id;
                        if(gl.getError()) { alert("Error while loading texture!"); }
                        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR_MIPMAP_LINEAR);
                        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);		
                        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.REPEAT);
                        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.REPEAT);
                        demo.LoadingTextures  -= 1;//&= ~(1<<1);
              
                    });
            })( this);












		}
		else
		{
			// Otherwise assign the default texture map
            this.m_pMaterials[i].uiTexture = m_uiDefaultDiffuseTexture;
            this.LoadingTextures -= 1;
		}

		if (material.data.bumpMapTextureIndex !== -1)
		{

            (function(demo)
            {
          

                PVRTexture.loadFromURI(gl, getFileLocation( demo.m_Scene.pod.data.textures[material.data.bumpMapTextureIndex].data.name), 0,
                    function(gl, id, h)
                    {
                
                        demo.m_pMaterials[i].uiBumpmap = id;
                        //Clear the bit that denotes that this texture is loaded

                        if(gl.getError()) { alert("Error while loading texture!"); }
                        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR_MIPMAP_LINEAR);
                        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);		
                        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.REPEAT);
                        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.REPEAT);
                        demo.LoadingTextures  -= 1;//&= ~(1<<1);
                      
                    });
            })( this);


		}
		else
		{
			// Otherwise assign the default texture map
            this.m_pMaterials[i].uiBumpmap = m_uiDefaultBumpTexture;
            this.LoadingTextures -= 1;
		}
	}	

	return true;
}

/*!****************************************************************************
 @Function		loadVbos
 @Description	Loads the mesh data required for this example into
				vertex buffer objects
******************************************************************************/
// WebGLDeferredShading.prototype.loadVbos(gl)
 WebGLDeferredShading.prototype.loadSceneVbos = function(gl)
{
	//
	// Load the scene
	//
	this.m_Scene.puiVBOs = new  Array(this.m_Scene.pod.data.numMeshes);
	this.m_Scene.puiIBOs = new Array(this.m_Scene.pod.data.numMeshes);
        
      for (let i =0 ; i < this.m_Scene.pod.data.numMeshes; i++) 
    {this.m_Scene.puiVBOs[i]= gl.createBuffer();
    }

  
    
    for (let  i = 0; i < this.m_Scene.pod.data.numMeshes; ++i)
	{
		// Load vertex data into buffer object
		let Mesh = this.m_Scene.pod.data.meshes[i];
		// Only indexed triangles are supported
		if (!Mesh.data.faces.data)
		{
			console.error("ERROR: Failed loading scene, only indexed geometry is supported.");
			return false;
		}

		//let  uiSize = Mesh.nNumVertex * Mesh.sVertex.nStride;
		gl.bindBuffer(gl.ARRAY_BUFFER, this.m_Scene.puiVBOs[i]);
		gl.bufferData(gl.ARRAY_BUFFER,  Mesh.data.vertexElementData[0], gl.STATIC_DRAW);

		this.m_Scene.puiIBOs[i] = gl.createBuffer();
	//	uiSize = PVRTModelPODCountIndices(Mesh) * Mesh.sFaces.nStride;
		gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, this.m_Scene.puiIBOs[i]);
		gl.bufferData(gl.ELEMENT_ARRAY_BUFFER,  Mesh.data.faces.data, gl.STATIC_DRAW);

		gl.enableVertexAttribArray(VERTEX_ARRAY);
		gl.enableVertexAttribArray(NORMAL_ARRAY);
		gl.enableVertexAttribArray(TEXCOORD_ARRAY);
		gl.enableVertexAttribArray(TANGENT_ARRAY);


        let positions = Mesh.data.vertexElements["POSITION0"];
        let normals   = Mesh.data.vertexElements["NORMAL0"];
		let uvs       = Mesh.data.vertexElements["UV0"];
        let tangents  = Mesh.data.vertexElements["TANGENT0"];
 

		gl.vertexAttribPointer(VERTEX_ARRAY, 3, gl.FLOAT, gl.FALSE,  positions.stride, positions.offset);
		gl.vertexAttribPointer(NORMAL_ARRAY, 3, gl.FLOAT, gl.FALSE, normals.stride, normals.offset);
		gl.vertexAttribPointer(TEXCOORD_ARRAY, 2, gl.FLOAT, gl.FALSE, uvs.stride, uvs.offset);
		gl.vertexAttribPointer(TANGENT_ARRAY, 3, gl.FLOAT, gl.FALSE, tangents.stride,tangents.offset);	
    }
    gl.bindBuffer(gl.ARRAY_BUFFER, null);
    gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, null);
    this.LoadingModel -= 1;
    return true;	
}

WebGLDeferredShading.prototype.loadLightVbos = function(gl){
	// 
	//  Load the point light model
	// 	
    	this.m_uiPointLightModelVBO=gl.createBuffer();
        this.m_uiPointLightModelIBO = gl.createBuffer();
		

		// Load vertex data into buffer object
		let  Mesh = this.m_PointLightModel.data.meshes[0];
		// Only indexed triangles are supported
		if (!Mesh.data.faces.data)
		{
			console.error("ERROR: Failed loading point light proxy, only indexed geometry is supported.");
			return false;
		}

	//	let  uiSize = Mesh.nNumVertex * Mesh.sVertex.nStride;
		gl.bindBuffer(gl.ARRAY_BUFFER, this.m_uiPointLightModelVBO);
		gl.bufferData(gl.ARRAY_BUFFER, Mesh.data.vertexElementData[0], gl.STATIC_DRAW);		

	//	uiSize = PVRTModelPODCountIndices(Mesh) * sizeof(GLshort);
		gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, this.m_uiPointLightModelIBO);
		gl.bufferData(gl.ELEMENT_ARRAY_BUFFER, Mesh.data.faces.data, gl.STATIC_DRAW);			

		gl.enableVertexAttribArray(VERTEX_ARRAY);
        gl.enableVertexAttribArray(NORMAL_ARRAY);
        
        let positions = Mesh.data.vertexElements["POSITION0"];
        let normals   = Mesh.data.vertexElements["NORMAL0"];


		gl.vertexAttribPointer(VERTEX_ARRAY, 3, gl.FLOAT, gl.FALSE, positions.stride, positions.offset);
		gl.vertexAttribPointer(NORMAL_ARRAY, 3, gl.FLOAT, gl.FALSE, normals.stride, normals.offset);
	

	gl.bindBuffer(gl.ARRAY_BUFFER, null);
	gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, null);		
 
    this.LoadingModel -= 1;
	return true;
}


/*!****************************************************************************
 @Function		loadLights
 @Return		bool		true if no error occurred
 @Description	Loads all lights from the scene and prepares helper structures.
******************************************************************************/
//bool WebGLDeferredShading.prototype.loadLights(CPVRTString* const pErrorStr)
 WebGLDeferredShading.prototype.loadLights = function()
{	
	if (this.m_Scene.pod.data.numLights === 0)
        return false;	
       	

	const uiLightNodeOffset = this.m_Scene.pod.data.numMeshNodes;	

	// Iterate through the scene and count and tag the lights
	for (let  i=0; i < this.m_Scene.pod.data.numLights; i++)
	{
		switch (this.m_Scene.pod.data.lights[i].data.type)
		{		
		case EPVRModel.Light.ePoint:       
			m_uiNumPointLights++; 
			break;
		case EPVRModel.Light.eDirectional: 
			m_uiNumDirectionalLights++; 
			break;
		default:             
			console.error("ERROR: Only point and directional light sources are supported.");
			return false;
		}
	}

	//
	// Allocate per-light buffers
	//
	if (m_uiNumPointLights > 0){
        m_pPointLights = new Array(m_uiNumPointLights);	  //PointLight
     for (let i =0; i<m_uiNumPointLights; i++ ) m_pPointLights[i]= new PointLight();
    }

	if (m_uiNumDirectionalLights > 0){
		m_pDirectionalLights = new Array(m_uiNumDirectionalLights); //DirectionalLight
      for(let i=0; i<m_uiNumDirectionalLights; i++ )m_pDirectionalLights[i] = new DirectionalLight();
    }
	//
	// Determine the indices for the individual lights as they are found in the pod file
	//		
	let  uiPointLightIdx = 0
	let  uiDirectionalLightIdx = 0;
	for (let  i=0; i < this.m_Scene.pod.data.numLights; i++)
	{
		let light = this.m_Scene.pod.data.lights[i];					
		
		switch (light.data.type)
		{		
        case EPVRModel.Light.ePoint:   
      
				m_pPointLights[uiPointLightIdx].uiNodeIdx = uiLightNodeOffset + i;
				m_pPointLights[uiPointLightIdx].vColour = new PVRVector3(light.data.colour[0],light.data.colour[1],light.data.colour[2]);
				m_pPointLights[uiPointLightIdx].mTransformation =  PVRMatrix4x4.identity() ;
				m_pPointLights[uiPointLightIdx].mProxyScale = PVRMatrix4x4.identity() 
				uiPointLightIdx++;
			
			break;

        case EPVRModel.Light.eDirectional: 
       
				m_pDirectionalLights[uiDirectionalLightIdx].uiNodeIdx = uiLightNodeOffset + i;				
				m_pDirectionalLights[uiDirectionalLightIdx].vColour = new PVRVector3(light.data.colour[0],light.data.colour[1],light.data.colour[2]);
				uiDirectionalLightIdx++;
			
			break;

		default:              
			break;
		}
	}

    return true;
    
}


/*!****************************************************************************
 @Function		LoadPFX
 @Return		bool			true if no error occurred
 @Description	Loads and compiles the shaders and links the shader programs
				required for this training course
******************************************************************************/
//bool WebGLDeferredShading.prototype.LoadPFX(CPVRTString* const pErrorStr)
 WebGLDeferredShading.prototype.loadPFX = function(gl){
	

	// Parse the whole PFX and store all data.
    m_pPFXEffectParser = new PVRPFXParser();
    
   
    
    m_pPFXEffectParser.parseFromFile (gl, this, getFileLocation( c_szPFXSrcFile), 
         function(gl,demo, parser){
           
            let  uiNumEffects = parser.getNumberEffects();

            demo.LoadingEffects = uiNumEffects;

            demo.m_ppPFXEffects = new  Array(uiNumEffects)   ;   //CPVRTPFXEffect*[uiNumEffects];
            demo.m_pUniformMapping = new Array(uiNumEffects);  //CPVRTMap<int,int> 
        
            for (let  i=0; i < uiNumEffects; i++) {
                demo.m_ppPFXEffects[i] = new PVRPFXEffect(m_sContext);
                demo.m_pUniformMapping[i] = new Map();
            }
		//	alert(uiNumEffects)
            // Load one by one the effects. This will also compile the shaders.
            for (let  i=0; i < uiNumEffects; i++)
            {

                if(!demo.m_ppPFXEffects[i].registerUniformSemantic(gl, c_CustomSemantics, c_uiNumCustomSemantics))
                {
                    console.error("Failed to set custom semantics:\n\n") ;
                    return false;
                }
        
                let  nUnknownUniformCount = 0;

  
            
                demo.m_ppPFXEffects[i].load(gl, parser, parser.getEffect(i).name, null, null, nUnknownUniformCount,  
               function(gl,demo){
        
                // .. upps, some uniforms are not in our table. Better to quit because something is not quite right.
                // if(nUnknownUniformCount)
                // {
                //     console.error("Unknown uniforms found in effect: " , m_pPFXEffectParser.getEffect(i).name);
                //     return false;
                // }		
        
                // Create the mapping so we can reference the uniforms more easily

             
                demo.m_ppPFXEffects[i].activate(gl);
                const  Uniforms = demo.m_ppPFXEffects[i].getUniformArray();  //CPVRTArray<SPVRTPFXUniform>&
		
				for(let  j = 0; j < Uniforms.length; ++j)
                {
					
				   demo.m_pUniformMapping[i].set( Uniforms[j].nSemantic , Uniforms[j].nLocation); 
                    if (Uniforms[j].nSemantic === EPVRTPFXUniformSemantic.ePVRTPFX_UsTEXTURE){
						gl.uniform1i(Uniforms[j].nLocation, Uniforms[j].nIdx);
					}
                }
                
                demo.LoadingEffects -= 1;
                //this.nLocation = 0;	//unsigned int // GL location of the Uniform
	// this.nSemantic = 0;	   //	unsigned int // Application-defined semantic value
	// this.nIdx  = 0;		 //unsigned int	// Index; for example two semantics might be LIGHTPOSITION0 and LIGHTPOSITION1
	// this.sValueName = "";
                
            },demo)   ; 
        
            }
            
        }
            )
       //(    c_szPFXSrcFile)
	// {
	// 	console.error("Parse failed:\n\n" );
	// 	return false;
	// }
	
	// Setup all effects in the PFX file so we initialize the shaders and
	// store uniforms and attributes locations.

        
	return true;
}





const loadPOD = function(stream, demo, gl){


    demo.m_Scene.pod = new PVRModel();
    let podLoader    = new PVRPODLoader();
    let result       = podLoader.load(stream, demo.m_Scene.pod);
    if(result !== EPODErrorCodes.eNoError)
    {
        alert("Failed to load POD: " + result);
        return;
	}
	

    if (!demo.loadLights())
    
    {
		//console.error( errorStr.c_str());
		return false;
    }
	//
	//  Load textures
	//
	if (!demo.loadTextures(gl))
	{
		//(prefExitMessage, ErrorStr.c_str());
		return false;
	}
		
	//
	//	Load objects from the scene into VBOs
	//
	if (!demo.loadSceneVbos(gl))
	{
		//console.error( ErrorStr.c_str());
		return false;
	}

	//
	//	Load and compile the shaders & link programs
	//
	if (!demo.loadPFX(gl))
	{
		//console.error( ErrorStr.c_str());
		return false;
	}

	// if (this.m_Scene.pod.nNumLight === 0)
	// {
	// 	console.error( "ERROR: No lights found in scene\n");
	// 	return false;
	// }

	// if (this.m_Scene.pod.nNumCamera === 0)
	// {
	// 	console.error( "ERROR: No cameras found in scene\n");
	// 	return false;
    // }
    

	// //
	//	Load lights from the scene and convert them into the internal representation
	//
	//CPVRTString errorStr;
}


const loadLightPOD= function(stream, demo, gl){

    
    demo.m_PointLightModel = new PVRModel();
    let podLoader    = new PVRPODLoader();
    let result       = podLoader.load(stream, demo.m_PointLightModel);
    if(result !== EPODErrorCodes.eNoError)
    {
        alert("Failed to load POD: " + result);
        return;
	}
	
    demo.loadLightVbos(gl);

}




/*!****************************************************************************
 @Function		InitApplication
 @Return		bool		true if no error occurred
 @Description	Code in InitApplication() will be called by PVRShell once per
				run, before the rendering context is created.
				Used to initialize variables that are not dependent on it
				(e.g. external modules, loading meshes, etc.)
				If the rendering context is lost, InitApplication() will
				not be called again.
******************************************************************************/
 WebGLDeferredShading.prototype.initApplication = function( PVRShell){	
    
    this.startRendering = false;
    this.m_iOriginalFBO =  {};
    this.m_ppPFXEffects=[];  //CPVRTPFXEffect **  
	this.m_pUniformMapping = []; //CPVRTMap<int, int> *
    this.m_uiLightEnvironmentMap = null;

    this.m_uiPointLightModelVBO = null;
    this.m_uiPointLightModelIBO = null;
    this.m_PointLightModel = {}; //CPVRTModelPOD
    this.m_Scene = new Model();
    this.LoadingModel = 2;
    this.LoadingEffects = 1000;
    this.LoadingTextures = 1000;

     ulPrevTime = PVRShell.getTimeNow();
	m_RenderMode = RenderMode.RENDER_DEFERRED;

	m_fFrame = 0.0 ;
	m_bPaused = false;
	m_uiCameraId = 0;
	
	this.m_Scene.puiVBOs = [];
	this.m_Scene.puiIBOs = [];

	this.m_pMaterials = [];

	m_uiNumPointLights = 0;
	m_pPointLights = 0;	

	m_uiNumDirectionalLights = 0;
	m_pDirectionalLights = 0;	

	m_pPFXEffectParser = 0;
	this.m_ppPFXEffects = 0;
	this.m_pUniformMapping = null;
	
	// Get and set the read path for content files
//	CPVRTResourceFile::SetReadPath((char*)PVRShellGet(prefReadPath));

	// Get and set the load/release functions for loading external files.
	// In the majority of cases the PVRShell will return null function pointers implying that
	// nothing special is required to load external files.
//	CPVRTResourceFile::SetLoadReleaseFunctions(PVRShellGet(prefLoadFileFunc), PVRShellGet(prefReleaseFileFunc));

	// 
	//  Load the scene and the lights
	//
	// if (this.m_Scene.pod.ReadFromFile(c_szSceneFile) !== PVR_SUCCESS)
	// {
	// 	console.error( "ERROR: Couldn't load the scene pod file\n");
	// 	return false;
    // }
    
      



	return true;
}

/*!****************************************************************************
 @Function		QuitApplication
 @Return		bool		true if no error occurred
 @Description	Code in QuitApplication() will be called by PVRShell once per
				run, just before exiting the program.
				If the rendering context is lost, QuitApplication() will
				not be called.x
******************************************************************************/
 WebGLDeferredShading.prototype.quitApplication = function()
{
	// Free the memory allocated for the scene
	//m_Scene.pod.Destroy();

	this.m_Scene.puiVBOs = null;
	this.m_Scene.puiIBOs = null;

	 m_pPointLights = null;
 m_pDirectionalLights = null;

this.m_pMaterials = null;

	return true;
}

/*!****************************************************************************
 @Function		InitView
 @Return		bool		true if no error occurred
 @Description	Code in InitView() will be called by PVRShell upon
				initialization or after a change in the rendering context.
				Used to initialize variables that are dependent on the rendering
				context (e.g. textures, vertex buffers, etc.)
******************************************************************************/
 WebGLDeferredShading.prototype.initView = function(gl, PVRShell)
{	
//	m_sExtensions.LoadExtensions();

//gl.getSupportedExtensions()


(function(demo, gl)
{
	let fs = new PVRFileStream();

    fs.Open(getFileLocation( c_szSceneFile), true, loadPOD, demo, gl);
})(this, gl);



//
//	Load light proxy geometry
//
// if (m_PointLightModel.ReadFromFile(c_pszPointLightModel) !== PVR_SUCCESS)
// {
// 	console.error( "ERROR: Couldn't load the point light proxy pod file\n");
// 	return false;
// }



(function(demo, gl)
{
	let fs = new PVRFileStream();
	

    fs.Open(getFileLocation( c_pszPointLightModel), true, loadLightPOD, demo, gl);
})(this, gl);

     gl.TRUE = true;

     
    let  iMaxRenderbufferSize = gl.getParameter(gl.MAX_RENDERBUFFER_SIZE);
    this.m_iOriginalFBO = gl.getParameter(gl.FRAMEBUFFER_BINDING);
    
	//glGetIntegerv(gl.MAX_RENDERBUFFER_SIZE, &iMaxRenderbufferSize);	
//	glGetIntegerv(gl.FRAMEBUFFER_BINDING, &); 

	//console.log("Renderbuffer max. size: %d\n", iMaxRenderbufferSize);

	m_iWindowWidth =  PVRShell.data.width; //PVRShellGet(prefWidth);
	m_iWindowHeight =  PVRShell.data.height;//PVRShellGet(prefHeight);

    m_iFboWidth = m_iFboHeight = PVRMaths.POTLower(Math.min(m_iWindowWidth, m_iWindowHeight), 0);

	// int numCmdLineOpts = PVRShellGet(prefCommandLineOptNum);
	// const SCmdLineOpt *pCmdLineOpts = (const SCmdLineOpt *)PVRShellGet(prefCommandLineOpts);
	// for (let i=0; i < numCmdLineOpts; i++)
	// {
	// 	if ( pCmdLineOpts[i].pArg === -fbowidth")
	// 		m_iFboWidth = PVRT_MIN(atoi(pCmdLineOpts[i].pVal), m_iWindowWidth);
	// 	else if (strcmp(pCmdLineOpts[i].pArg, "-fboheight") === 0)
	// 		m_iFboHeight = PVRT_MIN(atoi(pCmdLineOpts[i].pVal), m_iWindowHeight);
	// }

	m_iViewportOffsets[0] = (m_iWindowWidth - m_iFboWidth) / 2.0 ;
	m_iViewportOffsets[1] = (m_iWindowHeight - m_iFboHeight) / 2.0;

    //console.log("FBO dimensions: %d x %d\n", m_iFboWidth, m_iFboHeight);
	//console.log("Framebuffer dimensions: %d x %d\n", m_iWindowWidth, m_iWindowHeight);
				



	// Is the screen rotated?
	//m_bScreenRotated = PVRShellGet(prefIsRotated) && PVRShellGet(prefFullScreen);

	//
	//  Initialize Print3D
	//
	//m_Print3D.setTextures(gl, PVRShell(prefWidth), PVRShellGet(prefHeight)) //, m_bScreenRotated) //!== PVR_SUCCESS)
    m_Print3D.setTextures(gl,PVRShell.data.width ,PVRShell.data.height); 
    

    // {
	// 	console.error( "ERROR: Cannot initialise Print3D\n");
	// 	return false;
	// }	
				
	//
	//  Set default OpenGL render states 
	//
	gl.cullFace(gl.BACK);
	gl.enable(gl.CULL_FACE);
	gl.enable(gl.DEPTH_TEST);
	gl.disable(gl.BLEND);
	gl.blendFunc(gl.ONE, gl.ONE);			
	
	gl.clearColor(0.0 , 0.0 , 0.0 , 1.0 );		

	return true;
}

/*!****************************************************************************
 @Function		ReleaseView
 @Return		bool		true if no error occurred
 @Description	Code in ReleaseView() will be called by PVRShell when the
				application quits or before a change in the rendering context.
******************************************************************************/
 WebGLDeferredShading.prototype.releaseView = function(gl)
{		
    // Delete buffer objects
    this.m_Scene.puiVBOs.forEach(x=> gl.deleteBuffer(x));
    this.m_Scene.puiIBOs.forEach(x=> gl.deleteBuffer(x));
    m_uiGBufferFBOs.forEach(x=> gl.deleteBuffer(x));
    m_uiGBufferDepthBuffers.forEach(x=> gl.deleteBuffer(x));;


	// gl.deleteBuffers(this.m_Scene.pod.nNumMesh, this.m_Scene.puiVBOs);
	// gl.deleteBuffers(this.m_Scene.pod.nNumMesh, this.m_Scene.puiIBOs);

	gl.deleteBuffer( this.m_uiPointLightModelVBO);
	gl.deleteBuffer(this.m_uiPointLightModelIBO);	

	// gl.deleteBuffers(eFBO.NUM_FBOS, );
	// gl.deleteBuffers(eFBO.NUM_FBOS, m_uiGBufferDepthBuffers);

	for (let  i=0; i < this.m_Scene.pod.data.numMaterials; i++)
	{
		gl.deleteTexture(this.m_pMaterials[i].uiTexture);
		gl.deleteTexture( this.m_pMaterials[i].uiBumpmap);
	}
	gl.deleteTextures(m_uiDefaultDiffuseTexture);
	gl.deleteTextures(m_uiDefaultBumpTexture);
	
	// Release Print3D Textures
	m_Print3D.releaseTextures();

	// Release the effect[s] then the parser
	for (let  i=0; i < m_pPFXEffectParser.getNumberEffects(); i++)
	{
		this.m_ppPFXEffects[i].destroy();
	 this.m_ppPFXEffects[i] = null;
		this.m_pUniformMapping[i].clear();
	}
	this.m_ppPFXEffects = null;

 this.m_pUniformMapping = null;
	
	return true;
}

/*!****************************************************************************
 @Function		RenderScene
 @Return		bool		true if no error occurred
 @Description	Main rendering loop function of the program. The shell will
				call this function every frame.
				eglSwapBuffers() will be performed by PVRShell automatically.
				PVRShell will also manage important OS events.
				Will also manage relevant OS events. The user has access to
				these events through an abstraction layer provided by PVRShell.
******************************************************************************/
 WebGLDeferredShading.prototype.renderScene = function(gl, PVRShell)
{

	//
	//	Allocates the gbuffer buffer objects
    //
if (this.LoadingModel > 0) {return true;}
if (this.LoadingTextures > 0) {return true;}

if (this.LoadingEffects > 0) {return true;}


if (!this.allocating){
this.allocating = true;
	if (!this.allocateGBuffer(gl))
	{
		return true;
    }
}

 if (!this.startRendering) { return true; }
	
 
	const  mId =  PVRMatrix4x4.identity();
	//
	//  Handle user input and update object animations
	//
	this.handleInput(PVRShell);
	this.updateAnimation(PVRShell);	

	gl.enable(gl.DEPTH_TEST);
	gl.disable(gl.BLEND);	
	gl.disable(gl.STENCIL_TEST);
	
	
//	m_RenderMode = RenderMode.RENDER_GEOMETRY;
	switch (m_RenderMode)
	{
	case RenderMode.RENDER_DEFERRED:
			
			//
	        //  Render the G-Buffer
            //
           
			this.drawSceneDeferred(gl);			
		
		break;

	case RenderMode.RENDER_ALBEDO:
	case RenderMode.RENDER_NORMALS:
	case RenderMode.RENDER_DEPTH:
	
			// Render the albedo part of the gbuffer			
			gl.bindFramebuffer(gl.FRAMEBUFFER, this.m_iOriginalFBO);
			gl.viewport(0, 0, m_iFboWidth, m_iFboHeight);
		
			gl.clearColor(0.0 , 0.0 , 0.0 , 1.0 );
			gl.clearStencil(0);
			gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT | gl.STENCIL_BUFFER_BIT);		

			if ((m_iFboWidth !== m_iWindowWidth ) || (m_iFboHeight !== m_iWindowHeight))
				gl.viewport(m_iViewportOffsets[0], m_iViewportOffsets[1], m_iFboWidth, m_iFboHeight);


			this.renderGBuffer(gl,c_asRenderModeVisualisationEffects[m_RenderMode]);			
		
		break;

	case RenderMode.RENDER_GEOMETRY:
		
			gl.disable(gl.STENCIL_TEST);
			gl.bindFramebuffer(gl.FRAMEBUFFER, this.m_iOriginalFBO);
			gl.viewport(0, 0, m_iWindowWidth, m_iWindowHeight);
		


			gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT | gl.STENCIL_BUFFER_BIT);
		    if ((m_iFboWidth !== m_iWindowWidth ) || (m_iFboHeight !== m_iWindowHeight))
				gl.viewport(m_iViewportOffsets[0], m_iViewportOffsets[1], m_iFboWidth, m_iFboHeight);
	
			this.drawSceneFlatColoured(gl);
	    	this.drawLightSources(gl);
			
			// gl.enable(gl.BLEND);
			// gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);
			// this.drawPointLightGeometry(gl, 0.75 );						
			// gl.disable(gl.BLEND);			
		
		break;
    default: break;
    }
    

	
	if ((m_iFboWidth !== m_iWindowWidth ) || (m_iFboHeight !== m_iWindowHeight))
		gl.viewport(0, 0, m_iWindowWidth, m_iWindowHeight);
	// Displays the demo name using the tools. For a detailed explanation, see the training course IntroducingPVRTools
	//m_Print3D.displayDefaultTitle("Deferred Shading", c_pszRenderModes[m_RenderMode], EPVRPrint3D.Logo.PowerVR);	
	m_Print3D.displayDefaultTitle("", "", EPVRPrint3D.Logo.PowerVR);	

	if (m_bPaused) m_Print3D.Print3D(1.0 , 15.0 , 0.75 , 0xFFFFFFFF, "Paused");	
	m_Print3D.flush(gl);	

	return true;
}


/*!****************************************************************************
 @Function		DrawSceneFlatColoured
  @Description	Renders the scene flat coloured with a predefined palette
******************************************************************************/
 WebGLDeferredShading.prototype.drawSceneFlatColoured = function(gl)
{				
	// The colour palette
	this.sRandColours = 
	[ 
		new PVRVector4(1.0 , 0.0 , 0.0 , 1.0 ), new PVRVector4(0.0 , 1.0 , 0.0 , 1.0 ), new PVRVector4(0.0 , 0.0 , 1.0 , 1.0 ),
		new PVRVector4(1.0 , 0.0 , 1.0 , 1.0 ), new PVRVector4(0.0 , 1.0 , 1.0 , 1.0 ), new PVRVector4(1.0 , 1.0 , 1.0 , 1.0 ),
		new PVRVector4(1.0 , 1.0 , 0.0 , 1.0 ), new PVRVector4(0.0 , 0.0 , 0.0 , 1.0 ), new PVRVector4(0.5 , 1.0 , 0.5 , 1.0 ),
    ];
	
	const  iEffectId = m_pPFXEffectParser.findEffectByName(c_sSolidColourEffectName); //int
	this.m_ppPFXEffects[iEffectId].activate(gl);


	for (let  i=0; i < this.m_Scene.pod.data.numMeshNodes; i++)
	{		
		let Node = this.m_Scene.pod.data.nodes[i];
        const  Mesh = this.m_Scene.pod.data.meshes[Node.data.index];		
		const   mModelViewProj = PVRMatrix4x4.matrixMultiply (m_mViewProjection , this.m_Scene.pod.getWorldMatrix(Node.data.index));
		
		const  material = this.m_pMaterials[Node.data.materialIndex];	  //Material
		
		
		gl.activeTexture(gl.TEXTURE0);
		gl.bindTexture(gl.TEXTURE_2D, material.uiTexture);		
		gl.activeTexture(gl.TEXTURE1);
		gl.bindTexture(gl.TEXTURE_2D, material.uiBumpmap);

		gl.uniformMatrix4fv(this.m_pUniformMapping[iEffectId].get(EPVRTPFXUniformSemantic.ePVRTPFX_UsWORLDVIEWPROJECTION),  gl.FALSE, mModelViewProj.data);
		gl.uniform4fv(this.m_pUniformMapping[iEffectId].get(EPVRTPFXUniformSemantic.ePVRTPFX_UsMATERIALCOLORAMBIENT),  this.sRandColours[i%9].data);

		// Bind the vertex buffer object and set attribute locations
		gl.bindBuffer(gl.ARRAY_BUFFER, this.m_Scene.puiVBOs[Node.data.index]);
		gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, this.m_Scene.puiIBOs[Node.data.index]);
		this.setupVBOAttributes(gl, Mesh, this.m_ppPFXEffects[iEffectId].getUniformArray(), true);
				
		// Indexed Triangle list
		let datatype = (Mesh.data.faces.indexType === PVRMesh.EPVRMesh.VertexData.eUnsignedShort) ? gl.UNSIGNED_SHORT : gl.UNSIGNED_INT;
		gl.drawElements(gl.TRIANGLES, Mesh.data.faces.data.length, datatype, 0);

		this.setupVBOAttributes(gl, Mesh, this.m_ppPFXEffects[iEffectId].getUniformArray(), false);
	}

	gl.bindBuffer(gl.ARRAY_BUFFER, null);
	gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, null);
}


/*!****************************************************************************
 @Function		AllocateGBuffer
 @Description	Allocates the required FBOs and buffer objects
******************************************************************************/
 WebGLDeferredShading.prototype.allocateGBuffer = function(gl)
{
	//
	// Allocate the gbuffer surfaces with the following components
	//                           Albedo	           Normal            Depth     Offscreen	
	let internalformats = [ gl.RGBA,           gl.RGB,                  gl.RGBA,           gl.RGB ];
	let formats         = [ gl.RGBA,           gl.RGB,                  gl.RGBA,           gl.RGB  ];
	 let types         = [gl.UNSIGNED_BYTE,  gl.UNSIGNED_SHORT_5_6_5, gl.UNSIGNED_BYTE,  gl.UNSIGNED_SHORT_5_6_5 ];	

	// Allocate the render targets
    // gl.genTextures(NUM_FBOS, m_uiRenderTextures);
    for (let  i=0; i < eFBO.NUM_FBOS; i++)
	{
        m_uiRenderTextures[i]= gl.createTexture();
    }
    
	// gl.genRenderbuffers(NUM_FBOS, m_uiGBufƒferDepthBuffers);
    
    var ext = gl.getExtension('WEBGL_draw_buffers');


    for (let  i=0; i < eFBO.NUM_FBOS; i++)
	{
      
		gl.bindTexture(gl.TEXTURE_2D, m_uiRenderTextures[i]);
		gl.texImage2D(gl.TEXTURE_2D, 0, internalformats[i], m_iFboWidth, m_iFboHeight, 0, formats[i], types[i], null);
		gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
		gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);					
		gl.bindTexture(gl.TEXTURE_2D,null);
		m_uiGBufferDepthBuffers[i] = gl.createRenderbuffer();
		//m_uiGBufferStencilBuffers[i] = gl.createRenderbuffer();
		gl.bindRenderbuffer(gl.RENDERBUFFER, m_uiGBufferDepthBuffers[i]);
		gl.renderbufferStorage(gl.RENDERBUFFER, gl.DEPTH_STENCIL, m_iFboWidth, m_iFboHeight);
		//gl.bindRenderbuffer(gl.RENDERBUFFER, m_uiGBufferStencilBuffers[i]);
		//gl.renderbufferStorage(gl.RENDERBUFFER, gl.STENCIL_INDEX8, m_iFboWidth, m_iFboHeight);
		gl.bindRenderbuffer(gl.RENDERBUFFER, null);
	}
	// Check to see if the gl.EXT_discard_framebuffer extension is supported
	// bool bDiscardSupported = CPVRTgles2Ext::IsGLExtensionSupported("gl.EXT_discard_framebuffer");
	// if (bDiscardSupported)
	// 	bDiscardSupported = m_sExtensions.glDiscardFramebufferEXT !== 0;		
	
	// Temporarily disabled
	let bDiscardSupported = false;

	//
	// Allocate the gbuffer fbo and attach the surfaces
	//	
     
    m_uiGBufferFBOs = new Array(eFBO.NUM_FBOS); 
  
	for (let  i=0; i < eFBO.NUM_FBOS; i++)
	{				
        m_uiGBufferFBOs[i] = gl.createFramebuffer();
		gl.bindFramebuffer(gl.FRAMEBUFFER, m_uiGBufferFBOs[i]);						
	//	gl.framebufferRenderbuffer(gl.FRAMEBUFFER, gl.DEPTH_ATTACHMENT, gl.RENDERBUFFER, m_uiGBufferDepthBuffers[i]);	
	//	gl.framebufferRenderbuffer(gl.FRAMEBUFFER, gl.STENCIL_ATTACHMENT, gl.RENDERBUFFER, m_uiGBufferDepthBuffers[i]);	
        gl.framebufferRenderbuffer(gl.FRAMEBUFFER, gl.DEPTH_STENCIL_ATTACHMENT, gl.RENDERBUFFER, m_uiGBufferDepthBuffers[i]);	
        gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, m_uiRenderTextures[i], 0);
       // gl.framebufferTexture2D(gl.FRAMEBUFFER, ext.COLOR_ATTACHMENT0_WEBGL, gl.TEXTURE_2D, m_uiRenderTextures[i], 0);


		let framebufferstatus = gl.checkFramebufferStatus(gl.FRAMEBUFFER);
		if (framebufferstatus !== gl.FRAMEBUFFER_COMPLETE)
		{
			let szReason = "UNKNOWN";
			switch (framebufferstatus)
			{
			case gl.FRAMEBUFFER_INCOMPLETE_ATTACHMENT:
				szReason = "gl.FRAMEBUFFER_INCOMPLETE_ATTACHMENT";
				break;
			case gl.FRAMEBUFFER_INCOMPLETE_DIMENSIONS:
				szReason = "gl.FRAMEBUFFER_INCOMPLETE_DIMENSIONS";
				break;
			case gl.FRAMEBUFFER_INCOMPLETE_MISSING_ATTACHMENT:
				szReason = "gl.FRAMEBUFFER_INCOMPLETE_MISSING_ATTACHMENT";
				break;
			case gl.FRAMEBUFFER_UNSUPPORTED:
				szReason = "gl.FRAMEBUFFER_UNSUPPORTED";
                break;
                default: break;
			}

			let szBuffer;

			switch (i)
			{
			case eFBO.FBO_ALBEDO: szBuffer = "Albedo";break;
			case eFBO.FBO_NORMAL: szBuffer = "Normals";break;
			case eFBO.FBO_DEPTH:  szBuffer = "Depth";break;
            case eFBO.FBO_DEFERRED: szBuffer = "Deferred";break;
            default: break;
            }			
            
			console.error	("ERROR: " + szBuffer + " framebuffer not set up correctly: " + szReason + "\n");
           
			return false;
		}

		if (bDiscardSupported) 
		{
			//Give the drivers a hint that we don't want stencil or depth information to be stored for later.
			//const GLenum attachments[] = { gl.STENCIL_ATTACHMENT, gl.DEPTH_ATTACHMENT };
		//	m_sExtensions.gl.discardFramebufferEXT(gl.FRAMEBUFFER, 2, attachments);
		}
    }	
    
    this.startRendering = true;
	gl.bindFramebuffer(gl.FRAMEBUFFER, this.m_iOriginalFBO);

	return true;
}


/*!****************************************************************************
 @Function		RenderGBuffer
 @Description	Renders the gbuffer
******************************************************************************/
 WebGLDeferredShading.prototype.renderGBuffer = function (gl,  effect)
{

//console.log("m_pPFXEffectParser",m_pPFXEffectParser)
	const  iEffectId = m_pPFXEffectParser.findEffectByName(effect);
//console.log("this.m_ppPFXEffects", this.m_ppPFXEffects)
//	console.log("iEffectId",iEffectId)

	this.m_ppPFXEffects[iEffectId].activate(gl);	

	if (this.m_pUniformMapping[iEffectId].has(eCustomSemantics.eCUSTOMSEMANTIC_FARCLIPDISTANCE)){

		gl.uniform1f(this.m_pUniformMapping[iEffectId].get(eCustomSemantics.eCUSTOMSEMANTIC_FARCLIPDISTANCE), m_fFarClipDistance);
	}

	gl.enable(gl.STENCIL_TEST);
	gl.stencilFunc(gl.ALWAYS, 1, 0xFF);
	gl.stencilOp(gl.KEEP, gl.KEEP, gl.REPLACE);

	for (let  i=0; i < this.m_Scene.pod.data.numMeshNodes; i++)
	{
		const Node = this.m_Scene.pod.data.nodes[i];		
		const Mesh = this.m_Scene.pod.data.meshes[Node.data.index];	

		const   mWorld = this.m_Scene.pod.getWorldMatrix(Node.data.index);
		const  mWorldView = PVRMatrix4x4.matrixMultiply( m_mView , mWorld);
		const   mModelViewProj = PVRMatrix4x4.matrixMultiply( m_mViewProjection , mWorld);
		// let mWorldView3x3 =                        PVRMatrix4x4.createMatrix3x3  (mWorldView);
		// const  mWorldViewIT3x3 =  PVRMatrix3x3.transpose(PVRMatrix3x3.inverse(mWorldView3x3)) ; //  .inverse().transpose();		

		let mWorldView3x3 =              PVRMatrix4x4.transpose(PVRMatrix4x4.inverse(mWorldView)) ;       
	   const  mWorldViewIT3x3 =     PVRMatrix4x4.createMatrix3x3  (mWorldView3x3);  //  .inverse().transpose();		
//console.log(mWorldViewIT3x3.data)

		const  material = this.m_pMaterials[Node.data.materialIndex]; //Material
	
		gl.activeTexture(gl.TEXTURE0);
		gl.bindTexture(gl.TEXTURE_2D, material.uiTexture);		
		gl.activeTexture(gl.TEXTURE1);
		gl.bindTexture(gl.TEXTURE_2D, material.uiBumpmap);
	
		if (this.m_pUniformMapping[iEffectId].has(EPVRTPFXUniformSemantic.ePVRTPFX_UsWORLDVIEW)){
		
			gl.uniformMatrix4fv(this.m_pUniformMapping[iEffectId].get(EPVRTPFXUniformSemantic.ePVRTPFX_UsWORLDVIEW), gl.FALSE, mWorldView.data);}
		if (this.m_pUniformMapping[iEffectId].has(EPVRTPFXUniformSemantic.ePVRTPFX_UsWORLDVIEWPROJECTION)){

			gl.uniformMatrix4fv(this.m_pUniformMapping[iEffectId].get(EPVRTPFXUniformSemantic.ePVRTPFX_UsWORLDVIEWPROJECTION),  gl.FALSE, mModelViewProj.data);}
		if (this.m_pUniformMapping[iEffectId].has(EPVRTPFXUniformSemantic.ePVRTPFX_UsWORLDVIEWIT)){

			gl.uniformMatrix3fv(this.m_pUniformMapping[iEffectId].get(EPVRTPFXUniformSemantic.ePVRTPFX_UsWORLDVIEWIT),  gl.FALSE, mWorldViewIT3x3.data);}

		if (this.m_pUniformMapping[iEffectId].has(eCustomSemantics.eCUSTOMSEMANTIC_SPECULARPOWER))
			gl.uniform1f(this.m_pUniformMapping[iEffectId].get(eCustomSemantics.eCUSTOMSEMANTIC_SPECULARPOWER), material.fSpecularPower);
		if (this.m_pUniformMapping[iEffectId].has(eCustomSemantics.eCUSTOMSEMANTIC_DIFFUSECOLOUR))
			gl.uniform3fv(this.m_pUniformMapping[iEffectId].get(eCustomSemantics.eCUSTOMSEMANTIC_DIFFUSECOLOUR), material.vDiffuseColour.data);

        // Bind the vertex buffer object and set attribute locations
    
		gl.bindBuffer(gl.ARRAY_BUFFER, this.m_Scene.puiVBOs[Node.data.index]);
		gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, this.m_Scene.puiIBOs[Node.data.index]);
		this.setupVBOAttributes(gl,Mesh, this.m_ppPFXEffects[iEffectId].getUniformArray(), true);
        
        
		let datatype = (Mesh.data.faces.indexType === PVRMesh.EPVRMesh.VertexData.eUnsignedShort) ? gl.UNSIGNED_SHORT : gl.UNSIGNED_INT;
		gl.drawElements(gl.TRIANGLES,Mesh.data.faces.data.length , datatype, 0);		
        
		this.setupVBOAttributes(gl,Mesh, this.m_ppPFXEffects[iEffectId].getUniformArray(), false);
	}
	
	gl.disable(gl.STENCIL_TEST);

	gl.bindBuffer(gl.ARRAY_BUFFER, null);
	gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, null);
}

/*!****************************************************************************
 @Function		SetupVBOAttributes
 @Description	Renders the scene using the gbuffer
******************************************************************************/
 //WebGLDeferredShading.prototype.setupVBOAttributes ( const SPODMesh &Mesh, const CPVRTArray<SPVRTPFXUniform>& aUniforms, bool bEnableAttribs)
 WebGLDeferredShading.prototype.setupVBOAttributes= function (gl,  Mesh,  aUniforms,  bEnableAttribs)
 
 {


    let positions = Mesh.data.vertexElements["POSITION0"];
    let normals   = Mesh.data.vertexElements["NORMAL0"];
    let uvs       = Mesh.data.vertexElements["UV0"];
    let tangents  = Mesh.data.vertexElements["TANGENT0"];



	if (bEnableAttribs)
	{
		for(let  j = 0; j < aUniforms.length; ++j)
		{
		
			switch(aUniforms[j].nSemantic)
			{
			case EPVRTPFXUniformSemantic.ePVRTPFX_UsPOSITION:
				
				gl.vertexAttribPointer(aUniforms[j].nLocation, positions.numComponents, gl.FLOAT, gl.FALSE, positions.stride, positions.offset);				
                gl.enableVertexAttribArray(aUniforms[j].nLocation);
			   if(gl.getError()) alert( aUniforms[j].nLocation);
				break;
			case EPVRTPFXUniformSemantic.ePVRTPFX_UsUV:
                    
				gl.vertexAttribPointer(aUniforms[j].nLocation, uvs.numComponents, gl.FLOAT, gl.FALSE, uvs.stride, uvs.offset);
                gl.enableVertexAttribArray(aUniforms[j].nLocation);
				if(gl.getError()) {alert( aUniforms[j].nLocation);}
				break;
			case EPVRTPFXUniformSemantic.ePVRTPFX_UsNORMAL:
                  
				gl.vertexAttribPointer(aUniforms[j].nLocation, normals.numComponents, gl.FLOAT, gl.FALSE, normals.stride, normals.offset);
                gl.enableVertexAttribArray(aUniforms[j].nLocation);
				if(gl.getError()) {alert( aUniforms[j].nLocation);}

				break;
			case EPVRTPFXUniformSemantic.ePVRTPFX_UsTANGENT:
                   
				gl.vertexAttribPointer(TANGENT_ARRAY,  tangents.numComponents, gl.FLOAT, gl.FALSE, tangents.stride, tangents.offset);	
                gl.enableVertexAttribArray(aUniforms[j].nLocation);
				if(gl.getError()) {alert( aUniforms[j].nLocation);}
                break;
                default: break;
			}
		}
	}
	else
	{
		for(let  j = 0; j < aUniforms.length; ++j)
		{
			switch(aUniforms[j].nSemantic)
			{
			case EPVRTPFXUniformSemantic.ePVRTPFX_UsPOSITION:
				gl.disableVertexAttribArray(aUniforms[j].nLocation);
				break;
			case EPVRTPFXUniformSemantic.ePVRTPFX_UsUV:
				gl.disableVertexAttribArray(aUniforms[j].nLocation);
				break;
			case EPVRTPFXUniformSemantic.ePVRTPFX_UsNORMAL:
				gl.disableVertexAttribArray(aUniforms[j].nLocation);
				break;
			case EPVRTPFXUniformSemantic.ePVRTPFX_UsTANGENT:
				gl.disableVertexAttribArray(aUniforms[j].nLocation);
				break;
            default: break;
            }
		}
	}
}

/*!****************************************************************************
 @Function		drawSceneDeferred
 @Description	Renders the scene using the gbuffer
******************************************************************************/
 WebGLDeferredShading.prototype.drawSceneDeferred =function(gl)
{
 
	//
	//  Render the GBuffer
	//
	for (let  i=0; i < 3; i++)
	{
		gl.bindFramebuffer(gl.FRAMEBUFFER, m_uiGBufferFBOs[i]);	
		gl.viewport(0, 0, m_iFboWidth, m_iFboHeight);
		gl.clearColor(0.0 , 0.0 , 0.0 , 1.0 );
		gl.clearStencil(0);
		gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT | gl.STENCIL_BUFFER_BIT);	
		
		this.renderGBuffer(gl,c_asRenderModeGBufferEffects[i]);
	}	

	//
	//  Bind main FBO, render the geometry to update the depth buffer and 
	//  finally add the light contributions using the gbuffer
	//
	gl.bindFramebuffer(gl.FRAMEBUFFER, this.m_iOriginalFBO);		
	gl.viewport(0, 0, m_iWindowWidth, m_iWindowHeight);
	gl.clearColor(0.0 , 0.0 , 0.0 , 1.0 );	
	gl.clearStencil(0);

	if ((m_iFboWidth !== m_iWindowWidth ) || (m_iFboHeight !== m_iWindowHeight))
		gl.viewport(m_iViewportOffsets[0], m_iViewportOffsets[1], m_iFboWidth, m_iFboHeight);

	gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT | gl.STENCIL_BUFFER_BIT);
	gl.enable(gl.STENCIL_TEST);

	// Imprint a 1 into the stencil buffer to indicate where geometry is found.
	// This optimizes the rendering of directional light sources as the shader then
	// only has to be executed where necessary.
	gl.stencilFunc(gl.ALWAYS, 1, 0xFF);
	gl.stencilOp(gl.KEEP, gl.KEEP, gl.REPLACE);
	
	// Render the objects to the depth and stencil buffers but not to the framebuffer
	gl.colorMask(gl.FALSE, gl.FALSE, gl.FALSE, gl.FALSE);
	this.drawSceneFlatColoured(gl);

	gl.colorMask(gl.TRUE, gl.TRUE, gl.TRUE, gl.TRUE);
	gl.disable(gl.STENCIL_TEST);

	// Bind the GBuffer to the various texture channels so we can access it in the shader
	gl.activeTexture(gl.TEXTURE0);
	gl.bindTexture(gl.TEXTURE_2D, m_uiRenderTextures[eFBO.FBO_ALBEDO]);
	gl.activeTexture(gl.TEXTURE1);
	gl.bindTexture(gl.TEXTURE_2D, m_uiRenderTextures[eFBO.FBO_NORMAL]);
	gl.activeTexture(gl.TEXTURE2);
	gl.bindTexture(gl.TEXTURE_2D, m_uiRenderTextures[eFBO.FBO_DEPTH]);			

	// Disable depth writes as we do not want to modify the depth buffer while rendering the light sources.
	gl.enable(gl.DEPTH_TEST);
	gl.depthMask(gl.FALSE);
	gl.depthFunc(gl.LEQUAL);

	// Additively blend the light contributions
	gl.enable(gl.BLEND);			
	gl.blendFunc(gl.ONE, gl.ONE);	

	//
	//  Render the directional light contribution
	//	
	if (m_uiNumDirectionalLights > 0)
	{
		// Make use of the stencil buffer contents to only shade pixels where actual geometry is located.
		// Reset the stencil buffer to 0 at the same time to avoid the stencil clear operation afterwards.
		gl.enable(gl.STENCIL_TEST);		
		gl.stencilFunc(gl.NOTEQUAL, 0, 0xFF);
		gl.stencilOp(gl.REPLACE, gl.REPLACE, gl.REPLACE);
		
		this.drawDirectionalLightProxies(gl);
	}
	else
	{
		// A directional essentially does a clear for free as it renders a full screen quad 
		// for each directional light and resets the stencil buffer to zero.
		// If there aren't any directional lights do a manual clear.
		gl.clear(gl.STENCIL_BUFFER_BIT);
	}

	
	//
	//  Render the point light contribution
	//		
	if (m_uiNumPointLights > 0)
	{
		
		// Disable back face culling as we are using z-fail similar to shadow volumes to update 
		// the stencil buffer with regions that are affected by the light sources.
		gl.disable(gl.CULL_FACE);

		// Set the stencil test to the z-fail method and disable colour writes
		gl.enable(gl.STENCIL_TEST);
		gl.stencilFunc(gl.ALWAYS, 0, 0xFF);
		gl.stencilMask(0xFF);			
		gl.stencilOpSeparate(gl.FRONT, gl.KEEP, gl.INCR_WRAP, gl.KEEP);			
		gl.stencilOpSeparate(gl.BACK, gl.KEEP, gl.DECR_WRAP, gl.KEEP);
		gl.colorMask(gl.FALSE, gl.FALSE, gl.FALSE, gl.FALSE);					

		this.drawPointLightGeometry(gl, 1.0 );				

		// Set the stencil test to only shade the lit areas and re-enable colour writes.
		gl.stencilFunc(gl.NOTEQUAL, 0, 0xFF);			
		gl.stencilMask(0xFF);
		gl.colorMask(gl.TRUE, gl.TRUE, gl.TRUE, gl.TRUE);

		gl.enable(gl.CULL_FACE);
		gl.cullFace(gl.BACK);
		gl.disable(gl.DEPTH_TEST);
		gl.depthMask(gl.FALSE);
		
		this.drawPointLightProxies(gl);
	}	
	
	// Restore state
	gl.disable(gl.STENCIL_TEST);
	gl.disable(gl.BLEND);	
	gl.enable(gl.DEPTH_TEST);	
	gl.depthMask(gl.TRUE);		
	gl.enable(gl.CULL_FACE);
	gl.cullFace(gl.BACK);						
	gl.depthFunc(gl.LEQUAL);
	gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);	

	// Render the actual light sources to indicate where the light is coming from
	this.drawLightSources(gl);
}


/*!****************************************************************************
 @Function		DrawTransparentObjects
 @Description	Renders the point light sources
******************************************************************************/
 WebGLDeferredShading.prototype.drawLightSources= function(gl)
{
    
	const  iEffectId = m_pPFXEffectParser.findEffectByName(c_sCubeTextureEffectName);
	this.m_ppPFXEffects[iEffectId].activate(gl);	

	// Bind the vertex and index buffer for the point light
	const Mesh = this.m_PointLightModel.data.meshes[0];
	const uiNumFaces = Mesh.data.faces.data.length;//this.m_PointLightModel.data.meshes[0].data.numFaces * 3;//nNumFaces * 3;


	// Bind the vertex buffer object and set attribute locations
	gl.bindBuffer(gl.ARRAY_BUFFER, this.m_uiPointLightModelVBO);
    gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, this.m_uiPointLightModelIBO);

	this.setupVBOAttributes(gl, Mesh, this.m_ppPFXEffects[iEffectId].getUniformArray(), true);
	
	for (let  i=0; i < m_uiNumPointLights; i++)
	{
		let  vColour = new PVRVector4(m_pPointLights[i].vColour.data[0], m_pPointLights[i].vColour.data[1], m_pPointLights[i].vColour.data[2], 0.8 );
		const   mModelViewProj =  PVRMatrix4x4.matrixMultiply( m_mViewProjection , m_pPointLights[i].mTransformation);
	//	let mModelIT  =  PVRMatrix4x4.createMatrix3x3(m_pPointLights[i].mTransformation);
	//	mModelIT =  PVRMatrix3x3.transpose(PVRMatrix3x3.inverse(mModelIT)) ;  // .inverse().transpose();
		
		let mModelIT  =  PVRMatrix4x4.transpose(PVRMatrix4x4.inverse(m_pPointLights[i].mTransformation)) ;  // PVRMatrix4x4.createMatrix3x3(m_pPointLights[i].mTransformation);
		mModelIT =  PVRMatrix4x4.createMatrix3x3(mModelIT);  // .inverse().transpose();

		gl.activeTexture(gl.TEXTURE0);
		gl.bindTexture(gl.TEXTURE_CUBE_MAP, this.m_uiLightEnvironmentMap);		


		gl.uniformMatrix4fv(this.m_pUniformMapping[iEffectId].get(EPVRTPFXUniformSemantic.ePVRTPFX_UsWORLDVIEWPROJECTION), gl.FALSE, mModelViewProj.data);	
		if (this.m_pUniformMapping[iEffectId].has(EPVRTPFXUniformSemantic.ePVRTPFX_UsWORLDIT)){
		
		
		
			gl.uniformMatrix3fv(this.m_pUniformMapping[iEffectId].get(EPVRTPFXUniformSemantic.ePVRTPFX_UsWORLDIT),  gl.FALSE, mModelIT.data);}
		gl.uniform4fv(this.m_pUniformMapping[iEffectId].get(EPVRTPFXUniformSemantic.ePVRTPFX_UsMATERIALCOLORAMBIENT),  vColour.data);


		let datatype = (Mesh.data.faces.indexType  === PVRMesh.EPVRMesh.VertexData.eUnsignedShort) ? gl.UNSIGNED_SHORT : gl.UNSIGNED_INT;

		gl.enable(gl.BLEND);
		gl.blendFunc(gl.ONE, gl.ONE);	
       // gl.drawElements(gl.TRIANGLES, Mesh.nNumFaces*3, datatype, 0);
        gl.drawElements(gl.TRIANGLES, Mesh.data.faces.data.length, datatype, 0);
		gl.disable(gl.BLEND);
	}

	this.setupVBOAttributes(gl, Mesh, this.m_ppPFXEffects[iEffectId].getUniformArray(), false);
	gl.bindBuffer(gl.ARRAY_BUFFER, null);
	gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, null);	
}


/*!***************************************************************************
@Function		DrawPointLightGeometry
@Description	Renders the light proxy geometry using a simple shader
*****************************************************************************/
 WebGLDeferredShading.prototype.drawPointLightGeometry = function( gl, alpha)
{
	const  iEffectId = m_pPFXEffectParser.findEffectByName(c_sSolidColourEffectName);
	this.m_ppPFXEffects[iEffectId].activate(gl);

	gl.enableVertexAttribArray(VERTEX_ARRAY);

	// Bind the vertex and index buffer for the point light
	const Mesh = this.m_PointLightModel.data.meshes[0];
	const uiNumFaces = Mesh.data.faces.data.length;//this.m_PointLightModel.data.meshes[0].data.numFaces * 3;

	// Bind the vertex buffer object and set attribute locations
	gl.bindBuffer(gl.ARRAY_BUFFER, this.m_uiPointLightModelVBO);
	gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, this.m_uiPointLightModelIBO);
	this.setupVBOAttributes(gl, Mesh, this.m_ppPFXEffects[iEffectId].getUniformArray(), true);
	
	for (let  i=0; i < m_uiNumPointLights; i++)
	{
		
		let colour = new PVRVector4(m_pPointLights[i].vColour.data[0], m_pPointLights[i].vColour.data[1], m_pPointLights[i].vColour.data[2], alpha);
		const   mWorldScale = PVRMatrix4x4.matrixMultiply( m_pPointLights[i].mTransformation , m_pPointLights[i].mProxyScale);
		const   mModelViewProj = PVRMatrix4x4.matrixMultiply( m_mViewProjection ,mWorldScale);

		gl.uniform4fv(this.m_pUniformMapping[iEffectId].get(EPVRTPFXUniformSemantic.ePVRTPFX_UsMATERIALCOLORAMBIENT),  colour.data);	
		gl.uniformMatrix4fv(this.m_pUniformMapping[iEffectId].get(EPVRTPFXUniformSemantic.ePVRTPFX_UsWORLDVIEWPROJECTION),  gl.FALSE, mModelViewProj.data);
		
		let datatype = (Mesh.data.faces.indexType  === PVRMesh.EPVRMesh.VertexData.eUnsignedShort) ? gl.UNSIGNED_SHORT : gl.UNSIGNED_INT;
		//gl.drawElements(gl.TRIANGLES, Mesh.nNumFaces*3, datatype, 0);
        gl.drawElements(gl.TRIANGLES, uiNumFaces, datatype, 0);
		
	}
	
	this.setupVBOAttributes(gl, Mesh, this.m_ppPFXEffects[iEffectId].getUniformArray(), false);
	gl.bindBuffer(gl.ARRAY_BUFFER, null);
	gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, null);	
}


/*!***************************************************************************
@Function		DrawLightProxies
@Description	Renders all light proxies for debugging purposes
*****************************************************************************/
 WebGLDeferredShading.prototype.drawPointLightProxies = function(gl)
{
	const  iEffectId = m_pPFXEffectParser.findEffectByName(c_sPointLightEffectName);
	this.m_ppPFXEffects[iEffectId].activate(gl);

	if (this.m_pUniformMapping[iEffectId].has(eCustomSemantics.eCUSTOMSEMANTIC_FARCLIPDISTANCE))
		gl.uniform1f(this.m_pUniformMapping[iEffectId].get(eCustomSemantics.eCUSTOMSEMANTIC_FARCLIPDISTANCE), m_fFarClipDistance);
		
	// Bind the vertex and index buffer for the point light
	const  Mesh = this.m_PointLightModel.data.meshes[0];
	const uiNumFaces =   Mesh.data.faces.data.length; //this.m_PointLightModel.data.meshes[0].data.numFaces * 3;//nNumFaces * 3;
	const  datatype = (Mesh.data.faces.indexType === PVRMesh.EPVRMesh.VertexData.eUnsignedShort) ? gl.UNSIGNED_SHORT : gl.UNSIGNED_INT;

	// Bind the vertex buffer object and set attribute locations
	gl.bindBuffer(gl.ARRAY_BUFFER, this.m_uiPointLightModelVBO);
	gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, this.m_uiPointLightModelIBO);
	this.setupVBOAttributes(gl, Mesh, this.m_ppPFXEffects[iEffectId].getUniformArray(), true);
	
    gl.activeTexture(gl.TEXTURE3);
    
	gl.bindTexture(gl.TEXTURE_CUBE_MAP, this.m_uiLightEnvironmentMap);
		
	for (let  i=0; i < m_uiNumPointLights; i++)
	{
		let  vLightIntensity =  PVRVector3.scalarMultiply( m_pPointLights[i].vColour , c_fPointlightIntensity);
		if (this.m_pUniformMapping[iEffectId].has(EPVRTPFXUniformSemantic.ePVRTPFX_UsLIGHTCOLOR))
			gl.uniform3fv(this.m_pUniformMapping[iEffectId].get(EPVRTPFXUniformSemantic.ePVRTPFX_UsLIGHTCOLOR),  vLightIntensity.data);

		const   mWorldScale =PVRMatrix4x4.matrixMultiply(  m_pPointLights[i].mTransformation , m_pPointLights[i].mProxyScale);
		const   mModelView = PVRMatrix4x4.matrixMultiply (m_mView , mWorldScale);
		const   mModelViewProj = PVRMatrix4x4.matrixMultiply( m_mViewProjection , mWorldScale);
		let mModelIT    =   PVRMatrix4x4.transpose(PVRMatrix4x4.inverse(m_pPointLights[i].mTransformation));
		 mModelIT = PVRMatrix4x4.createMatrix3x3 (mModelIT);
	

		//let mModelIT = PVRMatrix4x4.createMatrix3x3 (m_pPointLights[i].mTransformation);
	//	mModelIT = PVRMatrix3x3.transpose(PVRMatrix3x3.inverse(mModelIT)) 



		gl.uniformMatrix4fv(this.m_pUniformMapping[iEffectId].get(EPVRTPFXUniformSemantic.ePVRTPFX_UsWORLDVIEWPROJECTION),  gl.FALSE, mModelViewProj.data);
		if (this.m_pUniformMapping[iEffectId].has(EPVRTPFXUniformSemantic.ePVRTPFX_UsWORLDVIEW))
			gl.uniformMatrix4fv(this.m_pUniformMapping[iEffectId].get(EPVRTPFXUniformSemantic.ePVRTPFX_UsWORLDVIEW),  gl.FALSE, mModelView.data);
		if (this.m_pUniformMapping[iEffectId].has(EPVRTPFXUniformSemantic.ePVRTPFX_UsWORLDIT))
			gl.uniformMatrix3fv(this.m_pUniformMapping[iEffectId].get(EPVRTPFXUniformSemantic.ePVRTPFX_UsWORLDIT),  gl.FALSE, mModelIT.data);

		let  vLightPosView = PVRMatrix4x4.multiply( PVRMatrix4x4.matrixMultiply(  m_mView , m_pPointLights[i].mTransformation) , new PVRVector4(0.0 , 0.0 , 0.0 , 1.0));  //new PVRVector4
		vLightPosView = new PVRVector3(vLightPosView.data[0],vLightPosView.data[1],vLightPosView.data[2] );

		if (this.m_pUniformMapping[iEffectId].has(eCustomSemantics.eCUSTOMSEMANTIC_POINTLIGHT_VIEWPOSITION))
			gl.uniform3fv(this.m_pUniformMapping[iEffectId].get(eCustomSemantics.eCUSTOMSEMANTIC_POINTLIGHT_VIEWPOSITION), vLightPosView.data);				
				
		gl.drawElements(gl.TRIANGLES, uiNumFaces, datatype, 0);		
	}
	
	this.setupVBOAttributes(gl, Mesh, this.m_ppPFXEffects[iEffectId].getUniformArray(), false);
	gl.bindBuffer(gl.ARRAY_BUFFER, null);
	gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, null);	
}

/*!***************************************************************************
@Function		DrawDirectionalLightProxies
@Description	Renders all directional lights
*****************************************************************************/
 WebGLDeferredShading.prototype.drawDirectionalLightProxies= function(gl)
{
	const  iEffectId = m_pPFXEffectParser.findEffectByName(c_sDirectionalLightEffectName);
	this.m_ppPFXEffects[iEffectId].activate(gl);	

	for (let  i=0; i < m_uiNumDirectionalLights; i++)
	{
		let  vLightIntensity = PVRVector3.scalarMultiply( m_pDirectionalLights[i].vColour , c_fDirectionallightIntensity);
		if (this.m_pUniformMapping[iEffectId].has(EPVRTPFXUniformSemantic.ePVRTPFX_UsLIGHTCOLOR))
			gl.uniform3fv(this.m_pUniformMapping[iEffectId].get(EPVRTPFXUniformSemantic.ePVRTPFX_UsLIGHTCOLOR), vLightIntensity.data);

		const   mModelView = PVRMatrix4x4.matrixMultiply( m_mView ,m_pDirectionalLights[i].mTransformation);
		const   mModelViewProj = PVRMatrix4x4.matrixMultiply( m_mViewProjection , m_pDirectionalLights[i].mTransformation);

		if (this.m_pUniformMapping[iEffectId].has(eCustomSemantics.eCUSTOMSEMANTIC_DIRECTIONALLIGHT_DIRECTION))
		{ 
			let vLightDirView  = PVRMatrix4x4.multiply( mModelView , m_pDirectionalLights[i].vDirection); //new PVRVector4
			gl.uniform4fv(this.m_pUniformMapping[iEffectId].get(eCustomSemantics.eCUSTOMSEMANTIC_DIRECTIONALLIGHT_DIRECTION), vLightDirView.data);
		}

		this.drawAxisAlignedQuad(gl, new PVRVector2(-1.0 , -1.0 ), new PVRVector2(1.0 , 1.0 ), this.m_ppPFXEffects[iEffectId].getUniformArray());	
	}			
}


/*!****************************************************************************
 @Function		DrawAxisAlignedQuad
 @Input			afLowerLeft		Lower left corner of the quad in normalized device coordinates
                afUpperRight    Upper right corner of the quad in normalized device coordinates
 @Description	Draws a textured quad
******************************************************************************/
//</SPVRTPFXUniform>void WebGLDeferredShading.prototype.DrawAxisAlignedQuad(PVRTVec2 afLowerLeft, PVRTVec2 afUpperRight, const CPVRTArray<SPVRTPFXUniform>& aUniforms)
 WebGLDeferredShading.prototype.drawAxisAlignedQuad = function(gl, afLowerLeft,  afUpperRight,  aUniforms)

{
	const  afVertexData = [ afLowerLeft.data[0], afLowerLeft.data[1], 0.0 , 0.0,0.0, afUpperRight.data[0], afLowerLeft.data[1], 0.0 ,1.0,0.0,
		                           afLowerLeft.data[0], afUpperRight.data[1], 0.0 , 0.0,1.0, afUpperRight.data[0], afUpperRight.data[1], 0.0 ,1.0,1.0];
	//const  afTexCoordData = [ 0, 0,  1, 0,  0, 1,  1, 1 ];

if (!this.afVertexDataBuffer) {this.afVertexDataBuffer = gl.createBuffer()
	gl.bindBuffer(gl.ARRAY_BUFFER, this.afVertexDataBuffer);
	gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(afVertexData), gl.STATIC_DRAW);
}
else{
	gl.bindBuffer(gl.ARRAY_BUFFER, this.afVertexDataBuffer);
}

	for(let  j = 0; j < aUniforms.length ; ++j)
	{
		switch(aUniforms[j].nSemantic){
		case EPVRTPFXUniformSemantic.ePVRTPFX_UsPOSITION:
				
				gl.vertexAttribPointer(aUniforms[j].nLocation, 3, gl.FLOAT, gl.FALSE, 20, 0);
                gl.enableVertexAttribArray(aUniforms[j].nLocation);
			
			break;
		case EPVRTPFXUniformSemantic.ePVRTPFX_UsUV:
             
				gl.vertexAttribPointer(aUniforms[j].nLocation, 2, gl.FLOAT, gl.FALSE, 20, 12);
                gl.enableVertexAttribArray(aUniforms[j].nLocation);
			
            break;
            default: break;
		}
	}	



	// Draw the quad
	gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);

	for(let  j = 0; j < aUniforms.length; ++j)
	{
		switch(aUniforms[j].nSemantic)
		{
		case EPVRTPFXUniformSemantic.ePVRTPFX_UsPOSITION:
			gl.disableVertexAttribArray(aUniforms[j].nLocation);
			break;
		case EPVRTPFXUniformSemantic.ePVRTPFX_UsUV:
			gl.disableVertexAttribArray(aUniforms[j].nLocation);
            break;
            default:break;
		}
	}
}


/*!****************************************************************************
 @Function		HandleInput
 @Description	Handles user input
******************************************************************************/
 WebGLDeferredShading.prototype.handleInput= function(PVRShell)
{
	
	// Handle input


	 if (PVRShell.isKeyPressed(PVRShell.KeyNameLEFT))
	  {

		if (m_RenderMode === 0)
			m_RenderMode = RenderMode.NUM_RENDER_MODES - 1;
		else
			m_RenderMode--;
	}
	 else if (PVRShell.isKeyPressed(PVRShell.KeyNameRIGHT))
	{
		m_RenderMode = ++m_RenderMode % RenderMode.NUM_RENDER_MODES;
	}
	else if (PVRShell.isKeyPressed(PVRShell.KeyNameUP))
	{
		
		m_uiCameraId = ++m_uiCameraId % this.m_Scene.pod.data.numCameras;
	}
	else if (PVRShell.isKeyPressed(PVRShell.KeyNameDOWN))
	{

		if (m_uiCameraId === 0)
			m_uiCameraId = this.m_Scene.pod.data.numCameras - 1;
		else
			m_uiCameraId--;
	}	
	// else if (PVRShellIsKeyPressed(PVRShellKeyNameSELECT))
	// {
	// 	m_bPaused = !m_bPaused;
	// }
}

/*!****************************************************************************
 @Function		UpdateAnimation
 @Description	Updates animation variables and camera matrices.
******************************************************************************/
 WebGLDeferredShading.prototype.updateAnimation = function(PVRShell)
{
	
	let ulTime = PVRShell.getTimeNow();
	let ulDeltaTime = ulTime - ulPrevTime;
	ulPrevTime = ulTime;
	if (!m_bPaused)
	{
		m_fFrame += ulDeltaTime * c_fDemoFrameRate;		
		if (m_fFrame > this.m_Scene.pod.data.numFrames - 1) m_fFrame = 0;		
	}
	
	this.m_Scene.pod.setCurrentFrame(m_fFrame);

	//
	// Copy current frames light attributes
	//		
	for (let  i=0; i < m_uiNumPointLights; i++)
	{	
		m_pPointLights[i].mTransformation = this.m_Scene.pod.getWorldMatrix(m_pPointLights[i].uiNodeIdx);
		m_pPointLights[i].mProxyScale =  PVRMatrix4x4.scale(c_fPointLightScale * c_fPointlightIntensity, c_fPointLightScale * c_fPointlightIntensity, c_fPointLightScale*c_fPointlightIntensity) ;
	}
			
	for (let  i=0; i < m_uiNumDirectionalLights; i++)
	{		
		m_pDirectionalLights[i].mTransformation = this.m_Scene.pod.getWorldMatrix(m_pDirectionalLights[i].uiNodeIdx);
		m_pDirectionalLights[i].vDirection =PVRMatrix4x4.multiply(  m_pDirectionalLights[i].mTransformation , new PVRVector4(0.0 , -1.0 , 0.0 , 0.0));			
	}	

    let vTo =new PVRVector3(0,0,0);
    let vUp =new PVRVector3(0,0,0);
    
   //this.this.m_Scene.getCamera( vFrom, vTo, vUp, g_ui32Camera);    
    let props =  this.m_Scene.pod.getCameraProperties(m_uiCameraId);
    vUp = props.up;
    m_vCameraPosition = props.from;
    vTo = props.to;


	let fNearClipDistance = this.m_Scene.pod.data.cameras[m_uiCameraId].data.near;
	m_fFarClipDistance = this.m_Scene.pod.data.cameras[m_uiCameraId].data.far;
    let fFieldOfView = this.m_Scene.pod.data.cameras[m_uiCameraId].data.FOVs[0];
	//console.log("this.m_Scene.pod.data.cameras[m_uiCameraId].data",this.m_Scene.pod.data.cameras[m_uiCameraId].data);
	//
	// Update camera matrices
	//
	m_mProjection =  PVRMatrix4x4.createPerspectiveProjection(fNearClipDistance, m_fFarClipDistance,  fFieldOfView, m_iFboWidth / m_iFboHeight);
	m_mView =  PVRMatrix4x4.createLookAt(m_vCameraPosition, vTo, vUp);	
	m_mViewProjection = PVRMatrix4x4.matrixMultiply( m_mProjection  ,m_mView);
	m_mInverseView = PVRMatrix4x4.inverse( m_mView);	

}


}

export default WebGLDeferredShading;

