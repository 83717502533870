/*
	PVRTexture
*/

import React from 'react'
import PVRFileStream from "./PVRFileStream"
import PVRTDecompressPVRTC from './Decompose'





const PVRTextureHeader = function()
{
    this.version = 0x03525650;
    this.flags = 0;
    this.pixelFormatH = 0;
    this.pixelFormatL = 0;
    this.colourSpace = 0;
    this.channelType = 0;
    this.height = 1;
    this.width = 1;
    this.depth = 1;
    this.numSurfaces = 1;
    this.numFaces = 1;
    this.MIPMapCount = 1;
    this.metaDataSize = 0;
}


const CompressedPixelFormat =
{
	PVRTCI_2bpp_RGB : 0,
	PVRTCI_2bpp_RGBA: 1,
	PVRTCI_4bpp_RGB: 2,
	PVRTCI_4bpp_RGBA:3,
	PVRTCII_2bpp:4,
	PVRTCII_4bpp:5,
}

const EPVRTexture =
{
    ChannelTypes:
    {
        UnsignedByteNorm : 0,
        SignedByteNorm : 1,
        UnsignedByte : 2,
        SignedByte: 3,
        UnsignedShortNorm: 4,
        SignedShortNorm: 5,
        UnsignedShort: 6,
        SignedShort: 7,
        UnsignedIntegerNorm: 8,
        SignedIntegerNorm: 9,
        UnsignedInteger: 10,
        SignedInteger: 11,
        SignedFloat: 12,
        Float: 12, //the name Float is now deprecated.
        UnsignedFloat: 13,
    }
}


export  class PVRTexture  extends React.Component
{


static   PVRTEX3_IDENT =  0x03525650;

	render(){
        return null
    }

 static genPixelTypeH(c1Name, c2Name, c3Name, c4Name)
{
    let val = 0;
    val |= c1Name.charCodeAt();

    if(c2Name !== undefined)
        val |= c2Name.charCodeAt() << 8;

    if(c3Name !== undefined)
        val |= c3Name.charCodeAt() << 16;

    if(c4Name !== undefined)
        val |= c4Name.charCodeAt() << 24;

    return val;
}

static genPixelTypeL(c1Bits, c2Bits, c3Bits, c4Bits)
{
    let val = 0;
    val |= c1Bits;

    if(c2Bits !== undefined)
        val |= c2Bits << 8;

    if(c3Bits !== undefined)
        val |= c3Bits << 16;

    if(c4Bits !== undefined)
        val |= c4Bits << 24;

    return val;
}

static getTextureFormat(gl, header)
{
    let ret = {};
    ret.format = 0;
    ret.type   = 0;
    ret.internalFormat = 0;

    if(header.pixelFormatL === 0 )
    {
        // TODO: Compressed texture support.
	  switch(header.pixelFormatH ){
case CompressedPixelFormat.PVRTCI_2bpp_RGBA:
  
        ret.internalFormat = 0x8C03;
        return ret;
case CompressedPixelFormat.PVRTCI_4bpp_RGBA:
        ret.internalFormat = 0x8C02;
       return ret;
default: break;

      }
     
        return ;
    }

    switch(header.channelType)
    {
        

        case EPVRTexture.ChannelTypes.Float:
        {
            // TODO: Add support.
            // console.loglog("EPVRTexture.ChannelTypes.Float");
            return ;
        }
        case EPVRTexture.ChannelTypes.UnsignedByteNorm:
        {
            // console.loglog("EPVRTexture.ChannelTypes.UnsignedByteNorm");
            ret.type = gl.UNSIGNED_BYTE;
            // console.loglog(header.pixelFormatL);
            switch(header.pixelFormatL)
            {
                case PVRTexture.genPixelTypeL(8,8,8,8):
                    // console.loglog("8888");
                    if(header.pixelFormatH === PVRTexture.genPixelTypeH('r', 'g', 'b', 'a'))
                        ret.format = ret.internalFormat = gl.RGBA;
                    else
                        ret.format = ret.internalFormat = gl.BGRA;
                    break;
                case PVRTexture.genPixelTypeL(8,8,8):
                    // console.loglog("888");
                    ret.format = ret.internalFormat = gl.RGB;
                    break;
                case PVRTexture.genPixelTypeL(8,8):
                        // console.loglog("88");
                    ret.format = ret.internalFormat = gl.LUMINANCE_ALPHA;
                    break;
                case PVRTexture.genPixelTypeL(8):
                        // console.loglog("8");
                    if(header.pixelFormatH === PVRTexture.genPixelTypeH('l'))
                        ret.format = ret.internalFormat = gl.LUMINANCE;
                    else
                        ret.format = ret.internalFormat = gl.ALPHA;
                    break;

                  
                    default:   
                    if(header.pixelFormatH === PVRTexture.genPixelTypeH('r', 'g', 'b', 'a'))
                    {// console.loglog("rgba");
                        ret.format = ret.internalFormat = gl.RGBA;}
                       else if(header.pixelFormatH === PVRTexture.genPixelTypeH('l'))
                        {// console.loglog("l");
                            ret.format = ret.internalFormat = gl.RGBA;}
                            else if(header.pixelFormatH === PVRTexture.genPixelTypeH('a'))
                            {// console.loglog("a");
                                ret.format = ret.internalFormat = gl.RGBA;}

                    else
                    {// console.loglog("bgra", gl.RGB);
                        ret.format = ret.internalFormat = gl.RGBA;}
                    break;
            }
            break;
        }
        case EPVRTexture.ChannelTypes.UnsignedShortNorm:
        {
            
            switch(header.pixelFormatL)
            {
           
                case PVRTexture.genPixelTypeL(4,4,4,4):
                    ret.type = gl.UNSIGNED_SHORT_4_4_4_4;
                    ret.format =  gl.RGBA;
                    ret.internalFormat = gl.RGBA;
                    break;
                case PVRTexture.genPixelTypeL(5,5,5,1):
                     
                    ret.type = gl.UNSIGNED_SHORT_5_5_5_1;
                    ret.format = ret.internalFormat = gl.RGBA;
                    break;
                case PVRTexture.genPixelTypeL(5,6,5):
                      
                    ret.type = gl.UNSIGNED_SHORT_5_6_5;
                    ret.format = ret.internalFormat = gl.RGB;
                    break;
                    default: break;
            }
            break;
        }
         default: break;
    }

    return ret;
}

static getBitsPerPixel(header)
{
 
     if(header.pixelFormatH === CompressedPixelFormat.PVRTCI_4bpp_RGBA){return 4;}
     else if(header.pixelFormatH === CompressedPixelFormat.PVRTCI_2bpp_RGBA){return 2;}
    if(header.pixelFormatH !== 0)
    {
        let lowPart = header.pixelFormatL;
        let c1Bits  = (lowPart >> 24) & 0xFF;
        let c2Bits  = (lowPart >> 16) & 0xFF;
        let c3Bits  = (lowPart >> 8)  & 0xFF;
        let c4Bits  = lowPart & 0xFF;
        return c1Bits + c2Bits + c3Bits + c4Bits;
    }
    else {
        let lowPart = header.pixelFormatL;
        let c1Bits  = (lowPart >> 24) & 0xFF;
        let c2Bits  = (lowPart >> 16) & 0xFF;
        let c3Bits  = (lowPart >> 8)  & 0xFF;
        let c4Bits  = lowPart & 0xFF;
        return c1Bits + c2Bits + c3Bits + c4Bits;
    }

    // TODO: Compressed texture support.

    //return 0;
}

static getDataSize(header, MIPLevel, allSurfaces, allFaces)
{
    let smallestWidth = 1;
    let smallestHeight = 1;
    let smallestDepth = 1;

    let pixelFormatL = header.pixelFormatL;

    if(pixelFormatL === 0)
    {
        // TODO: Handle compressed textures.
        switch(header.pixelFormatH ){
            case CompressedPixelFormat.PVRTCI_2bpp_RGBA:
                     smallestWidth = 16;
                     smallestHeight = 8;
                     smallestDepth = 1;
                   
                break;
            case CompressedPixelFormat.PVRTCI_4bpp_RGBA:
               
                    smallestWidth = 8;
                    smallestHeight = 8;
                    smallestDepth = 1;

                break;
               default: break;
        }

       // PVRTexture.getFormatMinDims(header);
    }

    let dataSize = 0;
    if(MIPLevel === -1)
    {
        for(let currentMIP = 0; currentMIP < header.MIPMapCount; ++currentMIP)
        {
            let width = Math.max(1, header.width>>currentMIP);
            let height = Math.max(1, header.height>>currentMIP);
            let depth = Math.max(1, header.depth>>currentMIP);

            if(header.pixelFormatL === 0)
            {
                // Pad the dimensions if the texture is compressed
                width = width + ((-1*width)%smallestWidth);
                height = height + ((-1*height)%smallestHeight);
                depth = depth + ((-1*depth)%smallestDepth);
            }

            // Add the current MIP map's data size
            dataSize += PVRTexture.getBitsPerPixel(header) * width * height * depth;
        }
    }
    else
    {
       
        let width = Math.max(1, header.width>>MIPLevel);
        let height = Math.max(1, header.height>>MIPLevel);
        let depth = Math.max(1, header.depth>>MIPLevel);
  
        if(header.pixelFormatL === 0)
        {
            // Pad the dimensions if the texture is compressed
            width = width + ((-1*width)%smallestWidth);
            height = height + ((-1*height)%smallestHeight);
            depth = depth + ((-1*depth)%smallestDepth);
        }

        // Add the current MIP map's data size
       
        dataSize += PVRTexture.getBitsPerPixel(header) * width * height * depth;
    
    }


  //if(dataSize == 0){ console.log("headersize", header.width, header.height, header.depth, MIPLevel, smallestWidth, smallestHeight, smallestDepth   )}
    let numFaces = (allFaces ? header.numFaces : 1);
    let numSurfs = (allSurfaces ? header.numSurfaces : 1);

    return (dataSize/8) * numSurfs * numFaces;
}

static loadFromURI (gl, uri, loadFromLevel, callback)
{
    let fs = new PVRFileStream();
    let args = [uri, true, PVRTexture.loadFromMemory, gl, {}, loadFromLevel, null, callback];
    args = args.concat(Array.prototype.slice.call(arguments, 4));
    
    fs.Open.apply(fs, args);
}

static copyHeader(header){
    let pvrHeader = new PVRTextureHeader();
    pvrHeader.version = header.version
    pvrHeader.flags        =  header.flags
    pvrHeader.pixelFormatH = header.pixelFormatH
    pvrHeader.pixelFormatL =  header.pixelFormatL
    pvrHeader.colourSpace  =  header.colourSpace 
    pvrHeader.channelType  =  header.channelType
    pvrHeader.height       = header.height
    pvrHeader.width        = header.width
    pvrHeader.depth        = header.depth   
    pvrHeader.numSurfaces  =  header.numSurfaces 
    pvrHeader.numFaces     =  header.numFaces 
    pvrHeader.MIPMapCount  = header.MIPMapCount
    pvrHeader.metaDataSize = header.metaDataSize
    return pvrHeader

}


static loadFromMemory (stream, gl, header, loadFromLevel, metaData, callback)
{
  
    let pvrHeader = new PVRTextureHeader();
    let pvrMetaData = {};
    pvrHeader.version = stream.ReadUInt32();
   
    if(pvrHeader.version !== PVRTexture.PVRTEX3_IDENT)
    {
        console.log(pvrHeader.version);
        console.log(PVRTexture.PVRTEX3_IDENT);
        console.log("version2");
        //return false;
    }

    pvrHeader.flags        = stream.ReadUInt32();
    pvrHeader.pixelFormatH = stream.ReadUInt32();
    pvrHeader.pixelFormatL = stream.ReadUInt32();
    pvrHeader.colourSpace  = stream.ReadUInt32();
    pvrHeader.channelType  = stream.ReadUInt32();
    pvrHeader.height       = stream.ReadUInt32();
    pvrHeader.width        = stream.ReadUInt32();
    pvrHeader.depth        = stream.ReadUInt32();
    pvrHeader.numSurfaces  = stream.ReadUInt32();
    pvrHeader.numFaces     = stream.ReadUInt32();
    pvrHeader.MIPMapCount  = stream.ReadUInt32();
    pvrHeader.metaDataSize = stream.ReadUInt32();
    // console.loglog("pvrHeader.metaDataSize",pvrHeader.metaDataSize);
    //Get the meta data.
    




    let metaDataSize = 0;
    while(metaDataSize < pvrHeader.metaDataSize)
    {
        let devFourCC = stream.ReadUInt32();
        metaDataSize += 4;

        let key = stream.ReadUInt32();
        metaDataSize += 4;

        let dataSize = stream.ReadUInt32();
        metaDataSize += 4;

        if(!(devFourCC in pvrMetaData))
            pvrMetaData[devFourCC] = {}

        pvrMetaData[devFourCC][key] = {}
        let currentData = pvrMetaData[devFourCC][key];
        currentData.devFourCC = devFourCC;
        currentData.key = key;
        currentData.dataSize = dataSize;

        if(dataSize > 0)
        {
            currentData.data = stream.ReadArrayBuffer(dataSize);
            metaDataSize += dataSize;
        }
    }

    let ret = PVRTexture.getTextureFormat(gl, pvrHeader);
    

    let textureFormat         = ret.format;
    let textureInternalFormat = ret.internalFormat;
    let textureType           = ret.type;

    if(textureInternalFormat === 0)
        return false;

    gl.pixelStorei(gl.UNPACK_ALIGNMENT, 1);
    let textureID = gl.createTexture();
    let target    = gl.TEXTURE_2D;

    if(pvrHeader.numFaces > 1)
    {
        target = gl.TEXTURE_CUBE_MAP;
    }

    if(pvrHeader.numSurfaces > 1)
    {
        console.log("numSurfaces----------------------------", 1000 );
        return false;
    }

    gl.bindTexture(target, textureID);

    let currentMIPSize = 0;
 
    // Loop through the faces
    if(pvrHeader.numFaces > 1)
    target = gl.TEXTURE_CUBE_MAP_POSITIVE_X;

    let MIPWidth = pvrHeader.width;
    let MIPHeight = pvrHeader.height;

   
// switch (target) {
//    case gl.TEXTURE_CUBE_MAP: console.log("gl.TEXTURE_CUBE_MAP");break;
//    case gl.TEXTURE_2D: console.log("gl.TEXTURE_2D") ;break;
//    case gl.TEXTURE_3D: console.log("gl.TEXTURE_3D");break;
//    case gl.TEXTURE_CUBE_MAP_POSITIVE_X: console.log("gl.TEXTURE_CUBE_MAP_POSITIVE_X");break;
//    default: break;
// }

if (pvrHeader.pixelFormatL === 0){
     
     let destHeader = PVRTexture.copyHeader(pvrHeader);
     textureInternalFormat = gl.RGBA;
textureFormat = gl.RGBA;
textureType = gl.UNSIGNED_BYTE;
destHeader.pixelFormatL = PVRTexture.genPixelTypeL(8, 8, 8, 8);
destHeader.pixelFormatH = PVRTexture.genPixelTypeH('r', 'g', 'b', 'a');
destHeader.channelType = EPVRTexture.ChannelTypes.UnsignedByteNorm;
// console.log("pvrHeader", destHeader);
// console.log("textureInternalFormat",textureInternalFormat);
// console.log("textureFormat",textureFormat);
// console.log("textureType",textureType);

//     destHeader.pixelFormatL = ;
//     destHeader.pixelFormatH = ;
//    destHeader.
    // currentMIPSize = PVRTexture.getDataSize(pvrHeader, MIPLevel, false, false);  
    // let length = PVRTexture.getDataSize(destHeader, MIPLevel, false, false);
     //let compressedTextureData =   stream.ReadByteArray(currentMIPSize);
 
    


    // var ext = gl.getSupportedExtensions();//getExtension("WEBGL_compressed_texture_pvrtc");
   
    // for(let MIPLevel = 0; MIPLevel < pvrHeader.MIPMapCount; ++MIPLevel)
    // { 
    //     let length = PVRTexture.getDataSize(destHeader, MIPLevel, false, false);
    //     let compressedTextureData =   stream.ReadByteArray(currentMIPSize);
        
    //     for(let face = 0; face < pvrHeader.numFaces; ++face)
    //     {

       // if(PVRTexture.getBitsPerPixel(pvrHeader) ===4 ) {alert("4bpp")}
        //if(PVRTexture.getBitsPerPixel(pvrHeader) ===2 ) {console.log("2bpp")}
    //     }
    //       // Reduce the MIP size
    //       MIPWidth = Math.max(1, MIPWidth >> 1);
    //       MIPHeight = Math.max(1, MIPHeight >> 1);
    // }

    for(let MIPLevel = 0; MIPLevel < pvrHeader.MIPMapCount; ++MIPLevel)
    {
        currentMIPSize = PVRTexture.getDataSize(pvrHeader, MIPLevel, false, false);
    
        let length = PVRTexture.getDataSize(destHeader, MIPLevel, false, false);
       
        let eTextureTarget = target;
    
        for(let face = 0; face < pvrHeader.numFaces; ++face)
        {
            if(MIPLevel >= loadFromLevel)
             {
              // let textureData = stream.ReadByteArray(currentMIPSize);
               
              let compressedTextureData =   stream.ReadByteArray(currentMIPSize);
               
              let textureData =   PVRTDecompressPVRTC(compressedTextureData, (PVRTexture.getBitsPerPixel(pvrHeader) === 2 ? 1 : 0),
              MIPWidth, MIPHeight, length/4);

// if (target === gl.TEXTURE_CUBE_MAP_POSITIVE_X && MIPLevel === pvrHeader.MIPMapCount-4 && face === 0){
// //     console.log(compressedTextureData)
// //     console.log(textureData)
// //     console.log(PVRTexture.getBitsPerPixel(pvrHeader) === 2 ? 1 : 0);
// // console.log("MIPWidth",MIPWidth);
// // console.log("MIPWidth",MIPHeight);
// // console.log("MIPWidth",length);

// };

             //let textureData = textureType ===  gl.UNSIGNED_BYTE ?  stream.ReadByteArray(currentMIPSize) : stream.ReadUInt16Array(currentMIPSize/2);
             gl.texImage2D(eTextureTarget, MIPLevel-loadFromLevel, textureInternalFormat, MIPWidth, MIPHeight, 0, textureFormat, textureType, textureData);
                //ext.COMPRESSED_RGBA_PVRTC_4BPPV1_IMG, MIPWidth, MIPHeight, 0, textureData);
            }
            eTextureTarget++;
        }


        // Reduce the MIP size
        MIPWidth = Math.max(1, MIPWidth >> 1);
        MIPHeight = Math.max(1, MIPHeight >> 1);
    }

    //pvrHeader = destHeader;
}
else{



    for(let MIPLevel = 0; MIPLevel < pvrHeader.MIPMapCount; ++MIPLevel)
    {
       
        currentMIPSize = PVRTexture.getDataSize(pvrHeader, MIPLevel, false, false);
        let eTextureTarget = target;
    
        for(let face = 0; face < pvrHeader.numFaces; ++face)
        {
            if(MIPLevel >= loadFromLevel)
            {
              // let textureData = stream.ReadByteArray(currentMIPSize);

             let textureData = textureType ===  gl.UNSIGNED_BYTE ?  stream.ReadByteArray(currentMIPSize) : stream.ReadUInt16Array(currentMIPSize/2);
             //if (target === gl.TEXTURE_CUBE_MAP_POSITIVE_X && MIPLevel === pvrHeader.MIPMapCount-4 && face ===0 ){console.log("unfolded",textureData)}; 
             
             gl.texImage2D(eTextureTarget, MIPLevel-loadFromLevel, textureInternalFormat, MIPWidth, MIPHeight, 0, textureFormat, textureType, textureData);
            
 
            }
            eTextureTarget++;
        }


        // Reduce the MIP size
        MIPWidth = Math.max(1, MIPWidth >> 1);
        MIPHeight = Math.max(1, MIPHeight >> 1);
    }
}
    if(header != null)
        header = pvrHeader;

    if(metaData != null)
        metaData = pvrMetaData;

    if(typeof callback == "function")
    {
        let args = [gl, textureID, pvrHeader, pvrMetaData, target];
        args = args.concat(Array.prototype.slice.call(arguments, 6));
      
        callback.apply(null, args);
    }

    return true;
}



static getDataPointer = function(pTextureData, header, mipMapLevel /*= 0*/,  arrayMember /*= 0*/,  face /*= 0*/)

//unsigned char* Texture::getDataPointer(uint32_t mipMapLevel /*= 0*/, uint32_t arrayMember /*= 0*/, uint32_t face /*= 0*/)
{
	// Initialize the offSet value.
	let offSet = 0;

	// Error checking
	// if ((mipMapLevel == pvrTextureAllMipMaps) || mipMapLevel >= getNumMipMapLevels())
	// {
	// 	throw InvalidArgumentError("mipmapLevel", "Texture::getDataPointer: Specified mipmap level did not exist");
	// }
	// if (arrayMember >= getNumArrayMembers())
	// {
	// 	throw InvalidArgumentError("arrayMember", "Texture::getDataPointer: Specified array member did not exist");
	// }
	// if (face >= getNumFaces())
	// {
	// 	throw InvalidArgumentError("face", "Texture::getDataPointer: Specified face did not exist");
	// }

	// File is organised by MIP Map levels, then surfaces, then faces.

	// Get the start of the MIP level.
	if (mipMapLevel !== 0)
	{
		// Get the size for all MIP Map levels up to this one.
		for (let uiCurrentMipMap = 0; uiCurrentMipMap < mipMapLevel; ++uiCurrentMipMap)
		{
			offSet += PVRTexture.getDataSize(header, uiCurrentMipMap, true, true);
		}
	}

	// Get the start of the array.
	if (arrayMember !== 0)
	{
		offSet += arrayMember * PVRTexture.getDataSize(header, mipMapLevel, false, true);
	}

    let length = PVRTexture.getDataSize(header, mipMapLevel, false, false);
	// Get the start of the face.
	if (face !== 0)
	{
		offSet += face * length;//getDataSize(mipMapLevel, false, false);
	}

	// Return the data pointer plus whatever offSet has been specified.
	return pTextureData.slice(offSet, offSet + length);
}


}

export default PVRTexture