/*
	PVRShader
*/


import React from 'react'

export  class PVRShader   extends React.Component
{


	render(){
        return null
    }
	
 static  loadFromURI  (gl, uri, type, defines, errorFunc, callback)
{
	function loaded()
	{
		let req = this;
		if(req.readyState === 4)
		{
			let shader = PVRShader.loadFromMemory(gl, req.responseText, type, defines, errorFunc);
			if(typeof callback == "function")
				callback(this.shader);			// Asynchronous
			else
				return shader;                  // Synchronous
		}
		else
		{
			errorFunc("Failed to load file: " + uri);
		}
	}

	let async   = (typeof callback == "function" ? true : false);
	let httpReq = new XMLHttpRequest();
	httpReq.open("GET", uri, async);
	if(async)
		httpReq.onreadystatechange = loaded;
	httpReq.send(null);

	if(!async)
	{
		return loaded.call(httpReq);
	}

	return null;
}

static  loadFromMemory  (gl, source, type, defines, errorFunc)
{
	// Append defines, if any
	let processedSource = "";
	if (defines.length > 0)
	{
		// Check for the #version tag
		let versionRX = /\s*#\s*version\s*\d+\s*\w*/
		let ver       = source.match(versionRX);
		if (ver)
		{
			// The source contains a #version tag, and needs to be placed first.
			processedSource += ver + "\n";
			source = source.slice(ver.length);
		}

		for (let key in defines)
		{
			processedSource += "#define " + defines[key] + "\n";
		}
	}


	// Append the source to the definition string
	processedSource += source;
//console.log("source:", processedSource);
	// Create the shader object and compile
	let shader = gl.createShader(type);

	gl.shaderSource(shader, processedSource);
	gl.compileShader(shader);

	// Test if compilation was successful
	if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS))
	{
		let errorLog = gl.getShaderInfoLog(shader);
		if (typeof errorFunc == "function")
			errorFunc(errorLog);
		else
			throw new Error(errorLog);

		gl.deleteShader(shader);
		return null;
	}

	return shader
}

static createProgram (gl, vertex, fragment, attribs, errorFunc)
{
	// Create the program and attach the shaders
	let program = gl.createProgram();
	gl.attachShader(program, vertex);
	gl.attachShader(program, fragment);

	// Bind attributes
	for (let i = 0; i < attribs.length; i++)
	{
		gl.bindAttribLocation(program, i, attribs[i]);
	}

	// Link the program
	gl.linkProgram(program);
	if (!gl.getProgramParameter(program, gl.LINK_STATUS))
	{
		let errorLog = gl.getProgramInfoLog(program);
		if (typeof errorFunc == "function")
			errorFunc(errorLog);
		else
			throw new Error(errorLog);

		gl.deleteProgram(program);
		return null;
	}

	// Make the program active
	gl.useProgram(program);

	return program;
}



}

export default PVRShader