/*
	PVRMesh
*/


import React from 'react'
import {EPODErrorCodes} from "./PVRPODLoader"



class VertexData {
		
	constructor(semantic, type, numComponents, stride, offset, dataIndex)
{
	
	this.semantic = semantic;
	this.dataType = type;
	this.numComponents = numComponents;
	this.stride = stride;
	this.offset = offset;
	this.dataIndex = dataIndex;
}
}


class PVRMesh  extends React.Component{


	render(){return null}


	static EPVRMesh =
{
	eTriangleList:          0,
	eIndexedTriangleList:   1,
	eTriangleStrips:        2,
	eIndexedTriangleStrips: 3,
	eTriPatchList:          4,
	eQuadPatchList:         5,
	
	VertexData:
	{
		eNone:               0,
		eFloat:              1,
		eInt:                2,
		eUnsignedShort:      3,
		eRGBA:               4,
		eARGB:               5,
		eD3DCOLOR:           6,
		eUBYTE4:             7,
		eDEC3N:              8,
		eFixed16_16:         9,
		eUnsignedByte:      10,
		eShort:             11,
		eShortNorm:         12,
		eByte:              13,
		eByteNorm:          14,
		eUnsignedByteNorm:  15,
		eUnsignedShortNorm: 16,
		eUnsignedInt:       17,
		eABGR:              18,

		eCustom:            1000,
	},
	
	FaceData:
	{
		e16Bit: 3,
		e32Bit: 17,
	},
}


	// Public data
	data = {
		unpackMatrix: [],
		vertexElementData: [], // Allows us to have multiple interleaved/non-interleaved arrays.
		vertexElements: {},   // Map of semantics (e.g POSITION0, NORMALS0)
		
		primitiveData:{
			numVertices: 0,
			numFaces: 0,
			numStrips: 0,
			numPatchesSubdivisions: 0,
			numPatches: 0,
			numControlPointsPerPatch: 0,
			
			stripLengths: null,
			primitiveType: PVRMesh.EPVRMesh.eIndexedTriangleList
		},
		
		boneBatches:{
			boneMax: 0,
			count: 0,
			batches: null,
			boneCounts: null,
			offsets: null
		},
		
		faces:{
			indexType: PVRMesh.EPVRMesh.FaceData.e16Bit,
			data: null
		}
	};

	// Private methods
	

	// Public methods
	AddData(data)
	{
		this.data.vertexElementData.push(data);
		return this.data.vertexElementData.length - 1;
	}
	
	AddFaces(data, type)
	{
		this.data.faces.indexType = type;
		this.data.faces.data      = data;
		
		if(data.length > 0)
		{
			this.data.primitiveData.numFaces = data.length / 3;
		}
		else
		{
			this.data.primitiveData.numFaces = 0;
		}
		
		return  EPODErrorCodes.eNoError;
	}
	
	AddElement(semantic, type, numComponents, stride, offset, dataIndex)
	{
		
		if(this.data.vertexElements.hasOwnProperty(semantic))
			return  EPODErrorCodes.eKeyAlreadyExists;
			
		let vertexData = new VertexData(semantic, type, numComponents, stride, offset, dataIndex);
		this.data.vertexElements[semantic] = vertexData;
		
		return  EPODErrorCodes.eNoError;
	}
	
	GetNumElementsOfSemantic(semantic)
	{
		let count = 0;

		for(let k in this.data.vertexElements)
		{
			if(semantic === k)
				++count;
		}
		return count;
	}


    GetNumberOfIndices(){
    // return this.data.primitiveData.numStrips ?  this.data.primitiveData.numFaces + (this.data.primitiveData.numStrips * 2 ) : this.data.primitiveData.numFaces * 3 ;
      return  this.data.primitiveData.numFaces * 3 ;
	}

}

export default PVRMesh