/*
	PVRFileStream
*/


import React from 'react'

//function PVRAnimation()

export const EPVRFileStreamSeekMode =
{
	eFromStart   : 1,
	eFromCurrent : 2,
}


class PVRFileStream   extends React.Component{

   render(){return null}
	
	offset       = 0;
	buffer       = null;
    raw          = null;
	littleEndian = false;
	
   

	Open(uri, littleEndian, completionFunc)
	{
		
		let args = [this];
		args     = args.concat(Array.prototype.slice.call(arguments, 3));
		let req = new XMLHttpRequest();
	
		
		req.open('GET', uri);
		req.responseType = "arraybuffer";
		req.callback     = completionFunc;
		req.arguments    = args;
		req.onload       = function(e)
		{
			if(req.readyState === 4)
			{
				
	

				let stream        = this.arguments[0];
                stream.raw        = this.response.slice(0);
				stream.buffer     = new DataView(stream.raw);
				this.callback.apply(null, args);
			}
		};
		req.onerror       = function(e)
		{
			console.log("cannot find files:",this.statusText);
		}

		this.littleEndian = littleEndian;

		req.send();
	}

	IsOpen()
	{
		return this.buffer != null;
	}

	GetPosition ()
	{
		return this.offset;
	}

	GetSize()
	{
		return this.buffer.byteLength;
	}

	Seek(offset, mode)
	{
		
		if(mode === EPVRFileStreamSeekMode.eFromStart)
		{
			if(offset > this.buffer.byteLength)
				return false;

			

			this.offset = offset;
		}
		else if(mode === EPVRFileStreamSeekMode.eFromCurrent)
		{
			
			
			if(this.offset + offset > this.buffer.byteLength)
				return false;

			this.offset += offset;
		}

		return true;
	}

	/*
		Int8
	*/
	ReadInt8(offset)
	{
		offset = (typeof offset == "undefined") ? this.offset : offset;
		let v  = this.buffer.getInt8(offset);
		this.offset = offset + 1;
		return v;
	}

	/*
		Uint8 / Bytes
	*/
	ReadUInt8  (offset)
	{
		offset = (typeof offset == "undefined") ? this.offset : offset;
		let v  = this.buffer.getUint8(offset);
		this.offset = offset + 1;
		return v;
	}

	ReadArrayBuffer  (count, offset)
	{
		offset = (typeof offset == "undefined") ? this.offset : offset;
		let buff  = this.raw.slice(offset, offset + count);
		this.offset += count;
		return buff;
	}

	ReadByteArray  (count, offset)
	{
		offset = (typeof offset == "undefined") ? this.offset : offset;
		let vA = [];
		for(let i = 0; i < count; i++)
		{
			vA[i] = this.buffer.getUint8(offset, this.littleEndian);
			offset++;
		}
		this.offset = offset;
		return new Uint8Array(vA);
	}

	/*
		Int16
	*/
	ReadInt16  (offset)
	{
		offset = (typeof offset == "undefined") ? this.offset : offset;
		let v  = this.buffer.getInt16(offset, this.littleEndian);
		this.offset = offset + 2;
		return v;
	}

	/*
		Uint16
	*/
	ReadUInt16 (offset)
	{
		offset = (typeof offset == "undefined") ? this.offset : offset;
		let v  = this.buffer.getUint16(offset, this.littleEndian);
		this.offset = offset + 2;
		return v;
	}

	ReadUInt16Array  (count, offset)
	{
		offset = (typeof offset == "undefined") ? this.offset : offset;
		let vA = [];
		for(let i = 0; i < count; i++)
		{
			vA[i] = this.buffer.getUint16(offset, this.littleEndian);
			offset += 2;
		}
		this.offset = offset;
		return new Uint16Array(vA);
	}

	/*
		Int32
	*/
	ReadInt32  (offset)
	{
	     offset = (typeof offset == "undefined") ? this.offset : offset;
		let v  = this.buffer.getInt32(offset, this.littleEndian);
		this.offset = offset + 4;
		//console.log("value",v);
	
		return v;
	}

	


	ReadInt32Array  (count, offset)
	{
		offset = (typeof offset == "undefined") ? this.offset : offset;
		let vA = [];
		for(let i = 0; i < count; i++)
		{
			vA[i] = this.buffer.getInt32(offset, this.littleEndian);
			offset += 4;
		}
		this.offset = offset;
		return new Int32Array(vA);
	}

	/*
		Uint32
	*/
	ReadUInt32  (offset)
	{
		offset = (typeof offset == "undefined") ? this.offset : offset;
		let v  = this.buffer.getUint32(offset, this.littleEndian);
		this.offset = offset + 4;
		return v;
	}

	ReadUInt32Array  (count, offset)
	{
		offset = (typeof offset == "undefined") ? this.offset : offset;
		let vA = [];
		for(let i = 0; i < count; i++)
		{
			vA[i] = this.buffer.getUint32(offset, this.littleEndian);
			offset += 4;
		}
		this.offset = offset;
		return new Uint32Array(vA);
	}

	/*
		Float32
	*/
	ReadFloat32  (offset)
	{
		offset = (typeof offset == "undefined") ? this.offset : offset;
		let v  = this.buffer.getFloat32(offset, this.littleEndian);
		this.offset = offset + 4;
		return v;
	}

	ReadFloat32Array  (count, offset)
	{
		offset = (typeof offset == "undefined") ? this.offset : offset;
		let vA = []
		for(let i = 0; i < count; i++)
		{
			vA[i] = this.buffer.getFloat32(offset, this.littleEndian);
			offset += 4;
		}
		this.offset = offset;
		return new Float32Array(vA);
	}

	/*
		Float64
	*/
	ReadFloat64  (offset)
	{
		offset = (typeof offset == "undefined") ? this.offset : offset;
		let v  = this.buffer.getFloat64(offset, this.littleEndian);
		this.offset = offset + 8;
		return v;
	}

	/*
		String
	*/
	ReadString  (length, offset)
	{
		offset = (typeof offset == "undefined") ? this.offset : offset;
		let v = "";
		for(let i = 0; i < length; i++)
		{
			let c = this.buffer.getUint8(offset+i);
			if(c !== 0)
				v += String.fromCharCode(c);
		}
		this.offset = offset + length;
		return v;
	}


	
}


export default PVRFileStream