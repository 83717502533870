// Insert our canvas element into the html code
//document.write("<canvas id=\"surface\" style=\"border: none;\" width=\"800\" height=\"600\">Your browser doesn't appear to support the HTML5 <code>&lt;canvas&gt;</code> element.</canvas>");

// PVRShell "class"

import React from "react"
import { PVRVector2 } from "./Tools/PVRMaths";
// import WebGLIntroducingPOD from "../WebGLIntroducingPOD"

class PVRShell extends React.Component {


    render(){
        return <></>
    }

//let PVRShell = {
    // Internal data

    getMouseButtonState(){

         return this.mouseButtonDown ? 1 : 0;
    }

    getPointerLocation(){
      
      if  (this.page.chosenCanvas !== this.surface) return null;
   
     return new PVRVector2(this.mouseOffsetX / this.data.width, this.mouseOffsetY / this.data.height )
    };



    isKeyPressed(key){
        let result = false;
            if (this.lastCode !== null){
          if (this.lastCode === key ){ 
            this.lastCode = null;
            result = true;
            return result;
              } 
            }
              return result
            }

    getTimeNow = function()
    {
    	if (window.performance)
    	{
			if (window.performance.now)
				return window.performance.now()
			else if (window.performance.webkitNow)
				return window.performance.webkitNow()
			else if (window.performance.mozNow)
				return window.performance.mozNow()
			else if (window.performance.oNow)
				return window.performance.oNow()
			else
				return Date.now()
		}
		else
		{
			return Date.now()
		}
    }

    // define our "main" function
  main(demo, surface, page)
    {
        this.mouseOffsetX = 0;
        this.mouseOffsetY = 0;
       this.lastCode = null;
       this.KeyNameRIGHT = "KeyD";
       this.KeyNameLEFT = "KeyA";
       this.KeyNameUP = "KeyW";
       this.KeyNameDOWN = "KeyS";
       this.KeyNameACTION1 = "KeyK";
       this.KeyNameACTION2 = "KeyL";
       this.mouseButtonDown = false;

        this.data  = { width:800, height:600, fullscreen:false }
        this.surface = surface

        // Utility function
        function parseBool(value)
        {
            if(value.toLowerCase() === 'true')
                return true;
            else if(parseInt(value, 10) !== 0)
                return true;

            return false;
        }

        // Based on http://www.paulirish.com/2011/requestanimationframe-for-smart-animating/
       const requestAnimFrame = (function() {
            return window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame ||
                function(callback){
                    window.setTimeout(callback, 1000, 60);
                };
        })();

        // Initialise the PVRShell data
        let canvas = document.getElementById(surface);
        let gl = null;
        let urlLine = [];
        let state = 0;
        let i;
        let myshell = this;

this.page = page;

      
        document.addEventListener('keydown',  function logKey(e) {
             myshell.lastCode = (page.chosenCanvas === myshell.surface) ? e.code : null;
            
           });

           var ongoingTouches = [];

           function colorForTouch(touch) {
            var r = touch.identifier % 16;
            var g = Math.floor(touch.identifier / 3) % 16;
            var b = Math.floor(touch.identifier / 7) % 16;
            r = r.toString(16); // make it a hex digit
            g = g.toString(16); // make it a hex digit
            b = b.toString(16); // make it a hex digit
            var color = "#" + r + g + b;
            console.log("color for touch with identifier " + touch.identifier + " = " + color);
            return color;
          }

          function copyTouch(touch) {
            return { identifier: touch.identifier, pageX: touch.pageX, pageY: touch.pageY };
          }

          function ongoingTouchIndexById(idToFind) {
            for (var i = 0; i < ongoingTouches.length; i++) {
              var id = ongoingTouches[i].identifier;
              
              if (id === idToFind) {
                return i;
              }
            }
            return -1;    // not found
          }

           function handleStart(evt) {
            evt.preventDefault();
            console.log("touchstart.");
            var el = document.getElementsByTagName("canvas")[0];
            var ctx = el.getContext("2d");
            var touches = evt.changedTouches;
                  
            for (var i = 0; i < touches.length; i++) {
              console.log("touchstart:" + i + "...");
              ongoingTouches.push(copyTouch(touches[i]));
              var color = colorForTouch(touches[i]);
              ctx.beginPath();
              ctx.arc(touches[i].pageX, touches[i].pageY, 4, 0, 2 * Math.PI, false);  // a circle at the start
              ctx.fillStyle = color;
              ctx.fill();
              console.log("touchstart:" + i + ".");
            }
          }


function handleMove(evt) {
  evt.preventDefault();
  var el = document.getElementsByTagName("canvas")[0];
  var ctx = el.getContext("2d");
  var touches = evt.changedTouches;

  for (var i = 0; i < touches.length; i++) {
    var color = colorForTouch(touches[i]);
    var idx = ongoingTouchIndexById(touches[i].identifier);

    if (idx >= 0) {
      console.log("continuing touch "+idx);
      ctx.beginPath();
      console.log("ctx.moveTo(" + ongoingTouches[idx].pageX + ", " + ongoingTouches[idx].pageY + ");");
      ctx.moveTo(ongoingTouches[idx].pageX, ongoingTouches[idx].pageY);
      console.log("ctx.lineTo(" + touches[i].pageX + ", " + touches[i].pageY + ");");
      ctx.lineTo(touches[i].pageX, touches[i].pageY);
      ctx.lineWidth = 4;
      ctx.strokeStyle = color;
      ctx.stroke();

      ongoingTouches.splice(idx, 1, copyTouch(touches[i]));  // swap in the new touch record
      console.log(".");
    } else {
      console.log("can't figure out which touch to continue");
    }
  }
}


function handleEnd(evt) {
    evt.preventDefault();
   // log("touchend");
    var el = document.getElementsByTagName("canvas")[0];
    var ctx = el.getContext("2d");
    var touches = evt.changedTouches;
  
    for (var i = 0; i < touches.length; i++) {
      var color = colorForTouch(touches[i]);
      var idx = ongoingTouchIndexById(touches[i].identifier);
  
      if (idx >= 0) {
        ctx.lineWidth = 4;
        ctx.fillStyle = color;
        ctx.beginPath();
        ctx.moveTo(ongoingTouches[idx].pageX, ongoingTouches[idx].pageY);
        ctx.lineTo(touches[i].pageX, touches[i].pageY);
        ctx.fillRect(touches[i].pageX - 4, touches[i].pageY - 4, 8, 8);  // and a square at the end
        ongoingTouches.splice(idx, 1);  // remove it; we're done
      } else {
        console.log("can't figure out which touch to end");
      }
    }
  }


function handleCancel(evt) {
  evt.preventDefault();
  console.log("touchcancel.");
  var touches = evt.changedTouches;
  
  for (var i = 0; i < touches.length; i++) {
    var idx = ongoingTouchIndexById(touches[i].identifier);
    ongoingTouches.splice(idx, 1);  // remove it; we're done
  }
}

function handleMouseMove(evt) {
    
 this.mouseOffsetX =  evt.offsetX;
 this.mouseOffsetY =  evt.offsetY;


}

function handleMouseDown(evt) {
    
    this.mouseButtonDown = true;
 
   
   
   }
   function handleMouseUp(evt) {
    this.mouseButtonDown = false;   
   }


           // var el = document.getElementsByTagName("canvas")[0];
           // console.log("el",el);
           canvas.addEventListener("mousemove", handleMouseMove.bind(myshell), false);
           canvas.addEventListener("mousedown", handleMouseDown.bind(myshell), false);
           canvas.addEventListener("mouseup", handleMouseUp.bind(myshell), false);

          

           // var el1 = document.getElementsByTagName("canvas")[1];
           // var el2 = document.getElementsByTagName("canvas")[2];



            // el.addEventListener("touchend", handleEnd, false);
            // el.addEventListener("touchcancel", handleCancel, false);
            // el.addEventListener("touchmove", handleMove, false);
            // console.log("initialized.");
           
          

        (function mainloop() {

          
            switch(state)
            {
                case 0: // Initialise app
                    // Parse "command-line"
                    urlLine = [];
                    let argIndex = window.location.href.indexOf('?');

                    if (argIndex > -1)
                    {
                        let arg;
                        let args = window.location.href.slice(argIndex + 1).split('&');
                        let argc = args.length;

                        for(i = 0; i < argc; i++)
                        {
                            arg = args[i].split('=');

                            // Add our arg name and value
                            urlLine[i]  = { name:arg[0], value:arg[1] };
                        }
                    }

                    // Call initApplication
                    if(demo.initApplication)
                    {
                        if(demo.initApplication(myshell) === false)
                        {
                            alert("initApplication failed");
                            return;
                        }
                    }

                    ++state;
                    requestAnimFrame(mainloop, myshell);
                break;
                case 1: // Initialise instance

                    // Apply our command-line
                   let urlLineLength = urlLine.length;

                
                    for(i = 0; i < urlLineLength; ++i)
                    {
                        switch(urlLine[i].name.toLowerCase())
                        {
                            case "width":
                                myshell.data.width = parseInt(urlLine[i].value, 10);
                                break;
                            case "height":
                                myshell.data.height = parseInt(urlLine[i].value, 10);
                                break;
                            case "fullscreen":
                                myshell.data.fullscreen = parseBool(urlLine[i].value);
                                break;
                            default: break;
                        }
                    }

                    if (myshell.data.fullscreen)
                    {
                        myshell.data.width  = window.innerWidth;
                        myshell.data.height = window.innerHeight;
                    }

                    // Setup our canvas dimensions
                    canvas.width  = myshell.data.width;
                    canvas.height = myshell.data.height;

                    // Initialise GL
                    try
                    {
                        // Update our canvas's dimensions
                        // Try to grab the standard context. If it fails, fallback to experimental
                       // gl = canvas.getContext("webgl", {alpha: false}) || canvas.getContext("experimental-webgl", {alpha: false});
                       
                       gl = canvas.getContext("webgl", {alpha: false}) || canvas.getContext("experimental-webgl", {alpha: false});
                       
                       gl.viewport(0, 0, canvas.width, canvas.height);
                    }
                    catch (e)
                    {
                    }

                    if (!gl)
                    {
                        alert("Unable to initialise WebGL. Your browser may not support it");
                        return;
                    }

                    // Call InitView
                    if(demo.initView)
                    {
                        if(demo.initView(gl,myshell) === false)
                        {
                            alert("initView failed");
                            return;
                        }
                    }

                    ++state;
                    requestAnimFrame(mainloop,myshell);
                    break;
                case 2: // Render
                
                    requestAnimFrame(mainloop,myshell);
                    if(myshell.data.fullscreen)
                    {
                        myshell.data.width  = window.innerWidth;
                        myshell.data.height = window.innerHeight;
                        if(myshell.data.width !== canvas.width || myshell.data.height !== canvas.height)
                        {
                            canvas.width  = myshell.data.width;
                            canvas.height = myshell.data.height;
                        }
                    }
                    if (demo.renderScene)
                    {
                        if(demo.renderScene(gl,myshell) === false)
                        {
                            ++state;
                            break;
                        }
                    }
                    break;
                case 3: // Release view
                    // Call ReleaseView
                    if(demo.releaseView)
                    {
                        if(demo.releaseView(gl) === false)
                        {
                            alert("releaseView failed");
                            return;
                        }
                    }
                    ++state;
                    requestAnimFrame(mainloop);
                    break;
                case 4: // Quit application
                    if(demo.quitApplication)
                    {
                        if(demo.quitApplication() === false)
                        {
                            alert("quitApplication failed");
                            return;
                        }
                    }

                    ++state;
                    requestAnimFrame(mainloop);
                    break;
                default:
                    // Do nothing
                    break;
            }
        })();
    }




}
;
export default PVRShell