/*
	PVRAnimation
*/

import React from 'react'
import { PVRMatrix4x4, PVRQuaternion, PVRVector3} from  "./PVRMaths"


const EPVRAnimation =
{
	eHasPositionAnimation: 0x01,
	eHasRotationAnimation: 0x02,
	eHasScaleAnimation:    0x04,
	eHasMatrixAnimation:   0x08,
}

//function PVRAnimation()




class PVRAnimation  extends React.Component
	{
	
	render(){return null}
	
	
	data =
	{
		flags: 0,
		numFrames: 0,

		positions: null,
		rotations: null,
		scales:    null,
		matrices:  null,
		positionIndices: null,
		rotationIndices: null,
		scaleIndices:    null,
		matrixIndices:   null,
	}

	getTranslationMatrix(frame, interp)
	{
		if(this.data.positions != null)
		{
			if(this.data.flags & EPVRAnimation.eHasPositionAnimation)
			{
/*					if(frame >= this.data.numFrames - 1)
					throw new RangeError;		*/ // TODO

				let index0, index1;

				if(this.data.positionIndices != null)
				{
					index0 = this.data.positionIndices[frame + 0];
					index1 = this.data.positionIndices[frame + 1];
				}
				else
				{
					index0 = 3 * (frame + 0);
					index1 = 3 * (frame + 1);
				}

				let p0 = new    PVRVector3(this.data.positions[index0+0],
										this.data.positions[index0+1],
										this.data.positions[index0+2]);

				let p1 = new    PVRVector3(this.data.positions[index1+0],
										this.data.positions[index1+1],
										this.data.positions[index1+2]);

				let temp =    PVRVector3.linearInterpolate(p0, p1, interp);
                return    PVRMatrix4x4.createTranslation3D(temp.data[0], temp.data[1], temp.data[2]);
			}
			else
			{
				return    PVRMatrix4x4.createTranslation3D(this.data.positions[0], this.data.positions[1], this.data.positions[2]);
			}
		}
		return    PVRMatrix4x4.identity();
	}

	getTranslationVector(frameAnimation, vectorOffset)
	{

     let frame = Math.floor(frameAnimation);
     let interp = frameAnimation - frame;
//let baseFrame =  this.m_fCameraAnimation;
	
//	let pos0= new PVRVector3(this.m_CameraPod.data.nodes[0].pfAnimPosition[baseFrame * 3]);
//	let pos1= new PVRVector3(this.m_CameraPod.data.nodes[0].pfAnimPosition[(baseFrame + 1) * 3]);

	// Offset the camera by a reference coordinate to center it around the origin
	// This should help circumvent floating point precision problems
//	pos0 -=  this.m_vCameraOffset;
	//pos1 -=  this.m_vCameraOffset;
	//let lerp = ( this.m_fCameraAnimation - this.baseFrame);
	// this.m_vCameraFrom = pos0 + (pos1 - pos0) * lerp;
	// this.m_vCameraFrom +=  this.m_vCameraOffset;

	

		if(this.data.positions != null)
		{
			if(this.data.flags & EPVRAnimation.eHasPositionAnimation)
			{
/*					if(frame >= this.data.numFrames - 1)
					throw new RangeError;		*/ // TODO

				let index0, index1;

				if(this.data.positionIndices != null)
				{
					index0 = this.data.positionIndices[frame + 0];
					index1 = this.data.positionIndices[frame + 1];
				}
				else
				{
					index0 = 3 * (frame + 0);
					index1 = 3 * (frame + 1);
				}

				let p0 = new    PVRVector3(this.data.positions[index0+0],
										this.data.positions[index0+1],
										this.data.positions[index0+2]);

				let p1 = new    PVRVector3(this.data.positions[index1+0],
										this.data.positions[index1+1],
										this.data.positions[index1+2]);

										
										p0 = PVRVector3.subtract(p0, vectorOffset);
  
                                        p1 = PVRVector3.subtract(p1, vectorOffset);

				let temp =    PVRVector3.linearInterpolate(p0, p1, interp);
			
                return    PVRVector3.add(temp, vectorOffset);
			}
			else
			{
				return   this.data.positions;
			}
		}
		return    PVRVector3();
	}


	getRotationMatrix(frame, interp)
	{
		if(this.data.rotations)
		{
			if(this.data.flags & EPVRAnimation.eHasRotationAnimation)
			{
/*					if(frame >= this.data.numFrames - 1)
					throw new RangeError;		*/ // TODO

				let index0, index1;

				if(this.data.rotationIndices != null)
				{
					index0 = this.data.rotationIndices[frame + 0];
					index1 = this.data.rotationIndices[frame + 1];
				}
				else
				{
					index0 = 4 * (frame + 0);
					index1 = 4 * (frame + 1);
				}

				let q0 = new    PVRQuaternion(this.data.rotations[index0+0],
										   this.data.rotations[index0+1],
										   this.data.rotations[index0+2],
										   this.data.rotations[index0+3]);

				let q1 = new    PVRQuaternion(this.data.rotations[index1+0],
										   this.data.rotations[index1+1],
										   this.data.rotations[index1+2],
										   this.data.rotations[index1+3]);

				let q  =    PVRQuaternion.sphericalLinearInterpolation(q0, q1, interp);
				return q.toRotationMatrix();
			}
			else
			{
				let q = new    PVRQuaternion(this.data.rotations[0],
										  this.data.rotations[1],
										  this.data.rotations[2],
										  this.data.rotations[3]);
				return q.toRotationMatrix();
			}
		}

		return    PVRMatrix4x4.identity();
	}


	getFrameRotationMatrix(frameAnimation){
		let frame = Math.floor(frameAnimation);
		let interp = frameAnimation - frame;
		return this.getRotationMatrix(frame, interp)
	}

	getScalingMatrix(frame, interp)
	{
        if(this.data.scales)
        {
            if(this.data.flags & EPVRAnimation.eHasScaleAnimation)
            {
                let index0, index1;
                if(this.data.scaleIndices)
                {
                    index0 = this.data.scaleIndices[frame + 0];
                    index1 = this.data.scaleIndices[frame + 1];
                }
                else
                {
                    index0 = 7 * (frame + 0);
                    index1 = 7 * (frame + 1);
                }

                let s0 = new     PVRVector3(this.data.scales[index0+0],
                                        this.data.scales[index0+1],
                                        this.data.scales[index0+2]);

                let s1 = new     PVRVector3(this.data.scales[index1+0],
                                        this.data.scales[index1+1],
                                        this.data.scales[index1+2]);

				let v =     PVRVector3.linearInterpolate(s0, s1, interp);
			    return    PVRMatrix4x4.scale(v.data[0], v.data[1], v.data[2]);
            }
            else
            {
                return    PVRMatrix4x4.scale(this.data.scales[0], this.data.scales[1], this.data.scales[2]);
            }
        }

		return    PVRMatrix4x4.identity();
	}

	getTransformationMatrix(frame, interp)
	{
		if(this.data.matrices)
		{
			// TODO
			throw new "TODO"();
		}
		else
		{
            let m =    PVRMatrix4x4.identity();
            let tmp;

            // Translation
            tmp = this.getTranslationMatrix(frame, interp);
            m   =    PVRMatrix4x4.matrixMultiply(m, tmp);

            // Rotation
            tmp = this.getRotationMatrix(frame, interp);
            m   =    PVRMatrix4x4.matrixMultiply(m, tmp);

            // Scale
            tmp = this.getScalingMatrix(frame, interp);
            m   =    PVRMatrix4x4.matrixMultiply(m, tmp);

            return m;
		}
	}
}





export default PVRAnimation