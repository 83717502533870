import React from 'react'
import PVRFileStream from "./PVRFileStream"
import {PVRVector2} from './PVRMaths'
const MAPFILEIO_SECURITYCHECKPOINT = 0xFACEBEED;
const MAPFILEIO_VERSION     =        1;


// struct PVRTBoundingBox2D
// {
// 	// Min and max coordinates
// 	PVRTVec2 minCoords;
// 	PVRTVec2 maxCoords;
// };
let PVRTBoundingBox2D = function(a,b,c,d)
{
	// Min and max coordinates
	this.minCoords = new PVRVector2();
    this.maxCoords = new PVRVector2();
    if (a) this.minCoords.data[0]  = a;
    if (b) this.minCoords.data[1] = b;
    if (c) this.maxCoords.data[0] = c;
    if (d) this.maxCoords.data[1] = d;
};


let PVRTMapBucket = function(ws)
{
	      this.boundingbox = new PVRTBoundingBox2D();
	                 this.numVertexDataBuckets = 0;
	      this.pVertexDataBuckets = new Array(ws);//PVRTVertexDataBucket();
	                 this.numIndexDataBuckets = 0;
	       this.pIndexDataBuckets = new Array(ws);//PVRTVertexDataBucket();
};
// struct PVRTMapBucket
// {
// 	PVRTBoundingBox2D          boundingbox;
// 	PVRTuint32                 numVertexDataBuckets;
// 	PVRTVertexDataBucket      *pVertexDataBuckets;
// 	PVRTuint32                 numIndexDataBuckets;
// 	PVRTIndexDataBucket       *pIndexDataBuckets;
// }; layer



// struct PVRTVertexDataBucket
// {
// 	PVRTBoundingBox2D     boundingbox;
// 	PVRTuint32            size;
// 	char                 *pData;
// };


let PVRTVertexDataBucket = function()
{
	          this.boundingbox = new PVRTBoundingBox2D()
	          this.size = 0;
              this.pData = {};
};


// struct PVRTIndexDataBucket
// {
// 	PVRTuint32           bucketindex;
// 	PVRTBoundingBox2D    boundingbox;
// 	PVRTuint32           numIndices;
// 	index_t             *pIndices;
// };


let PVRTIndexDataBucket = function()
{
	           this.bucketindex = 0
	           this.boundingbox = new PVRTBoundingBox2D()
	           this.numIndices = 0
	           this.pIndices = {}
};



export  class PVRMapBucket  extends React.Component
{



	render(){
        return null
    }

 static genPixelTypeH(c1Name, c2Name, c3Name, c4Name)
{
    let val = 0;
    val |= c1Name.charCodeAt();

    if(c2Name !== undefined)
        val |= c2Name.charCodeAt() << 8;

    if(c3Name !== undefined)
        val |= c3Name.charCodeAt() << 16;

    if(c4Name !== undefined)
        val |= c4Name.charCodeAt() << 24;

    return val;
}

static genPixelTypeL(c1Bits, c2Bits, c3Bits, c4Bits)
{
    let val = 0;
    val |= c1Bits;

    if(c2Bits !== undefined)
        val |= c2Bits << 8;

    if(c3Bits !== undefined)
        val |= c3Bits << 16;

    if(c4Bits !== undefined)
        val |= c4Bits << 24;

    return val;
}


static getBitsPerPixel(header)
{
    if(header.pixelFormatH !== 0)
    {
        let lowPart = header.pixelFormatL;
        let c1Bits  = (lowPart >> 24) & 0xFF;
        let c2Bits  = (lowPart >> 16) & 0xFF;
        let c3Bits  = (lowPart >> 8)  & 0xFF;
        let c4Bits  = lowPart & 0xFF;
        return c1Bits + c2Bits + c3Bits + c4Bits;
    }

    // TODO: Compressed texture support.

    return 0;
}

static loadFromURI (layer, uri, i, loadFromLevel, demo, layer1, callback)
{
    let fs = new PVRFileStream();
    let args = [uri, true, PVRMapBucket.loadFromMemory, layer, i, loadFromLevel, demo, layer1, callback];
    args = args.concat(Array.prototype.slice.call(arguments, 4));
  //  console.log("rui-args:", args);
    fs.Open.apply(fs, args);
}
   
static loadFromMemory (stream, layer, header, loadFromLevel, demo, layer1, callback)
{
   let mapfileversion =  stream.ReadUInt32();
   if (mapfileversion !== MAPFILEIO_VERSION) {return false;}
   let mina =  stream.ReadFloat32(); //  ReadArrayBuffer( sizeof(layer.boundingbox));
   let minb =  stream.ReadFloat32(); 
   let maxa =  stream.ReadFloat32(); 
   let maxb =  stream.ReadFloat32(); 

  

   layer.boundingbox = new PVRTBoundingBox2D(mina,minb, maxa,maxb);

   let mapfilescp =  stream.ReadUInt32();
  
   if (mapfilescp !== MAPFILEIO_SECURITYCHECKPOINT) {return false;}


   layer.numVertexDataBuckets = stream.ReadUInt32();    //ReadArrayBuffer( sizeof(layer.numVertexDataBuckets));
   
   if (layer.numVertexDataBuckets > 0){


    layer.pVertexDataBuckets = new Array(layer.numVertexDataBuckets);
    for (let i=0; i < layer.numVertexDataBuckets; i++){
    
        layer.pVertexDataBuckets[i] = new PVRTVertexDataBucket();

        let mina =  stream.ReadFloat32(); //  ReadArrayBuffer( sizeof(layer.boundingbox));
        let minb =  stream.ReadFloat32(); 
        let maxa =  stream.ReadFloat32(); 
        let maxb =  stream.ReadFloat32(); 
       
   

        layer.pVertexDataBuckets[i].boundingbox =  new PVRTBoundingBox2D(mina,minb, maxa,maxb);
        //stream.ReadArrayBuffer( sizeof(layer.pVertexDataBuckets[i].boundingbox));
       
         mapfilescp =  stream.ReadUInt32();
    
        if (mapfilescp !== MAPFILEIO_SECURITYCHECKPOINT) {return false;}
        layer.pVertexDataBuckets[i].size  = stream.ReadUInt32();   //ReadArrayBuffer( sizeof(layer.pVertexDataBuckets[i].size));

        if (layer.pVertexDataBuckets[i].size === 0) return false;
        layer.pVertexDataBuckets[i].pData = stream.ReadArrayBuffer(layer.pVertexDataBuckets[i].size);
        mapfilescp =  stream.ReadUInt32();
        if (mapfilescp !== MAPFILEIO_SECURITYCHECKPOINT) {return false;}
	 	}
    }
    else{
        layer.pVertexDataBuckets = null;
    }

    mapfilescp =  stream.ReadUInt32();
  
    if (mapfilescp !== MAPFILEIO_SECURITYCHECKPOINT) {return false;}

    layer.numIndexDataBuckets = stream.ReadUInt32();  //ReadArrayBuffer(sizeof(layer.numIndexDataBuckets));

    if (layer.numIndexDataBuckets > 0)
	{
	 	layer.pIndexDataBuckets = new Array(layer.numIndexDataBuckets);
	 	for (let i=0; i < layer.numIndexDataBuckets; i++)
		{
            layer.pIndexDataBuckets[i] = new PVRTIndexDataBucket();

            layer.pIndexDataBuckets[i].bucketindex = stream.ReadUInt32();//  ReadArrayBuffer(sizeof(layer.pIndexDataBuckets[i].bucketindex));
           
             mapfilescp =  stream.ReadUInt32();
          
            if (mapfilescp !== MAPFILEIO_SECURITYCHECKPOINT) {return false;}
        
            let mina =  stream.ReadFloat32(); //  ReadArrayBuffer( sizeof(layer.boundingbox));
            let minb =  stream.ReadFloat32(); 
            let maxa =  stream.ReadFloat32(); 
            let maxb =  stream.ReadFloat32(); 
            layer.pIndexDataBuckets[i].boundingbox =  new PVRTBoundingBox2D(mina,minb, maxa,maxb);
          
             mapfilescp =  stream.ReadUInt32();
        
             if (mapfilescp !== MAPFILEIO_SECURITYCHECKPOINT) {return false;}
         
          //  PVRTuint32 size;
            let size = stream.ReadUInt32();
     
	     	layer.pIndexDataBuckets[i].numIndices = size / 2 ;   //sizeof(index_t);
            if (layer.pIndexDataBuckets[i].numIndices === 0) return false;
            
        layer.pIndexDataBuckets[i].pIndices = stream.ReadUInt16Array(size / 2); //ReadArrayBuffer(size); //layer.pIndexDataBuckets[i].numIndices); 

//         if(layer.pIndexDataBuckets[i].pIndices !== {}){
//             let m = new Uint8Array(layer.pIndexDataBuckets[i].pIndices)
//   console.log("ddd",m);

//         }

        mapfilescp =  stream.ReadUInt32();
       
        if (mapfilescp !== MAPFILEIO_SECURITYCHECKPOINT) {return false;}
	 	}
	 }
 else  layer.pIndexDataBuckets = null;

          mapfilescp =  stream.ReadUInt32();
    
        if (mapfilescp !== MAPFILEIO_SECURITYCHECKPOINT) {return false;}

        if(typeof callback === "function")
        {
            let args = [layer, layer1, header, loadFromLevel, demo];
            args = args.concat(Array.prototype.slice.call(arguments, 7));
           
            callback.apply(null, args);
        }
    return true;
    
}


	// CPVRTResourceFile file(pszFilename);
	// if (!file.IsOpen())
	// 	return false;

	// const char *pData = (const char *)file.DataPtr();

	// if (!checkMarker(&pData, MAPFILEIO_VERSION))	return false;
	// readData(&pData, &layer.boundingbox, sizeof(layer.boundingbox));

	// if (!checkMarker(&pData, MAPFILEIO_SECURITYCHECKPOINT))	return false;

	// // Read vertex data
	// readData(&pData, &layer.numVertexDataBuckets, sizeof(layer.numVertexDataBuckets));
	// if (layer.numVertexDataBuckets > 0)
	// {
	// 	layer.pVertexDataBuckets = new PVRTVertexDataBucket[layer.numVertexDataBuckets];
	// 	for (let i=0; i < layer.numVertexDataBuckets; i++)
	// 	{
	// 		readData(&pData, &layer.pVertexDataBuckets[i].boundingbox, sizeof(layer.pVertexDataBuckets[i].boundingbox));
	// 		if (!checkMarker(&pData, MAPFILEIO_SECURITYCHECKPOINT))	return false;

	// 		readData(&pData, &layer.pVertexDataBuckets[i].size, sizeof(layer.pVertexDataBuckets[i].size));
	// 		if (layer.pVertexDataBuckets[i].size == 0) return false;
	// 		layer.pVertexDataBuckets[i].pData = new char[layer.pVertexDataBuckets[i].size];
	// 		readData(&pData, layer.pVertexDataBuckets[i].pData, layer.pVertexDataBuckets[i].size);
	// 		if (!checkMarker(&pData, MAPFILEIO_SECURITYCHECKPOINT))	return false;
	// 	}
	// }
	// else
	// 	layer.pVertexDataBuckets = NULL;

	// if (!checkMarker(&pData, MAPFILEIO_SECURITYCHECKPOINT))	return false;

	// readData(&pData, &layer.numIndexDataBuckets, sizeof(layer.numIndexDataBuckets));
	// if (layer.numIndexDataBuckets > 0)
	// {
	// 	layer.pIndexDataBuckets = new PVRTIndexDataBucket[layer.numIndexDataBuckets];
	// 	for (let i=0; i < layer.numIndexDataBuckets; i++)
	// 	{
	// 		readData(&pData, &layer.pIndexDataBuckets[i].bucketindex, sizeof(layer.pIndexDataBuckets[i].bucketindex));
	// 		if (!checkMarker(&pData, MAPFILEIO_SECURITYCHECKPOINT))	return false;

	// 		readData(&pData, &layer.pIndexDataBuckets[i].boundingbox, sizeof(layer.pIndexDataBuckets[i].boundingbox));
	// 		if (!checkMarker(&pData, MAPFILEIO_SECURITYCHECKPOINT))	return false;

	// 		PVRTuint32 size;
	// 		readData(&pData, &size, sizeof(size));
	// 		layer.pIndexDataBuckets[i].numIndices = size / sizeof(index_t);
	// 		if (layer.pIndexDataBuckets[i].numIndices == 0) return false;
	// 		layer.pIndexDataBuckets[i].pIndices = new index_t[layer.pIndexDataBuckets[i].numIndices];
	// 		readData(&pData, layer.pIndexDataBuckets[i].pIndices, size);
	// 		if (!checkMarker(&pData, MAPFILEIO_SECURITYCHECKPOINT))	return false;
	// 	}
	// }
	// else
	// 	layer.pIndexDataBuckets = NULL;

	// if (!checkMarker(&pData, MAPFILEIO_SECURITYCHECKPOINT))	return false;

	// return true;




}

export default PVRMapBucket