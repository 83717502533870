function Pixel32()
{
	this.red = 0;
	this.green = 0;
	this.blue = 0;
	this.alpha = 0;
};

function Pixel128S(a,b,c,d)
{
	this.red = 0; 
	if (a){this.red = a}
	this.green = 0;
	if (b){this.green = b}
	this.blue = 0;
	if (c){this.blue = c}
	this.alpha = 0;
	if (d){this.alpha = d}

};

function PVRTCWord()
{
	this.u32ModulationData = 0;
	this.u32ColorData = 0;
};

function PVRTCWordIndices()
{   this.P = new Int32Array(2);
    this.Q = new Int32Array(2);
this.R = new Int32Array(2);
this.S = new  Int32Array(2); 
};



function getColorA( u32ColorData) //uint32_t -> Pixel32
{
	let color = new Pixel32();
	
	// Opaque Color Mode - RGB 554
	if ((u32ColorData & 0x8000) !== 0)
	{
		
		color.red = (u32ColorData & 0x7c00) >> 10; // 5->5 bits
		color.green = (u32ColorData & 0x3e0) >> 5; // 5->5 bits
		color.blue = ((u32ColorData & 0x1e)) | ((u32ColorData & 0x1e) >> 4); // 4->5 bits
		color.alpha = 0xf; // 0->4 bits
	}
	// Transparent Color Mode - ARGB 3443
	else
	{
		color.red = ((u32ColorData & 0xf00) >> 7) | ((u32ColorData & 0xf00) >> 11); // 4->5 bits
		color.green = ((u32ColorData & 0xf0) >> 3) | ((u32ColorData & 0xf0) >> 7); // 4->5 bits
		color.blue = ((u32ColorData & 0xe) << 1) | ((u32ColorData & 0xe) >> 2); // 3->5 bits
		color.alpha = ((u32ColorData & 0x7000)) >> 11; // 3->4 bits - note 0 at right
	}


	return color;
}


function getColorB( u32ColorData)
{
	 let color = new Pixel32();

	// Opaque Color Mode - RGB 555
	if (u32ColorData & 0x80000000)
	{
		color.red = (u32ColorData & 0x7c000000) >> 26; // 5->5 bits
		color.green = (u32ColorData & 0x3e00000) >> 21; // 5->5 bits
		color.blue = (u32ColorData & 0x1f0000) >> 16; // 5->5 bits
		color.alpha = 0xf; // 0 bits
	}
	// Transparent Color Mode - ARGB 3444
	else
	{
		color.red = ((u32ColorData & 0xf000000) >> 23) | ((u32ColorData & 0xf000000) >> 27); // 4->5 bits
		color.green = ((u32ColorData & 0xf00000) >> 19) | ((u32ColorData & 0xf00000) >> 23); // 4->5 bits
		color.blue = ((u32ColorData & 0xf0000) >> 15) | ((u32ColorData & 0xf0000) >> 19); // 4->5 bits
		color.alpha = (u32ColorData & 0x70000000) >> 27; // 3->4 bits - note 0 at right
	}
	return color;
}





//function interpolateColors(Pixel32 P, Pixel32 Q, Pixel32 R, Pixel32 S, Pixel128S* pPixel, uint8_t ui8Bpp)
function interpolateColors( P,  Q,  R,  S,  pPixel,  ui8Bpp)
{
	let ui32WordWidth = 4;
	let ui32WordHeight = 4;
	if (ui8Bpp === 2)
	{
		ui32WordWidth = 8;
	}

	// Convert to int 32.
	let hP = new Pixel128S( P.red, P.green, P.blue, P.alpha );
	let hQ = new Pixel128S( Q.red, Q.green, Q.blue, Q.alpha );
	let hR = new Pixel128S( R.red, R.green, R.blue, R.alpha );
	let hS = new Pixel128S( S.red, S.green, S.blue, S.alpha );

	// Get vectors.
	let QminusP = new Pixel128S( hQ.red - hP.red, hQ.green - hP.green, hQ.blue - hP.blue, hQ.alpha - hP.alpha );
	let SminusR = new Pixel128S( hS.red - hR.red, hS.green - hR.green, hS.blue - hR.blue, hS.alpha - hR.alpha );

	// Multiply colors.
	hP.red *= ui32WordWidth;
	hP.green *= ui32WordWidth;
	hP.blue *= ui32WordWidth;
	hP.alpha *= ui32WordWidth;
	hR.red *= ui32WordWidth;
	hR.green *= ui32WordWidth;
	hR.blue *= ui32WordWidth;
	hR.alpha *= ui32WordWidth;

	if (ui8Bpp === 2)
	{
		// Loop through pixels to achieve results.
		for (let x = 0; x < ui32WordWidth; x++)
		{
			let result = new Pixel128S( 4 * hP.red, 4 * hP.green, 4 * hP.blue, 4 * hP.alpha );
			let dY = new Pixel128S( hR.red - hP.red, hR.green - hP.green, hR.blue - hP.blue, hR.alpha - hP.alpha );

			for (let y = 0; y < ui32WordHeight; y++)
			{

				pPixel[y * ui32WordWidth + x].red = (result.red >> 7) + (result.red >> 2);
				pPixel[y * ui32WordWidth + x].green = (result.green >> 7) + (result.green >> 2);
				pPixel[y * ui32WordWidth + x].blue = (result.blue >> 7) + (result.blue >> 2);
				pPixel[y * ui32WordWidth + x].alpha = (result.alpha >> 5) + (result.alpha >> 1);

				result.red += dY.red;
				result.green += dY.green;
				result.blue += dY.blue;
				result.alpha += dY.alpha;
			}

			hP.red += QminusP.red;
			hP.green += QminusP.green;
			hP.blue += QminusP.blue;
			hP.alpha += QminusP.alpha;

			hR.red += SminusR.red;
			hR.green += SminusR.green;
			hR.blue += SminusR.blue;
			hR.alpha += SminusR.alpha;
		}
	}
	else
	{
		// Loop through pixels to achieve results.
		for (let y = 0; y < ui32WordHeight; y++)
		{
			let result = new Pixel128S ( 4 * hP.red, 4 * hP.green, 4 * hP.blue, 4 * hP.alpha );
			let dY = new Pixel128S ( hR.red - hP.red, hR.green - hP.green, hR.blue - hP.blue, hR.alpha - hP.alpha );

			for (let x = 0; x < ui32WordWidth; x++)
			{
				
				pPixel[y * ui32WordWidth + x].red = (result.red >> 6) + (result.red >> 1);
				pPixel[y * ui32WordWidth + x].green = (result.green >> 6) + (result.green >> 1);
				pPixel[y * ui32WordWidth + x].blue = (result.blue >> 6) + (result.blue >> 1);
				pPixel[y * ui32WordWidth + x].alpha = (result.alpha >> 4) + (result.alpha);

				result.red += dY.red;
				result.green += dY.green;
				result.blue += dY.blue;
				result.alpha += dY.alpha;
			}

			hP.red += QminusP.red;
			hP.green += QminusP.green;
			hP.blue += QminusP.blue;
			hP.alpha += QminusP.alpha;

			hR.red += SminusR.red;
			hR.green += SminusR.green;
			hR.blue += SminusR.blue;
			hR.alpha += SminusR.alpha;
		}
	}


}


//static void unpackModulations(const PVRTCWord& word, int offsetX, int offsetY, int32_t i32ModulationValues[16][8], int32_t i32ModulationModes[16][8], uint8_t ui8Bpp)
function unpackModulations( word, offsetX, offsetY, i32ModulationValues, i32ModulationModes, ui8Bpp)
{
	let WordModMode = word.u32ColorData & 0x1;

	let ModulationBits = word.u32ModulationData;

	// Unpack differently depending on 2bpp or 4bpp modes.
	if (ui8Bpp === 2)
	{
	
		if (WordModMode)
		{
			// determine which of the three modes are in use:

			// If this is the either the H-only or V-only interpolation mode...
			if (ModulationBits & 0x1)
			{
				// look at the "LSB" for the "centre" (V=2,H=4) texel. Its LSB is now
				// actually used to indicate whether it's the H-only mode or the V-only...

				// The centre texel data is the at (y==2, x==4) and so its LSB is at bit 20.
				if (ModulationBits & (0x1 << 20))
				{
					// This is the V-only mode
					WordModMode = 3;
				}
				else
				{
					// This is the H-only mode
					WordModMode = 2;
				}

				// Create an extra bit for the centre pixel so that it looks like
				// we have 2 actual bits for this texel. It makes later coding much easier.
				if (ModulationBits & (0x1 << 21))
				{
					// set it to produce code for 1.0
					ModulationBits |= (0x1 << 20);
				}
				else
				{
					// clear it to produce 0.0 code
					ModulationBits &= ~(0x1 << 20);
				}
			} // end if H-Only or V-Only interpolation mode was chosen

			if (ModulationBits & 0x2)
			{
				ModulationBits |= 0x1; /*set it*/
			}
			else
			{
				ModulationBits &= ~0x1; /*clear it*/
			}

			// run through all the pixels in the block. Note we can now treat all the
			// "stored" values as if they have 2bits (even when they didn't!)
			for (let y = 0; y < 4; y++)
			{
				for (let x = 0; x < 8; x++)
				{
					i32ModulationModes[x + offsetX][y + offsetY] = WordModMode;

					// if this is a stored value...
					if (((x ^ y) & 1) === 0)
					{
						i32ModulationValues[x + offsetX][y + offsetY] = ModulationBits & 3;
						ModulationBits >>= 2;
					}
				}
			} // end for y
		}
		// else if direct encoded 2bit mode - i.e. 1 mode bit per pixel
		else
		{
			
			for (let y = 0; y < 4; y++)
			{
				for (let x = 0; x < 8; x++)
				{
					i32ModulationModes[x + offsetX][y + offsetY] = WordModMode;

					/*
					// double the bits so 0=> 00, and 1=>11
					*/
					if (ModulationBits & 1)
					{
						i32ModulationValues[x + offsetX][y + offsetY] = 0x3;
					}
					else
					{
						i32ModulationValues[x + offsetX][y + offsetY] = 0x0;
					}
					ModulationBits >>= 1;
				}
			} // end for y
		}
	}
	else
	{
		// Much simpler than the 2bpp decompression, only two modes, so the n/8 values are set directly.
		// run through all the pixels in the word.
		if (WordModMode)
		{
			for (let y = 0; y < 4; y++)
			{
				for (let x = 0; x < 4; x++)
				{
					i32ModulationValues[y + offsetY][x + offsetX] = ModulationBits & 3;
					// if (i32ModulationValues==0) {}. We don't need to check 0, 0 = 0/8.
					if (i32ModulationValues[y + offsetY][x + offsetX] === 1)
					{
						i32ModulationValues[y + offsetY][x + offsetX] = 4;
					}
					else if (i32ModulationValues[y + offsetY][x + offsetX] === 2)
					{
						i32ModulationValues[y + offsetY][x + offsetX] = 14; //+10 tells the decompressor to punch through alpha.
					}
					else if (i32ModulationValues[y + offsetY][x + offsetX] === 3)
					{
						i32ModulationValues[y + offsetY][x + offsetX] = 8;
					}
					ModulationBits >>= 2;
				} // end for x
			} // end for y
		}
		else
		{
			for (let y = 0; y < 4; y++)
			{
				for (let x = 0; x < 4; x++)
				{
					i32ModulationValues[y + offsetY][x + offsetX] = ModulationBits & 3;
					i32ModulationValues[y + offsetY][x + offsetX] *= 3;
					if (i32ModulationValues[y + offsetY][x + offsetX] > 3)
					{
						i32ModulationValues[y + offsetY][x + offsetX] -= 1;
					}
					ModulationBits >>= 2;
				} // end for x
			} // end for y
		}
	}
}


//static int32_t getModulationValues(int32_t i32ModulationValues[16][8], int32_t i32ModulationModes[16][8], uint32_t xPos, uint32_t yPos, uint8_t ui8Bpp)
function  getModulationValues( i32ModulationValues,  i32ModulationModes,  xPos,  yPos,  ui8Bpp)
{
	if (ui8Bpp === 2)
	{
		let RepVals0 = [ 0, 3, 5, 8 ];

		// extract the modulation value. If a simple encoding
		if (i32ModulationModes[xPos][yPos] === 0)
		{
			return RepVals0[i32ModulationValues[xPos][yPos]];
		}
		else
		{
			// if this is a stored value
			if (((xPos ^ yPos) & 1) === 0)
			{
				return RepVals0[i32ModulationValues[xPos][yPos]];
			}

			// else average from the neighbours
			// if H&V interpolation...
			else if (i32ModulationModes[xPos][yPos] === 1)
			{
				return Math.floor((RepVals0[i32ModulationValues[xPos][yPos - 1]] + RepVals0[i32ModulationValues[xPos][yPos + 1]] + RepVals0[i32ModulationValues[xPos - 1][yPos]] +
						   RepVals0[i32ModulationValues[xPos + 1][yPos]] + 2) /
					4);
			}
			// else if H-Only
			else if (i32ModulationModes[xPos][yPos] === 2)
			{
				return (RepVals0[i32ModulationValues[xPos - 1][yPos]] +Math.floor( RepVals0[i32ModulationValues[xPos + 1][yPos]] + 1) / 2);
			}
			// else it's V-Only
			else
			{
				return (RepVals0[i32ModulationValues[xPos][yPos - 1]] + Math.floor(RepVals0[i32ModulationValues[xPos][yPos + 1]] + 1) / 2);
			}
		}
	}
	else if (ui8Bpp === 4)
	{
		return i32ModulationValues[xPos][yPos];
	}

	return 0;
}



//static void pvrtcGetDecompressedPixels(const PVRTCWord& P, const PVRTCWord& Q, const PVRTCWord& R, const PVRTCWord& S, Pixel32* pColorData, uint8_t ui8Bpp)
function pvrtcGetDecompressedPixels(P,  Q,  R,  S, pColorData,  ui8Bpp)
{
	// if (P.u32ModulationData && ui8Bpp === 4 ){
	// 	console.log("P", P, Q, R,S, ui8Bpp);}
	//console.log("P", P, Q, R,S, ui8Bpp);

	// 4bpp only needs 8*8 values, but 2bpp needs 16*8, so rather than wasting processor time we just statically allocate 16*8.
	let i32ModulationValues = new Array(16);
	for (let i =0 ; i<16; i++) i32ModulationValues[i] = new Int32Array(8);
	// Only 2bpp needs this.
	let i32ModulationModes = new Array(16);
	for (let i =0 ; i<16; i++) i32ModulationModes[i] = new Int32Array(8);
 //int32_t
	// 4bpp only needs 16 values, but 2bpp needs 32, so rather than wasting processor time we just statically allocate 32.
	let upscaledColorA = new Array(32);
		for (let i =0 ; i<32; i++) upscaledColorA[i] = new Pixel128S();
	let upscaledColorB = new Array(32);
		for (let i =0 ; i<32; i++) upscaledColorB[i] = new Pixel128S();

	let ui32WordWidth = 4;
	let ui32WordHeight = 4;
	if (ui8Bpp === 2)
	{
		ui32WordWidth = 8;
	}

	// Get the modulations from each word.
	unpackModulations(P, 0, 0, i32ModulationValues, i32ModulationModes, ui8Bpp);
	

	unpackModulations(Q, ui32WordWidth, 0, i32ModulationValues, i32ModulationModes, ui8Bpp);
	unpackModulations(R, 0, ui32WordHeight, i32ModulationValues, i32ModulationModes, ui8Bpp);
	unpackModulations(S, ui32WordWidth, ui32WordHeight, i32ModulationValues, i32ModulationModes, ui8Bpp);

	// Bilinear upscale image data from 2x2 -> 4x4
	interpolateColors(getColorA(P.u32ColorData), getColorA(Q.u32ColorData), getColorA(R.u32ColorData), getColorA(S.u32ColorData), upscaledColorA, ui8Bpp);
	interpolateColors(getColorB(P.u32ColorData), getColorB(Q.u32ColorData), getColorB(R.u32ColorData), getColorB(S.u32ColorData), upscaledColorB, ui8Bpp);

	for (let y = 0; y < ui32WordHeight; y++)
	{
		for (let x = 0; x < ui32WordWidth; x++)
		{
			let mod = getModulationValues(i32ModulationValues, i32ModulationModes, x + Math.floor(ui32WordWidth / 2),  y + Math.floor(ui32WordHeight / 2), ui8Bpp);
			//console.log("mod", mod);
			let punchthroughAlpha = false;
			if (mod > 10)
			{
				punchthroughAlpha = true;
				mod -= 10;
			}

			let result = new Pixel128S();
			result.red = Math.floor((upscaledColorA[y * ui32WordWidth + x].red * (8 - mod) + upscaledColorB[y * ui32WordWidth + x].red * mod) / 8);
			result.green =Math.floor( (upscaledColorA[y * ui32WordWidth + x].green * (8 - mod) + upscaledColorB[y * ui32WordWidth + x].green * mod) / 8);
			result.blue = Math.floor((upscaledColorA[y * ui32WordWidth + x].blue * (8 - mod) + upscaledColorB[y * ui32WordWidth + x].blue * mod) / 8);
		
			if (punchthroughAlpha)
			{
				result.alpha = 0;
			}
			else
			{
				result.alpha = Math.floor((upscaledColorA[y * ui32WordWidth + x].alpha * (8 - mod) + upscaledColorB[y * ui32WordWidth + x].alpha * mod) / 8);
			
			}

			// Convert the 32bit precision Result to 8 bit per channel color.
			if (ui8Bpp === 2)
			{
				
				pColorData[y * ui32WordWidth + x].red = result.red;
				pColorData[y * ui32WordWidth + x].green = result.green;
				pColorData[y * ui32WordWidth + x].blue = result.blue;
				pColorData[y * ui32WordWidth + x].alpha = result.alpha;
//if (!result.alpha) {console.log("punchthroughAlpha",upscaledColorA[y * ui32WordWidth + x].alpha, upscaledColorB[y * ui32WordWidth + x].alpha, mod)}
			// if (result.red === 0)	{console.log("result.red",result);
		
			// console.log("pColorData.red", pColorData[y * ui32WordWidth + x]);
		//}
	// if (P.u32ModulationData){
		
	// 	console.log("222",pColorData[y * ui32WordWidth + x].red, pColorData[y * ui32WordWidth + x].green,pColorData[y * ui32WordWidth + x].blue,pColorData[y * ui32WordWidth + x].alpha );
	
	// }
			}
			else if (ui8Bpp === 4)
			{
				
				pColorData[y + x * ui32WordHeight].red = result.red;
				pColorData[y + x * ui32WordHeight].green = result.green;
				pColorData[y + x * ui32WordHeight].blue = result.blue;
				pColorData[y + x * ui32WordHeight].alpha = result.alpha;
		//if (!result.alpha) {console.log("444",upscaledColorA[y * ui32WordWidth + x].alpha, upscaledColorB[y * ui32WordWidth + x].red, getColorA(Q.u32ColorData), getColorB(S.u32ColorData))}
	//  if (P.u32ModulationData){
	// 	console.log("444",pColorData[y + x * ui32WordHeight].red, pColorData[y + x * ui32WordHeight].green,pColorData[y + x * ui32WordHeight].blue,pColorData[y + x * ui32WordHeight].alpha );
	// 		}}
			}
		}
	}

}



function wrapWordIndex(numWords, word){
	return ((word + numWords) % numWords);
}


function isPowerOf2( input) //uint32_t
{
	if (!input){return false;}
	let minus1 = input - 1;
	return ((input | minus1) === (input ^ minus1));
}



function TwiddleUV( XSize,  YSize,  XPos,  YPos) //uint32_t
{

	

	// Initially assume X is the larger size.
	let MinDimension = XSize;
	let MaxValue = YPos;
	let Twiddled = 0;
	let SrcBitPos = 1;
	let DstBitPos = 1;
	let ShiftCount = 0;

	// Check the sizes are valid.
	if(YPos > YSize){console.error("decomposing : TwiddleUV")};
	if(XPos > XSize){console.error("decomposing : TwiddleUV")};
	if(!isPowerOf2(YSize)){console.error("decomposing : TwiddleUV")};
	if(!isPowerOf2(XSize)){console.error("decomposing : TwiddleUV")};

	// If Y is the larger dimension - switch the min/max values.
	if (YSize < XSize)
	{
		MinDimension = YSize;
		MaxValue = XPos;
	}

	// Step through all the bits in the "minimum" dimension
	while (SrcBitPos < MinDimension)
	{
		if (YPos & SrcBitPos)
		{
			Twiddled |= DstBitPos;
		}

		if (XPos & SrcBitPos)
		{
			Twiddled |= (DstBitPos << 1);
		}

		SrcBitPos <<= 1;
		DstBitPos <<= 2;
		ShiftCount += 1;
	}

	MaxValue >>= ShiftCount;
	Twiddled |= (MaxValue << (2 * ShiftCount));

	return Twiddled;
}




	
	

//function mapDecompressedData(Pixel32* pOutput, int width, const Pixel32* pWord, const PVRTCWordIndices& words, uint8_t ui8Bpp)
function mapDecompressedData(pOutput, width, pWord, words,  ui8Bpp)
{
	
	let ui32WordWidth = 4;
	let ui32WordHeight = 4;
	if (ui8Bpp === 2)
	{
		ui32WordWidth = 8;
	}

	// for (let y = 0; y < Math.floor(ui32WordHeight / 2); y++)
	// {
	// 	for (let x = 0; x < Math.floor(ui32WordWidth / 2); x++)
	// 	{
	// 		pOutput[(((words.P[1] * ui32WordHeight) + y + Math.floor(ui32WordHeight / 2)) * width + words.P[0] * ui32WordWidth + x + Math.floor(ui32WordWidth / 2))] = pWord[y * ui32WordWidth + x]; // map P
    //       //  if( pWord[0].red &&   ui8Bpp === 4) {console.log(pWord[y * ui32WordWidth + x])}
	// 		pOutput[(((words.Q[1] * ui32WordHeight) + y + Math.floor(ui32WordHeight / 2)) * width + words.Q[0] * ui32WordWidth + x)] = pWord[y * ui32WordWidth + x + Math.floor(ui32WordWidth / 2)]; // map Q
	// 	//	if( pWord[0].red &&  ui8Bpp === 4) {console.log(pWord[y * ui32WordWidth + x + Math.floor(ui32WordWidth / 2)])}
	// 		pOutput[(((words.R[1] * ui32WordHeight) + y) * width + words.R[0] * ui32WordWidth + x + Math.floor(ui32WordWidth / 2))] = pWord[(y + Math.floor(ui32WordHeight / 2)) * ui32WordWidth + x]; // map R
	// 	//	if( pWord[0].red &&  ui8Bpp === 4) {console.log(pWord[(y + Math.floor(ui32WordHeight / 2)) * ui32WordWidth + x])}
	// 		pOutput[(((words.S[1] * ui32WordHeight) + y) * width + words.S[0] * ui32WordWidth + x)] = pWord[(y + Math.floor(ui32WordHeight / 2)) * ui32WordWidth + x + Math.floor(ui32WordWidth / 2)]; // map S
	// 	//	if( pWord[0].red &&  ui8Bpp === 4) {console.log(pWord[(y + Math.floor(ui32WordHeight / 2)) * ui32WordWidth + x + Math.floor(ui32WordWidth / 2)])}
	// 	}
	// }

	for (let y = 0; y < ui32WordHeight / 2; y++)
	{
		for (let x = 0; x < ui32WordWidth / 2; x++)
		{
			pOutput[(((words.P[1] * ui32WordHeight) + y + ui32WordHeight / 2) * width + words.P[0] * ui32WordWidth + x + ui32WordWidth / 2)].red = pWord[y * ui32WordWidth + x].red; // map P
			pOutput[(((words.P[1] * ui32WordHeight) + y + ui32WordHeight / 2) * width + words.P[0] * ui32WordWidth + x + ui32WordWidth / 2)].green = pWord[y * ui32WordWidth + x].green;
			pOutput[(((words.P[1] * ui32WordHeight) + y + ui32WordHeight / 2) * width + words.P[0] * ui32WordWidth + x + ui32WordWidth / 2)].blue = pWord[y * ui32WordWidth + x].blue;
			pOutput[(((words.P[1] * ui32WordHeight) + y + ui32WordHeight / 2) * width + words.P[0] * ui32WordWidth + x + ui32WordWidth / 2)].alpha = pWord[y * ui32WordWidth + x].alpha;
			
			// if(PVRTDecompressPVRTC.count <4 ){ console.log("pos",(((words.P[1] * ui32WordHeight) + y + ui32WordHeight / 2) * width + words.P[0] * ui32WordWidth + x + ui32WordWidth / 2))     }
			pOutput[(((words.Q[1] * ui32WordHeight) + y + ui32WordHeight / 2) * width + words.Q[0] * ui32WordWidth + x)].red = pWord[y * ui32WordWidth + x + ui32WordWidth / 2].red; // map Q
			pOutput[(((words.Q[1] * ui32WordHeight) + y + ui32WordHeight / 2) * width + words.Q[0] * ui32WordWidth + x)].green = pWord[y * ui32WordWidth + x + ui32WordWidth / 2].green; // map Q
			pOutput[(((words.Q[1] * ui32WordHeight) + y + ui32WordHeight / 2) * width + words.Q[0] * ui32WordWidth + x)].blue = pWord[y * ui32WordWidth + x + ui32WordWidth / 2].blue; // map Q
         	pOutput[(((words.Q[1] * ui32WordHeight) + y + ui32WordHeight / 2) * width + words.Q[0] * ui32WordWidth + x)].alpha = pWord[y * ui32WordWidth + x + ui32WordWidth / 2].alpha; // map Q


			pOutput[(((words.R[1] * ui32WordHeight) + y) * width + words.R[0] * ui32WordWidth + x + ui32WordWidth / 2)].red = pWord[(y + ui32WordHeight / 2) * ui32WordWidth + x].red; // map R
			pOutput[(((words.R[1] * ui32WordHeight) + y) * width + words.R[0] * ui32WordWidth + x + ui32WordWidth / 2)].green = pWord[(y + ui32WordHeight / 2) * ui32WordWidth + x].green; // map R
			pOutput[(((words.R[1] * ui32WordHeight) + y) * width + words.R[0] * ui32WordWidth + x + ui32WordWidth / 2)].blue = pWord[(y + ui32WordHeight / 2) * ui32WordWidth + x].blue; // map R
			pOutput[(((words.R[1] * ui32WordHeight) + y) * width + words.R[0] * ui32WordWidth + x + ui32WordWidth / 2)].alpha = pWord[(y + ui32WordHeight / 2) * ui32WordWidth + x].alpha; // map R

			pOutput[(((words.S[1] * ui32WordHeight) + y) * width + words.S[0] * ui32WordWidth + x)].red = pWord[(y + ui32WordHeight / 2) * ui32WordWidth + x + ui32WordWidth / 2].red; // map S
			pOutput[(((words.S[1] * ui32WordHeight) + y) * width + words.S[0] * ui32WordWidth + x)].green = pWord[(y + ui32WordHeight / 2) * ui32WordWidth + x + ui32WordWidth / 2].green; // map S
			pOutput[(((words.S[1] * ui32WordHeight) + y) * width + words.S[0] * ui32WordWidth + x)].blue = pWord[(y + ui32WordHeight / 2) * ui32WordWidth + x + ui32WordWidth / 2].blue; // map S
			pOutput[(((words.S[1] * ui32WordHeight) + y) * width + words.S[0] * ui32WordWidth + x)].alpha = pWord[(y + ui32WordHeight / 2) * ui32WordWidth + x + ui32WordWidth / 2].alpha; // map S

		}
	}

}




//static int pvrtcDecompress(uint8_t* pCompressedData, Pixel32* pDecompressedData, uint32_t ui32Width, uint32_t ui32Height, uint8_t ui8Bpp)

function pvrtcDecompress( pCompressedData, pDecompressedData,  ui32Width,  ui32Height,  ui8Bpp)
{


	let ui32WordWidth = 4;
	let ui32WordHeight = 4;
	if (ui8Bpp === 2)
	{
		ui32WordWidth = 8;
	}

	

	let pWordMembers = new Uint32Array(pCompressedData.buffer);




	let pOutData = pDecompressedData;

	// Calculate number of words
	let i32NumXWords = Math.floor(ui32Width / ui32WordWidth);
	let i32NumYWords = Math.floor(ui32Height / ui32WordHeight);

	// Structs used for decompression
	let indices = new PVRTCWordIndices();
	
	let pPixels = new Array(ui32WordWidth * ui32WordHeight);
	for (let i = 0 ; i< (ui32WordWidth * ui32WordHeight); i++) pPixels[i] = new Pixel32();
	

	// For each row of words
	for (let wordY = -1; wordY < i32NumYWords - 1; wordY++)
	{
		// for each column of words
		for (let wordX = -1; wordX < i32NumXWords - 1; wordX++)
		{
			indices.P[0] = wrapWordIndex(i32NumXWords, wordX);
			indices.P[1] = wrapWordIndex(i32NumYWords, wordY);
			indices.Q[0] = wrapWordIndex(i32NumXWords, wordX + 1);
			indices.Q[1] = wrapWordIndex(i32NumYWords, wordY);
			indices.R[0] = wrapWordIndex(i32NumXWords, wordX);
			indices.R[1] = wrapWordIndex(i32NumYWords, wordY + 1);
			indices.S[0] = wrapWordIndex(i32NumXWords, wordX + 1);
			indices.S[1] = wrapWordIndex(i32NumYWords, wordY + 1);

			// Work out the offsets into the twiddle structs, multiply by two as there are two members per word.
			let WordOffsets = [
				TwiddleUV(i32NumXWords, i32NumYWords, indices.P[0], indices.P[1]) * 2,
				TwiddleUV(i32NumXWords, i32NumYWords, indices.Q[0], indices.Q[1]) * 2,
				TwiddleUV(i32NumXWords, i32NumYWords, indices.R[0], indices.R[1]) * 2,
				TwiddleUV(i32NumXWords, i32NumYWords, indices.S[0], indices.S[1]) * 2,
            ];

			// Access individual elements to fill out PVRTCWord
			let P = new PVRTCWord();
			let Q = new PVRTCWord();
			let R = new PVRTCWord();
			let S = new PVRTCWord();
			
		
			P.u32ColorData = pWordMembers[WordOffsets[0] + 1];
			P.u32ModulationData = pWordMembers[WordOffsets[0]];
			Q.u32ColorData = pWordMembers[WordOffsets[1] + 1];
			Q.u32ModulationData = pWordMembers[WordOffsets[1]];
			R.u32ColorData = pWordMembers[WordOffsets[2] + 1];
			R.u32ModulationData = pWordMembers[WordOffsets[2]];
			S.u32ColorData = pWordMembers[WordOffsets[3] + 1];
			S.u32ModulationData = pWordMembers[WordOffsets[3]];
			
		

			// if (ui8Bpp ===4 && ui32Width === 8 && ui32Height === 8 ){
				
			// 	if (!PVRTDecompressPVRTC.count) {PVRTDecompressPVRTC.count = 0};
				
			// 	PVRTDecompressPVRTC.count++;
				
			// 	if(PVRTDecompressPVRTC.count < 10) {console.log("data", 
			// 	WordOffsets)}
			// 	}

			  


			// assemble 4 words into struct to get decompressed pixels from
			pvrtcGetDecompressedPixels(P, Q, R, S, pPixels, ui8Bpp);
			 
			// if (ui8Bpp ===4 && ui32Width === 8 && ui32Height === 8 ){
				
			
			// 	if (!PVRTDecompressPVRTC.count) {PVRTDecompressPVRTC.count = 0};
				
			// 	PVRTDecompressPVRTC.count++;
				
			// 	if(PVRTDecompressPVRTC.count < 10) {
			// 		console.log("data", indices.P[0],indices.P[1],indices.Q[0],indices.Q[1],indices.R[0],indices.R[1],indices.S[0],indices.S[1],);

			// 		console.log("data", 
			// 	   pPixels.map(x=> { return [x.red, x.green, x.blue, x.alpha]}))
			// 	}

			//   }

			mapDecompressedData(pOutData, ui32Width, pPixels, indices, ui8Bpp);
        //   if (ui8Bpp ===4 && ui32Width === 8 && ui32Height === 8 ){
		// 	if (!PVRTDecompressPVRTC.count) {PVRTDecompressPVRTC.count = 0};
		// 	PVRTDecompressPVRTC.count++;
		// 	if(PVRTDecompressPVRTC.count < 10) {console.log("data", pOutData.map(x=> { return [x.red, x.green, x.blue, x.alpha];}))
		//   		  }}

		} // for each word
	} // for each row of words

	//pPixels = null;
	// Return the data size
	return Math.floor(ui32Width * ui32Height / Math.floor((ui32WordWidth / 2)));
}

 const  PVRTDecompressPVRTC = function(pCompressedData, Do2bitMode, XDim, YDim, pResultImage)
// PVRTDecompressPVRTC(const void* pCompressedData, uint32_t Do2bitMode, uint32_t XDim, uint32_t YDim, uint8_t* pResultImage)
{
	// Cast the output buffer to a Pixel32 pointer.
	//let pDecompressedData = (Pixel32*)pResultImage;
	let m_length = 0;
	m_length = pResultImage;
	let pDecompressedData =  [];//new Array(m_length); // pResultImage;   //pResultImage //ArrayBytes
	for (let i = 0; i< m_length; i++) pDecompressedData[i] = new Pixel32();
	
	// Check the X and Y values are at least the minimum size.
	let XTrueDim = Math.max(XDim, ((Do2bitMode === 1) ? 16 : 8));
	let YTrueDim = Math.max(YDim, 8);
	// If the dimensions aren't correct, we need to create a new buffer instead of just using the provided one, as the buffer will overrun otherwise.
	if (XTrueDim !==XDim || YTrueDim !==YDim)
	{
		//let m_length = 0;
		m_length = XTrueDim * YTrueDim;
		//pResultImage = parseInt(  (XTrueDim * YTrueDim).toString(), 10);
		//m_length = pResultImage;
		pDecompressedData =  [];//new Array(m_length);
		for (let i = 0 ; i<m_length; i++) pDecompressedData[i] = new Pixel32();
	}

	// Decompress the surface.
	//let retval = 
	pvrtcDecompress(pCompressedData, pDecompressedData, XTrueDim, YTrueDim, (Do2bitMode === 1 ? 2 : 4));

	// If the dimensions were too small, then copy the new buffer back into the output buffer.
	if (XTrueDim !==XDim || YTrueDim !== YDim)
	{
		// let temLength = 0;
		// temLength =  XDim*YDim;
		let tempDecompressedData = [];//new Array(m_length); // pResultImage;   //pResultImage //ArrayBytes
		for (let i = 0; i< m_length; i++) tempDecompressedData[i] = new Pixel32();

		// Loop through all the required pixels.
		for (let x = 0; x < XDim; ++x)
		{
			for (let y = 0; y < YDim; ++y)
			{
				tempDecompressedData[x + y * XDim] = pDecompressedData[x + y * XTrueDim];
			}
		}
		
		let buffer = new ArrayBuffer(m_length*4); //new ArrayBuffer(XDim*YDim*4);
	let dv = new DataView(buffer);
    for (let i = 0; i< m_length; i++) {
	//	console.log(tempDecompressedData[i]);
	//	console.log("pDecompressedData",pDecompressedData[i]);
	//	console.log(tempDecompressedData[i].alpha);
	//	dv.setUint32(4*i ,tempDecompressedData[i], true);
		 dv.setUint8( i*4, tempDecompressedData[i].red );
		 dv.setUint8( i*4+1, tempDecompressedData[i].green );
		 dv.setUint8( i*4+2, tempDecompressedData[i].blue );
		 dv.setUint8( i*4+3, tempDecompressedData[i].alpha );
	}
	return new Uint8Array(buffer) ;

		// Free the temporary buffer.
	}
	else{
	let buffer = new ArrayBuffer(m_length*4);
	let dv = new DataView(buffer);
    for (let i = 0; i< m_length; i++) {

	//	console.log(pDecompressedData[i]);
//	console.log(pDecompressedData[i].alpha);

//	dv.setUint32(4*i ,pDecompressedData[i], true);
		 dv.setUint8( i*4, pDecompressedData[i].red );
		 dv.setUint8( i*4+1, pDecompressedData[i].green );
		 dv.setUint8( i*4+2, pDecompressedData[i].blue );
		 dv.setUint8( i*4+3, pDecompressedData[i].alpha );
	
	}
// if(Do2bitMode ===0){
// console.log("Uint8Array(buffer)", pDecompressedData, new Uint8Array(buffer));
// }

	return new Uint8Array(buffer) ;
}

 //PVRTDecompressPVRTC.count = 0;

}

export default PVRTDecompressPVRTC