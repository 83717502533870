import { PVRMatrix4x4 , PVRVector3} from "./webgl/Tools/PVRMaths"
import {PVRPrint3D, EPVRPrint3D} from "./webgl/Tools/PVRPrint3D"
import {PVRPFXEffect , EPVRTPFXUniformSemantic}from "./webgl/Tools/PVREffect"
import PVRPFXParser from "./webgl/Tools/PVREffectParser"
import PVRTexture from "./webgl/Tools/PVRTexture"



const PVRTEX_CUBEMAP = 1<<12;


const PVRT_PI = 3.1415926535;
const CAMERA_DISTANCE	 = 50;
const  ALTITUDE	= 15
const  TO_ALTITUDE	 =	0

const  CAM_NEAR	 = 4.0
const  CAM_FAR	=	5000.0

const  CUBEMAP_FLAG = 0x1000

const  SHADER_PATH = "" 	// SHADER_PATH is always relative to the location where the exe is located
const 		shader_path = "";
const 	g_ShaderList = ["envmap", "directional_lighting", "anisotropic_lighting", "fasttnl", "lattice", "point_lighting", "phong_lighting", "reflections", "simple", "spot_lighting", "toon", "vertex_sine", "wood"];
const 	g_SurfacesList = ["Torus", "Moebius", "KleinBottle", "BoySurface", "DiniSurface"];
const 	g_TextureList = ["base", "reflection", "anisotropicmap", "cubemap"];
const 		g_numShaders =	13
const 		g_numSurfaces = 5
const 		g_numTextures =	4

//typedef void (*PFUNCTION)(float u, float v, float* x, float* y, float* z);

/******************************************************************************
 Content file names
******************************************************************************/

const ETextures =
{
	eTexAnisotropic : 0,
	eTexBase : 1,
	eTexReflection: 2,
	eTexCubeMap :3
};

// Textures
const  g_aszTextureNames = [
	"AnisoMap.pvr",
	"Basetex.pvr",
	"Reflection.pvr",
	"Cubemap.pvr",
];

/****************************************************************************
 Class: ParametricSurface
 Description: This class creates the geometrical meshes to which we will apply
              our shaders library.
****************************************************************************/
const  ParametricSurface = function(gl, dSampleU, dSampleV)
{

	// Vertex buffer objects for vertices, UV's and normals.
//    let iVertexVBO = {}
//    let iUvVBO = {}
//     let iNormalVBO = {}

    let pVertex = [];
    let pUV=[]; 
    let pNormal = [];

	
    let  fMinU = 0;
    let  fMaxU= 0; 
     let  fMinV= 0;
     let  fMaxV= 0;
     let nSampleU = 0;
     let  nSampleV = 0;
     if (dSampleU) nSampleU = dSampleU
     if (dSampleV) nSampleV = dSampleV

     ParametricSurface.prototype.getNumFaces = function()
     {
         return (nSampleU-1)*(nSampleV-1)*2;
     }
     

    let pIndex =  new Uint16Array(this.getNumFaces()*3);  //new unsigned short

       // Generate three vertex buffer objects.
       this.iVertexVBO =  gl.createBuffer();
       this.iUvVBO = gl.createBuffer();
       this.iNormalVBO = gl.createBuffer();
       this.iIBO = gl.createBuffer();

       for (let i=0; i<nSampleU-1; i++)
       {
           for (let j=0; j<nSampleV-1; j++)
           {
               pIndex[ (j*(nSampleU-1)+i)*6 + 0 ] =  (j * nSampleU + i);
               pIndex[ (j*(nSampleU-1)+i)*6 + 1 ] =  (j * nSampleU + (i+1));
               pIndex[ (j*(nSampleU-1)+i)*6 + 2 ] =  ((j+1) * nSampleU + (i+1));
               pIndex[ (j*(nSampleU-1)+i)*6 + 3 ] =  (j * nSampleU + i);
               pIndex[ (j*(nSampleU-1)+i)*6 + 4 ] =  ((j+1) * nSampleU + (i+1));
               pIndex[ (j*(nSampleU-1)+i)*6 + 5 ] =  ((j+1) * nSampleU + i);
           }
       }

       gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, this.iIBO);
       //gl.bufferData(gl.ARRAY_BUFFER, nVertex * 2 * sizeof (float), pUV, gl.STATIC_DRAW);
       gl.bufferData(gl.ELEMENT_ARRAY_BUFFER,  pIndex, gl.STATIC_DRAW);



   // ParametricSurface(int dSampleU, int dSampleV);
    
   // void ComputeVertexAndNormals(PFUNCTION function, float dMinU, float dMaxU, float dMinV, float dMaxV);


   ParametricSurface.prototype.deleteParametricSurface = function(gl)
   {
       gl.deleteBuffers(this.iVertexVBO);
       gl.deleteBuffers(this.iUvVBO);
       gl.deleteBuffers(this.iNormalVBO);
       pIndex = null
   }
   
//    ParametricSurface.prototype.parametricSurface = function( dSampleU,  dSampleV) : iVertexVBO(0), iUvVBO(0), iNormalVBO(0), pVertex(0),
//                                                                        pUV(0),pNormal(0), pIndex(0), fMinU(0), fMaxU(0), fMinV(0), 
//                                                                        fMaxV(0), nSampleU(dSampleU), nSampleV(dSampleV)
//    {
       
//    }

   
   ParametricSurface.prototype.computeVertexAndNormals = function(gl, func,  dMinU,  dMaxU,  dMinV,  dMaxV){
  
   // ParametricSurface.prototype.computeVertexAndNormals(PFUNCTION function, float dMinU, float dMaxU, float dMinV, float dMaxV)
   
       let nVertex = nSampleU * nSampleV;
       pVertex = new Float32Array(nVertex*3);
       pNormal = new Float32Array(nVertex*3);
       pUV = new Float32Array(nVertex*2);
   
       fMinU = dMinU;
       fMaxU = dMaxU;
       fMinV = dMinV;
       fMaxV = dMaxV;
   
       for (let i=0; i<nSampleU; i++)
       {
           for (let j=0; j<nSampleV; j++)
           {
               let u = fMinU + i * (fMaxU-fMinU) / (nSampleU-1);
               let v = fMinV + j * (fMaxV-fMinV) / (nSampleV-1);
               let x = {};
               let y = {};
               let z = {};
            
               func(u,v, x,y,z);
               pVertex[(j*nSampleU+i)*3 + 0] = x.value;
               pVertex[(j*nSampleU+i)*3 + 1] = y.value;
               pVertex[(j*nSampleU+i)*3 + 2] = z.value;
           }
       }
   
       for (let i=0; i<nSampleU; i++)
       {
           for (let j=0; j<nSampleV; j++)
           {
               pUV[ (j*nSampleU+i)*2 + 0 ] = i / (nSampleU-1);
               pUV[ (j*nSampleU+i)*2 + 1 ] = j / (nSampleV-1);
           }
       }
   
       for (let i=0; i<nSampleU-1; i++)
       {
           for (let j=0; j<nSampleV-1; j++)
           {
               let ptA = new PVRVector3(pVertex[(j*nSampleU+i)*3+0],pVertex[(j*nSampleU+i)*3+1],pVertex[(j*nSampleU+i)*3+2]);
               let ptB = new PVRVector3(pVertex[(j*nSampleU+i+1)*3+0],pVertex[(j*nSampleU+i+1)*3+1],pVertex[(j*nSampleU+i+1)*3+2]);
               let ptC = new PVRVector3(pVertex[((j+1)*nSampleU+i)*3+0],pVertex[((j+1)*nSampleU+i)*3+1],pVertex[((j+1)*nSampleU+i)*3+2]);
               let AB = new PVRVector3(ptB.data[0]-ptA.data[0], ptB.data[1]-ptA.data[1], ptB.data[2]-ptA.data[2]);
               let AC = new PVRVector3(ptC.data[0]-ptA.data[0], ptC.data[1]-ptA.data[1], ptC.data[2]-ptA.data[2]);
               let normal;
   
               normal = PVRVector3.cross(AB, AC) //  AB.cross(AC);
               normal.normalise();
   
               pNormal[(j*nSampleU+i)*3 + 0] = -normal.data[0];
               pNormal[(j*nSampleU+i)*3 + 1] = -normal.data[1];
               pNormal[(j*nSampleU+i)*3 + 2] = -normal.data[2];
           }
       }
   
       for (let i=0; i<nSampleU-1; i++)
       {
           pNormal[((nSampleV-1)*nSampleU+i)*3+0] = pNormal[(i)*3+0];
           pNormal[((nSampleV-1)*nSampleU+i)*3+1] = pNormal[(i)*3+1];
           pNormal[((nSampleV-1)*nSampleU+i)*3+2] = pNormal[(i)*3+2];
       }
   
       for (let j=0; j<nSampleV-1; j++)
       {
           pNormal[(j*nSampleU+nSampleU-1)*3+0] = pNormal[(j*nSampleU)*3+0];
           pNormal[(j*nSampleU+nSampleU-1)*3+1] = pNormal[(j*nSampleU)*3+1];
           pNormal[(j*nSampleU+nSampleU-1)*3+2] = pNormal[(j*nSampleU)*3+2];
       }
   
       pNormal[((nSampleV-1)*nSampleU + (nSampleU-1))*3+0]= pNormal[((nSampleV-2)*nSampleU + (nSampleU-2))*3+0];
       pNormal[((nSampleV-1)*nSampleU + (nSampleU-1))*3+1]= pNormal[((nSampleV-2)*nSampleU + (nSampleU-2))*3+1];
       pNormal[((nSampleV-1)*nSampleU + (nSampleU-1))*3+2]= pNormal[((nSampleV-2)*nSampleU + (nSampleU-2))*3+2];
   
       // Insert generated data into vertex buffer objects.
       gl.bindBuffer(gl.ARRAY_BUFFER, this.iVertexVBO);
       //gl.bufferData(gl.ARRAY_BUFFER, nVertex * 3 * sizeof (float), pVertex, gl.STATIC_DRAW);
       gl.bufferData(gl.ARRAY_BUFFER,  pVertex, gl.STATIC_DRAW);
   

       gl.bindBuffer(gl.ARRAY_BUFFER, this.iUvVBO);
       //gl.bufferData(gl.ARRAY_BUFFER, nVertex * 2 * sizeof (float), pUV, gl.STATIC_DRAW);
       gl.bufferData(gl.ARRAY_BUFFER,  pUV, gl.STATIC_DRAW);
   

       gl.bindBuffer(gl.ARRAY_BUFFER, this.iNormalVBO);
       //gl.bufferData(gl.ARRAY_BUFFER, nVertex * 3 * sizeof (float), pNormal, gl.STATIC_DRAW);
       gl.bufferData(gl.ARRAY_BUFFER,  pNormal, gl.STATIC_DRAW);
   

       gl.bindBuffer(gl.ARRAY_BUFFER, null); // Unbind the last buffer used.

        pVertex = null;
       pNormal = null;
       pUV = null;
   }




};

/****************************************************************************
 Surface functions
 Description: Mathematical surfaces to be used by ParametricSurface
****************************************************************************/
// void func_Plan(float u,float v, float* x,float* y,float* z);
// void func_Moebius(float u,float v, float* x,float* y,float* z);
// void func_Torus(float u,float v, float* x,float* y,float* z);
// void func_KleinBottle(float u,float v, float* x,float* y,float* z);
// void func_BoySurface(float u,float v, float* x,float* y,float* z);
// void func_DiniSurface(float u,float v, float* x,float* y,float* z);


 const Surface = function(fun, fMinU, fMaxU, fMinV, fMaxV)
{
	this.function = null; //PFUNCTION
    this.fMinU = 0;
 this.fMaxU = 0;
 this.fMinV = 0;
 this.fMaxV = 0;
 if (fun) this.function = fun;
 if (fMinU) this.fMinU = fMinU;
 if (fMaxU) this.fMaxU = fMaxU;
 if (fMinV) this.fMinV = fMinV;
 if (fMaxV) this.fMaxV = fMaxV;

};






const func_Plan = function( u, v,  x, y, z)
{
	x.value = u;
	y.value = 0;
	z.value = v;
}

const func_Moebius = function( u, v,  x, y, z)
{
	let R = 9;
	x.value = R * ( Math.cos(v) + u * Math.cos(v / 0.5) * Math.cos(v));
	y.value = R * (Math.sin(v) + u *  Math.cos(v / 0.5) * Math.sin(v));
	z.value = R * u * Math.sin(v / 0.5);
}

const func_Torus = function( u, v,  x, y, z)
{
	let R=2, r=4;
	x.value = R * Math.cos(v) * (r + Math.cos(u));
	y.value = R * Math.sin(v) * (r + Math.cos(u));
	z.value = R * Math.sin(u);
}

const func_KleinBottle = function( u, v,  x,  y, z)
{
	let botx = (6-2)  * Math.cos(u) * (1 + Math.sin(u));
	let boty = (16-4) * Math.sin(u);
	let rad  = (4-1)  * (1 - Math.cos(u)/2);

	if (u > 1.7 * PVRT_PI)
	{
		x.value = botx + rad * Math.cos(u) * Math.cos(v);
		y.value = boty + rad * Math.sin(u) * Math.cos(v);
	}
	else if (u > PVRT_PI)
	{
		x.value = botx + rad * Math.cos(v+PVRT_PI);
		y.value = boty;
	}
	else
	{
		x.value = botx + rad * Math.cos(u) * Math.cos(v);
		y.value = boty + rad * Math.sin(u) * Math.cos(v);
	}

	z.value = -rad *  Math.sin(v);
	y.value -= 2;
}

const func_BoySurface = function( u, v,  x,  y, z)
{
	let a = Math.cos(u*0.5) * Math.sin(v);
	let b = Math.sin(u*0.5) * Math.sin(v);
	let c = Math.cos(v);
	x.value = ( (2*a*a-b*b-c*c) + 2*b*c*(b*b-c*c) + c*a*(a*a-c*c) + a*b*(b*b-a*a) ) / 2;
	y.value = ( (b*b-c*c) + c*a*(c*c-a*a) + a*b*(b*b-a*a) ) * Math.sqrt(3.0) / 2;
	z.value = (a+b+c) * ( (a+b+c)*(a+b+c)*(a+b+c) + 4*(b-a)*(c-b)*(a-c) )/8;
	x.value *=10;
	y.value *=10;
	z.value *=10;
}
const func_DiniSurface= function( u, v,  x,  y, z)
{
	x.value =   Math.cos(u) * Math.sin(v);
	y.value = - Math.cos(v) - Math.log(Math.tan(v/2)) - 0.2*u;
	z.value =  - Math.sin(u) * Math.sin(v);
	x.value*=5;
	y.value*=4;
	z.value*=5;
	y.value-=3;
}


/* Mesh display list composed by the name of the geometry function and the function limits*/
let SurfaceList = [
	new Surface(func_Torus,		0, 2*PVRT_PI,		0, 2*PVRT_PI),
	new Surface(func_Moebius,		-PVRT_PI/6.0, PVRT_PI/6.0,	0, 2*PVRT_PI),
	new Surface(func_KleinBottle,	0, 2*PVRT_PI,		0, 2*PVRT_PI),
	new Surface(func_BoySurface,	0.001, PVRT_PI,	0.001, PVRT_PI),
	new Surface(func_DiniSurface,	0, 4*PVRT_PI,		0.01, 1.7)
];

/****************************************************************************
 Class: OGLES2Shaders
 To use the PowerVR framework, you have to inherit a class from PVRShell
 and implement the five virtual functions which describe how your application
 initializes, runs and releases the resources.
****************************************************************************/
const  WebGLShaders = function (data)
{




	let documentData = data
   
	let dataResult = function(thisdata, a, b)  {
		 
		return   thisdata.filter(function(elt)  {
			return elt.endsWith(b) && elt.includes(a)
		})[0]
		}
	
	
	const myresult = (documentData.allFile.edges.map((file, index) => {return (file.node.publicURL ) }))
	
	
	let getFileLocation = function(name) {
		const dataResult = function(thisdata, a, b)  {
			return   thisdata.filter(function(elt)  {
				return elt.endsWith(b) && elt.includes(a)
			})[0]
			}
			let r1 = name.split(".")
			let l   = r1.length;
			let filename = r1[0];
			for (let i =1; i<l-1; i++) filename += r1[i];
			//let result = data.allFile.edges.map((file, index) => {return (file.node.publicURL ) })
				return (
					dataResult(myresult, r1[0],r1[l-1])
				)
			}
	

			


    // Print 3D Class Object
	 let  m_Print3D = new PVRPrint3D(myresult)

	let	m_Surface = {}
     let       m_mProjection = new PVRMatrix4x4();
     let       m_mModelView = new PVRMatrix4x4();
    let        m_mView = new PVRMatrix4x4();
   

    let				m_nCurrentShader = 0
    let             m_nCurrentSurface   = 0;
    let 			m_fViewAngle = 0;
    
	// let 			m_puiTextureHandle = [];  //[g_numTextures] ;
	// let          	m_uiTextureFlags = []; //[g_numTextures];
 


	// The effect file handlers
//	CPVRTPFXParser*				m_ppEffectParser[g_numShaders];
//	CPVRTPFXEffect*				m_ppEffect[g_numShaders];

//public:

//	OGLES2Shaders();

	/* PVRShell functions */
	// virtual bool InitApplication();
	// virtual bool InitView();
	// virtual bool ReleaseView();
	// virtual bool QuitApplication();
	// virtual bool RenderScene();

	// void computeSurface( nSurface);
	// void computeViewMatrix();
	// void drawModel();
	// void freeMemory();

/*!****************************************************************************
 @Function		OGLES2Shaders
 @Return		none
 @Description	Application class constructor to set some variables.
******************************************************************************/
// WebGLShaders.prototype.OGLES2Shaders() : 	m_fViewAngle(0),
// 									m_nCurrentShader(0),
// 									m_nCurrentSurface(0),
// 									m_Surface(0)
// {
// 	for(let i = 0; i < g_numShaders; i++)
// 	{
// 		m_ppEffectParser[i] = null;
// 		m_ppEffect[i] = null;
// 	}
// }
/*!****************************************************************************
 @Function		InitApplication
 @Return		bool		true if no error occurred
 @Description	Code in InitApplication() will be called by PVRShell once per
				run, before the rendering context is created.
				Used to initialize variables that are not dependent on it
				(e.g. external modules, loading meshes, etc.)
				If the rendering context is lost, InitApplication() will
				not be called again.
******************************************************************************/
 WebGLShaders.prototype.initApplication = function(PVRShell)
{

    this.LoadingTextures =  g_numTextures;
    this.loadingEffects = g_numShaders; 

    this.currentEffectIndex = this.loadingEffects;

    this.loadingModels = 1;

    this.m_ppEffect = [];
this.m_ppEffectParser = [];

    for(let i = 0; i < g_numShaders; i++)
	{
		this.m_ppEffectParser[i] = {};
		this.m_ppEffect[i] = {};
    }
    
	// Get and set the read path for content files
	//CPVRTResourceFile::SetReadPath((char*)PVRShellGet(prefReadPath));

	// Get and set the load/release functions for loading external files.
	// In the majority of cases the PVRShell will return NULL function pointers implying that
	// nothing special is required to load external files.
	//CPVRTResourceFile::SetLoadReleaseFunctions(PVRShellGet(prefLoadFileFunc), PVRShellGet(prefReleaseFileFunc));

	/*
		Analyse command line
		the format is: -s=? -m=? where s in the shader and m is the mesh
		e.g. OGLES2Shaders -s=3 -m=4
	*/
//	let nClNum	= PVRShellGet(prefCommandLineOptNum);
//	const SCmdLineOpt* pOpt	= (const SCmdLineOpt*)PVRShellGet(prefCommandLineOpts);

	// for(let i = 0; i < nClNum; ++i)
	// {
	// 	if(pOpt[i].pArg[0] == '-')
	// 	{
	// 		switch (pOpt[i].pArg[1])
	// 		{
	// 		case 's':case 'S':
	// 			m_nCurrentShader	= atoi(pOpt[i].pVal) % g_numShaders;
	// 			continue;

	// 		case 'm':case 'M':
	// 			m_nCurrentSurface	= atoi(pOpt[i].pVal) % g_numSurfaces;
	// 			continue;
	// 		}
	// 	}
	// }

	return true;
}
/*!****************************************************************************
 @Function		QuitApplication
 @Return		bool		true if no error occurred
 @Description	Code in QuitApplication() will be called by PVRShell once per
				run, just before exiting the program.
				If the rendering context is lost, QuitApplication() will
				not be called.
******************************************************************************/
  WebGLShaders.prototype.quitApplication = function()
{
    return true;
}

/*!****************************************************************************
 @Function		InitView
 @Return		bool		true if no error occurred
 @Description	Code in InitView() will be called by PVRShell upon
				initialization or after a change in the rendering context.
				Used to initialize variables that are dependant on the rendering
				context (e.g. textures, vertex buffers, etc.)
******************************************************************************/
WebGLShaders.prototype.loadTextures = function(gl)
{

    let pTexture = {};
  
	//let header = PVRTextureHeaderV3();

	for(let i = 0; i < g_numTextures; ++i)
	{
		if( g_TextureList[i] === "base" )
			pTexture =  g_aszTextureNames[ETextures.eTexBase];
		else if( g_TextureList[i] === "reflection")
			pTexture =  g_aszTextureNames[ETextures.eTexReflection];
		else if( g_TextureList[i] === "cubemap")
			pTexture =  g_aszTextureNames[ETextures.eTexCubeMap];
		else if(g_TextureList[i] === "anisotropicmap")
			pTexture = g_aszTextureNames[ETextures.eTexAnisotropic];



            (function(gl, demo)
            {
            
                PVRTexture.loadFromURI( gl, getFileLocation(pTexture), i,
                    function(gl, id, h)
                    {
                
                 demo.m_puiTextureHandle[i] = id;
                        //Clear the bit that denotes that this texture is loaded
              //   demo.m_uiTextureFlags[i] = (h.numFaces===6?PVRTEX_CUBEMAP:0) | (h.MIPMapCount>1?PVRTEX_MIPMAP:0) | (h.depth>1?PVRTEX_VOLUME:0);
              demo.m_uiTextureFlags[i] = (h.numFaces===6?PVRTEX_CUBEMAP:0) | (h.MIPMapCount>1?1<<8:0) | (h.depth>1?1<<14:0);



    //              this.version = 0x03525650;
    // this.flags = 0;
    // this.pixelFormatH = 0;
    // this.pixelFormatL = 0;
    // this.colourSpace = 0;
    // this.channelType = 0;
    // this.height = 1;
    // this.width = 1;
    // this.depth = 1;
    // this.numSurfaces = 1;
    // this.numFaces = 1;
    // this.MIPMapCount = 1;
    // this.metaDataSize = 0;

                        // if(gl.getError()) { 
                        //  alert(sTextureName) ;  
                        //  alert("Error while loading texture!"); }
                      
                        // gl.bindTexture(gl.TEXTURE_2D, id);

                        // gl.texParameterf(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR_MIPMAP_LINEAR);
                        // gl.texParameterf(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
                       
                        //    gl.texParameterf(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.REPEAT);
                        //    gl.texParameterf(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.REPEAT);
                       
                          // gl.texParameterf(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
                          // gl.texParameterf(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
                       

                     //    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
                     //     gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
                     //    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
                     //     gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
                        
                        demo.LoadingTextures  -= 1;//&= ~(1<<1);
                    
                    });
            })(gl, this);





		// if(PVRTexture.loadFromPVR(pTexture, this.m_puiTextureHandle[i], header))
		// {
		// 	console.log("ERROR: Could not open texture file ",pTexture);
		// 	//PVRShellSet(prefExitMessage, ErrorStr.c_str());
		// 	return false;
		// }

		// // Get texture flags form the header
		// this.m_uiTextureFlags[i] = (Header.u32NumFaces===6?PVRTEX_CUBEMAP:0) | (Header.u32MIPMapCount>1?PVRTEX_MIPMAP:0) | (Header.u32Depth>1?PVRTEX_VOLUME:0);
	}


}


WebGLShaders.prototype.loadEffects = function(gl){
    
   if(this.loadingEffects === this.currentEffectIndex){
      
    this.currentEffectIndex--;
    this.loadEffect(gl, this.currentEffectIndex)
   }
}


WebGLShaders.prototype.loadEffect = function(gl, j)
{


	// for(let j = 0; j < g_numShaders; j++)
	// {
		let		fileName = "";
		let 	nUnknownUniformCount  = 0;
	

		/*
			Parse the file
		*/
		this.m_ppEffectParser[j] = new PVRPFXParser();
		fileName = g_ShaderList[j] + ".pfx";

		this.m_ppEffectParser[j].parseFromFile(gl, this, getFileLocation(  fileName)  ,function(gl,demo, parser){
         
            // let  uiNumEffects = parser.getNumberEffects();

            // demo.LoadingEffects = uiNumEffects;

            // demo.m_ppPFXEffects = new  Array(uiNumEffects)   ;   //CPVRTPFXEffect*[uiNumEffects];
            // demo.m_pUniformMapping = new Array(uiNumEffects);  //CPVRTMap<int,int> 
        
            // for (let  i=0; i < uiNumEffects; i++) {
            //     demo.m_ppPFXEffects[i] = new PVRPFXEffect(m_sContext);
            //     demo.m_pUniformMapping[i] = new Map();
            // }
		// {
		// 	console.log("Parse failed for ", fileName ,":\n\n" );
			
		// 	this.freeMemory(gl);
		// 	return false;
		// }

		/*
			Load the effect from the file
		*/
        
        demo.m_ppEffect[j] = new PVRPFXEffect();
      
        demo.m_ppEffect[j].load(gl, parser, "myEffect", null, null, nUnknownUniformCount,  
        function(gl,demo){
           
        
            const  sTex = demo.m_ppEffect[j].getTextureArray();  //CPVRTArray<SPVRTPFXTexture>&
            
            // Loop over textures used in the CPVRTPFXEffect
            for(let i = 0; i < sTex.length; ++i)
            {
                let iTexIdx = demo.m_ppEffectParser[j].findTextureByName(sTex[i].name);
                const fileName = demo.m_ppEffectParser[j].getTexture(iTexIdx).fileName;
    
                let k = 0;
                // Loop over available textures
                for( k = 0; k < g_numTextures; k++)
                {
                    let texName;
                    texName =  g_TextureList[k] + ".pvr";
                    
                    if(fileName === texName)
                    {
                        // Set the current texture
                        if((demo.m_uiTextureFlags[k] & CUBEMAP_FLAG) !== 0)
                            gl.bindTexture(gl.TEXTURE_CUBE_MAP, demo.m_puiTextureHandle[k]);
                        else
                            gl.bindTexture(gl.TEXTURE_2D, demo.m_puiTextureHandle[k]);
    
                        // Link the texture to the CPVRTPFXEffect and apply filtering
                        demo.m_ppEffect[j].setTexture(gl, i, demo.m_puiTextureHandle[k], demo.m_uiTextureFlags[k]);
    
                        break;
                    }
                }
                if(k === g_numTextures)
                {
                    // Texture not found
                    console.log("Warning: effect file requested unrecognised texture: \"%s\"\n", fileName);
                    demo.m_ppEffect[j].setTexture(gl, i, 0);
                }
            }
  demo.loadingEffects --;

        },demo);
    })
		// if(demo.m_ppEffect[j].load(m_ppEffectParser[j], "myEffect", fileName, null, nUnknownUniformCount) )
		// {
		// 	console.log("fail to load effect");
		// 	this.freeMemory(gl);
		// 	return false;
		// }

		// if(nUnknownUniformCount)
		// {
		// 	console.log("PFX File: %s\n%s Unknown uniform semantic count: %d\n", fileName, nUnknownUniformCount);
		// 	//PVRShellSet(prefExitMessage, error.c_str());

		// 	this.freeMemory(gl);
		// 	return false;
		// }
		// if(!error.empty())
		// {
		// 	PVRShellOutputDebug(error.c_str());
		// }

		/*
			Link the textrues to the effect.
		*/
	
	

}

WebGLShaders.prototype.loadModels = function(gl)
{
	gl.enable(gl.DEPTH_TEST);
	gl.disable(gl.CULL_FACE);

	// Create the surface
	m_Surface = new ParametricSurface(gl, 50,50);
	this.computeSurface(gl, m_nCurrentSurface);



}




 WebGLShaders.prototype.initView = function(gl, PVRShell)
{
	// Is the screen rotated
//	let bRotate = PVRShellGet(prefIsRotated) && PVRShellGet(prefFullScreen);
this.m_puiTextureHandle = [];
this.m_uiTextureFlags = [];
	/* Initialize Print3D textures */
	m_Print3D.setTextures(gl,PVRShell.data.width,PVRShell.data.height)  //!= PVR_SUCCESS)
	// {
	// 	PVRShellOutputDebug("ERROR: Cannot initialise Print3D\n");
	// 	return false;
	// }

	gl.clearColor(0.6, 0.8, 1.0, 1.0);

	m_mProjection = PVRMatrix4x4.createPerspectiveProjection( CAM_NEAR, CAM_FAR, PVRT_PI/6.0, PVRShell.data.width/PVRShell.data.height);

	m_mView = PVRMatrix4x4.identity();

    return true;
	/*
		Loads the textures.
	*/

	// Textures

	// Get pointer to the texture name
	//CPVRTString ErrorStr;

    let pTexture = {};
    this.LoadingTextures =  g_numTextures;
	//let header = PVRTextureHeaderV3();

	for(let i = 0; i < g_numTextures; ++i)
	{
		if( g_TextureList[i] === "base" )
			pTexture =  g_aszTextureNames[ETextures.eTexBase];
		else if( g_TextureList[i] === "reflection")
			pTexture =  g_aszTextureNames[ETextures.eTexReflection];
		else if( g_TextureList[i] === "cubemap")
			pTexture =  g_aszTextureNames[ETextures.eTexCubeMap];
		else if(g_TextureList[i] === "anisotropicmap")
			pTexture = g_aszTextureNames[ETextures.eTexAnisotropic];



            (function(gl, demo)
            {
            
                PVRTexture.loadFromURI(gl, pTexture, i,
                    function(gl, id, h)
                    {
                
                 demo.m_puiTextureHandle[i] = id;
                        //Clear the bit that denotes that this texture is loaded
             //    demo.m_uiTextureFlags[i] = (h.numFaces===6?PVRTEX_CUBEMAP:0) | (h.MIPMapCount>1?PVRTEX_MIPMAP:0) | (h.depth>1?PVRTEX_VOLUME:0);



    //              this.version = 0x03525650;
    // this.flags = 0;
    // this.pixelFormatH = 0;
    // this.pixelFormatL = 0;
    // this.colourSpace = 0;
    // this.channelType = 0;
    // this.height = 1;
    // this.width = 1;
    // this.depth = 1;
    // this.numSurfaces = 1;
    // this.numFaces = 1;
    // this.MIPMapCount = 1;
    // this.metaDataSize = 0;

                        // if(gl.getError()) { 
                        //  alert(sTextureName) ;  
                        //  alert("Error while loading texture!"); }
                      
                        // gl.bindTexture(gl.TEXTURE_2D, id);

                        // gl.texParameterf(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR_MIPMAP_LINEAR);
                        // gl.texParameterf(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
                       
                        //    gl.texParameterf(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.REPEAT);
                        //    gl.texParameterf(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.REPEAT);
                       
                          // gl.texParameterf(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
                          // gl.texParameterf(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
                       

                     //    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
                     //     gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
                     //    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
                     //     gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
                        
                        demo.LoadingTextures  -= 1;//&= ~(1<<1);
                    
                    });
            })(gl, this);





		// if(PVRTexture.loadFromPVR(pTexture, this.m_puiTextureHandle[i], header))
		// {
		// 	console.log("ERROR: Could not open texture file ",pTexture);
		// 	//PVRShellSet(prefExitMessage, ErrorStr.c_str());
		// 	return false;
		// }

		// // Get texture flags form the header
		// this.m_uiTextureFlags[i] = (Header.u32NumFaces===6?PVRTEX_CUBEMAP:0) | (Header.u32MIPMapCount>1?PVRTEX_MIPMAP:0) | (Header.u32Depth>1?PVRTEX_VOLUME:0);
	}

	/*
		Load the effect file
	*/
	for(let j = 0; j < g_numShaders; j++)
	{
		let		fileName = "";
		let 	nUnknownUniformCount  = 0;
	

		/*
			Parse the file
		*/
		this.m_ppEffectParser[j] = new PVRPFXParser();
		fileName = g_ShaderList[j] + ".pfx";

		this.m_ppEffectParser[j].parseFromFile(gl, this,fileName,function(gl,demo, parser){
           
            // let  uiNumEffects = parser.getNumberEffects();

            // demo.LoadingEffects = uiNumEffects;

            // demo.m_ppPFXEffects = new  Array(uiNumEffects)   ;   //CPVRTPFXEffect*[uiNumEffects];
            // demo.m_pUniformMapping = new Array(uiNumEffects);  //CPVRTMap<int,int> 
        
            // for (let  i=0; i < uiNumEffects; i++) {
            //     demo.m_ppPFXEffects[i] = new PVRPFXEffect(m_sContext);
            //     demo.m_pUniformMapping[i] = new Map();
            // }
		// {
		// 	console.log("Parse failed for ", fileName ,":\n\n" );
			
		// 	this.freeMemory(gl);
		// 	return false;
		// }

		/*
			Load the effect from the file
		*/
	
        demo.m_ppEffect[j] = new PVRPFXEffect();
        
        demo.m_ppEffect[j].load(gl, parser, "myEffect", null, null, nUnknownUniformCount,  
        function(gl,demo){


            const  sTex = demo.m_ppEffect[j].getTextureArray();  //CPVRTArray<SPVRTPFXTexture>&

            // Loop over textures used in the CPVRTPFXEffect
            for(let i = 0; i < sTex.length; ++i)
            {
                let iTexIdx = demo.m_ppEffectParser[j].findTextureByName(sTex[i].Name);
                const fileName = demo.m_ppEffectParser[j].getTexture(iTexIdx).fileName;
    
                let k = 0;
                // Loop over available textures
                for( k = 0; k < g_numTextures; k++)
                {
                    let texName;
                    texName =  g_TextureList[k] + ".pvr";
                    if(fileName === texName)
                    {
                        // Set the current texture
                        if((demo.m_uiTextureFlags[k] & CUBEMAP_FLAG) !== 0)
                            gl.bindTexture(gl.TEXTURE_CUBE_MAP, demo.m_puiTextureHandle[k]);
                        else
                            gl.bindTexture(gl.TEXTURE_2D, demo.m_puiTextureHandle[k]);
    
                        // Link the texture to the CPVRTPFXEffect and apply filtering
                        demo.m_ppEffect[j].setTexture(gl, i, demo.m_puiTextureHandle[k], demo.m_uiTextureFlags[k]);
    
                        break;
                    }
                }
                if(k === g_numTextures)
                {
                    // Texture not found
                    console.log("Warning: effect file requested unrecognised texture: \"%s\"\n", fileName);
                    demo.m_ppEffect[j].setTexture(gl, i, 0);
                }
            }


        },demo);
    })
		// if(demo.m_ppEffect[j].load(m_ppEffectParser[j], "myEffect", fileName, null, nUnknownUniformCount) )
		// {
		// 	console.log("fail to load effect");
		// 	this.freeMemory(gl);
		// 	return false;
		// }

		// if(nUnknownUniformCount)
		// {
		// 	console.log("PFX File: %s\n%s Unknown uniform semantic count: %d\n", fileName, nUnknownUniformCount);
		// 	//PVRShellSet(prefExitMessage, error.c_str());

		// 	this.freeMemory(gl);
		// 	return false;
		// }
		// if(!error.empty())
		// {
		// 	PVRShellOutputDebug(error.c_str());
		// }

		/*
			Link the textrues to the effect.
		*/
	
	}

	gl.enable(gl.DEPTH_TEST);
	gl.disable(gl.CULL_FACE);

	// Create the surface
	m_Surface = new ParametricSurface(gl, 50,50);
	this.computeSurface(gl, m_nCurrentSurface);

	return true;
}
/*!****************************************************************************
 @Function		ReleaseView
 @Return		bool		true if no error occurred
 @Description	Code in ReleaseView() will be called by PVRShell when the
				application quits or before a change in the rendering context.
******************************************************************************/
 WebGLShaders.prototype.releaseView = function(gl)
{
	for(let j = 0; j < g_numShaders; j++)
	{
		// Release textures
		const  sTex = this.m_ppEffect[j].getTextureArray();  //CPVRTArray<SPVRTPFXTexture>&
		for(let i = 0; i < sTex.length; ++i)
			gl.deleteTexture(sTex[i].ui);

		// Release the effect[s] then the parser
		 this.m_ppEffect[j] = null;
		 this.m_ppEffectParser[j] = null;
	}

	m_Print3D.releaseTextures();

	if(m_Surface)
	{
		 m_Surface = null
	}

	return true;
}
/*!****************************************************************************
 @Function		RenderScene
 @Return		bool		true if no error occurred
 @Description	Main rendering loop function of the program. The shell will
				call this function every frame.
				eglSwapBuffers() will be performed by PVRShell automatically.
				PVRShell will also manage important OS events.
				Will also manage relevant OS events. The user has access to
				these events through an abstraction layer provided by PVRShell.
******************************************************************************/
 WebGLShaders.prototype.renderScene = function(gl, PVRShell)
{

    if (!this.startLoadingTextures) { 
        this.startLoadingTextures = true
        this.loadTextures(gl, PVRShell)}
    if  (this.LoadingTextures > 0) return true;

   
    
   if  (this.loadingEffects > 0) {
    this.loadEffects(gl, PVRShell)   
    return true;
   }

   if (!this.startLoadModels) { 
    this.startLoadModels = true
this.loadModels(gl)
}
//if  (this.loadingModels > 0) return true;


	gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);

	// Keyboard input (cursor to change shaders and meshes)
	if (PVRShell.isKeyPressed(PVRShell.KeyNameLEFT))
	{
		m_nCurrentShader--;
		if(m_nCurrentShader<0) m_nCurrentShader=(g_numShaders-1);
	}
	if (PVRShell.isKeyPressed(PVRShell.KeyNameRIGHT))
	{
		m_nCurrentShader++;
		if(m_nCurrentShader>(g_numShaders-1)) m_nCurrentShader=0;
	}
	if (PVRShell.isKeyPressed(PVRShell.KeyNameDOWN))
	{
		m_nCurrentSurface--;
		if(m_nCurrentSurface<0) m_nCurrentSurface=(g_numSurfaces-1);
		this.computeSurface(gl, m_nCurrentSurface);
	}
	if (PVRShell.isKeyPressed(PVRShell.KeyNameUP))
	{
		m_nCurrentSurface++;
		if(m_nCurrentSurface>(g_numSurfaces-1)) m_nCurrentSurface=0;
		this.computeSurface(gl, m_nCurrentSurface);
	}

	// Draw the mesh
	this.computeViewMatrix();
	this.drawModel(gl);

	// Display screen info
	m_Print3D.displayDefaultTitle("Shaders", null,  EPVRPrint3D.Logo.PowerVR);
    m_Print3D.print3D(0.3, 7.5, 0.75, 0xFFFFFFFF, `Shader: ${g_ShaderList[m_nCurrentShader]} `) ;
    m_Print3D.print3D(0.3, 17.5, 0.75, 0xFFFFFFFF, `Mesh: ${g_SurfacesList[m_nCurrentSurface]}`) ;
    m_Print3D.flush(gl);
	return true;
}

/*******************************************************************************
 * Function Name : DrawModel
 * Description   : Draws the model
 *******************************************************************************/
 WebGLShaders.prototype.drawModel = function(gl)
{
    // Use the loaded effect
  
	this.m_ppEffect[m_nCurrentShader].activate(gl);

	/*
		Set attributes and uniforms
	*/
	const  Uniforms = this.m_ppEffect[m_nCurrentShader].getUniformArray();  //CPVRTArray<SPVRTPFXUniform>&
    gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, m_Surface.iIBO);	
	for(let j = 0; j < Uniforms.length; ++j)
	{

		switch(Uniforms[j].nSemantic)
		{
		case EPVRTPFXUniformSemantic.ePVRTPFX_UsPOSITION:
			
				gl.bindBuffer(gl.ARRAY_BUFFER, m_Surface.iVertexVBO);
				gl.vertexAttribPointer(Uniforms[j].nLocation, 3, gl.FLOAT, gl.FALSE, 0, null);
				gl.enableVertexAttribArray(Uniforms[j].nLocation);
			
			break;
		case EPVRTPFXUniformSemantic.ePVRTPFX_UsNORMAL:
			
				gl.bindBuffer(gl.ARRAY_BUFFER, m_Surface.iNormalVBO);
				gl.vertexAttribPointer(Uniforms[j].nLocation, 3, gl.FLOAT, gl.FALSE, 0, null);
				gl.enableVertexAttribArray(Uniforms[j].nLocation);
			
			break;
		case EPVRTPFXUniformSemantic.ePVRTPFX_UsUV:
			
				gl.bindBuffer(gl.ARRAY_BUFFER, m_Surface.iUvVBO);
				gl.vertexAttribPointer(Uniforms[j].nLocation, 2, gl.FLOAT, gl.FALSE, 0, null);
				gl.enableVertexAttribArray(Uniforms[j].nLocation);
			
			break;
		case EPVRTPFXUniformSemantic.ePVRTPFX_UsWORLDVIEWPROJECTION:
			
				 // = new PVRMatrix4x4(); //PVRTMat4

				/* Passes the model-view-projection matrix (MVP) to the shader to transform the vertices */
				let mMVP = PVRMatrix4x4.matrixMultiply( m_mProjection , m_mModelView);
				gl.uniformMatrix4fv(Uniforms[j].nLocation,  gl.FALSE, mMVP.data);
			
			break;
		case EPVRTPFXUniformSemantic.ePVRTPFX_UsWORLDVIEW:
			
				gl.uniformMatrix4fv(Uniforms[j].nLocation,  gl.FALSE, m_mModelView.data);
			
			break;
		case EPVRTPFXUniformSemantic.ePVRTPFX_UsWORLDVIEWIT:
			
				//PVRTMat4 mModelViewI, mModelViewIT;

				/* Passes the inverse transpose of the model-view matrix to the shader to transform the normals */
				let mModelViewI = PVRMatrix4x4.inverse( m_mModelView) ;//.inverse();
				let mModelViewIT= PVRMatrix4x4.transpose(  mModelViewI); //.transpose();
				let ModelViewIT = PVRMatrix4x4.createMatrix3x3(mModelViewIT);

				gl.uniformMatrix3fv(Uniforms[j].nLocation,  gl.FALSE, ModelViewIT.data);
			
			break;
		case EPVRTPFXUniformSemantic.ePVRTPFX_UsVIEWIT:
			
			//	PVRTMat4 mViewI, mViewIT;

				/* Passes the inverse transpose of the model-view matrix to the shader to transform the normals */
			 let	mViewI = PVRMatrix4x4.inverse(  m_mView) ;// .inverse();
             let	mViewIT= PVRMatrix4x4.transpose( mViewI); //.transpose();
             let ViewIT = PVRMatrix4x4.createMatrix3x3(mViewIT);

				gl.uniformMatrix3fv(Uniforms[j].nLocation,  gl.FALSE, ViewIT.data);
			
			break;
		case EPVRTPFXUniformSemantic.ePVRTPFX_UsTEXTURE:
			
				// Set the sampler variable to the texture unit
				gl.uniform1i(Uniforms[j].nLocation, Uniforms[j].nIdx);
			
			break;
		case EPVRTPFXUniformSemantic.ePVRTPFX_UsANIMATION:
			
				// Float in the range 0..1: contains this objects distance through its animation.
				let fAnimation = 0.5 * m_fViewAngle / PVRT_PI;
				gl.uniform1f(Uniforms[j].nLocation, fAnimation);
			
            break;
             default: break;
		}
	}

	// Unbind the last buffer used.
 
	gl.drawElements(gl.TRIANGLES, m_Surface.getNumFaces()*3, gl.UNSIGNED_SHORT, 0);//m_Surface.pIndex);
    gl.bindBuffer(gl.ARRAY_BUFFER, null);	
    gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, null);	
	/*
		Disable attributes
	*/
	for(let j = 0; j < Uniforms.length; ++j)
	{
		switch(Uniforms[j].nSemantic)
		{
		case EPVRTPFXUniformSemantic.ePVRTPFX_UsPOSITION:
			
				gl.disableVertexAttribArray(Uniforms[j].nLocation);
			
			break;
		case EPVRTPFXUniformSemantic.ePVRTPFX_UsNORMAL:
			
				gl.disableVertexAttribArray(Uniforms[j].nLocation);
			
			break;
		case EPVRTPFXUniformSemantic.ePVRTPFX_UsUV:
			
				gl.disableVertexAttribArray(Uniforms[j].nLocation);
			
            break;
            default: break;
		}
	}

	return;
}

/*!****************************************************************************
 @Function		ComputeSurface
 @Return		none
 @Description	Compute the a surface
******************************************************************************/
 WebGLShaders.prototype.computeSurface = function(gl, nSurface)
{
	m_Surface.computeVertexAndNormals( gl, SurfaceList[nSurface].function,
										SurfaceList[nSurface].fMinU,
										SurfaceList[nSurface].fMaxU,
										SurfaceList[nSurface].fMinV,
                                        SurfaceList[nSurface].fMaxV)
}

/*******************************************************************************
 * Function Name  : ComputeViewMatrix
 * Description    : Calculate the view matrix turning around the balloon
 *******************************************************************************/
 WebGLShaders.prototype.computeViewMatrix = function()
{
	let factor = 1.0 * 0.03;

    let vFrom = new PVRVector3();
    let vTo = new PVRVector3();
    let vUp = new PVRVector3();

	vTo.data[0] = 0;
	vTo.data[1] = TO_ALTITUDE;
	vTo.data[2] = 0;

	vUp.data[0] = 0;
	vUp.data[1] = 1;
	vUp.data[2] = 0;

	/* Calculate the angle of the camera around the balloon */
	vFrom.data[0] = CAMERA_DISTANCE * Math.cos(m_fViewAngle);
	vFrom.data[1] = ALTITUDE;
	vFrom.data[2] = CAMERA_DISTANCE * Math.sin(m_fViewAngle);
	m_fViewAngle += factor;

	while(m_fViewAngle > 2*PVRT_PI)
		m_fViewAngle = m_fViewAngle - 2*PVRT_PI;

	/* Compute and set the matrix */
	m_mModelView = PVRMatrix4x4.createLookAt(vFrom, vTo, vUp);
}

/*!****************************************************************************
 @Function		FreeMemory
 @Description	Free allocated memory.
******************************************************************************/
 WebGLShaders.prototype.freeMemory = function(gl)
{
	for(let j = 0; j < g_numShaders; j++)
	{
		// Release textures
		if(this.m_ppEffect[j])
		{
			let sTex = this.m_ppEffect[j].getTextureArray();  //const CPVRTArray<SPVRTPFXTexture>& </SPVRTPFXUniform>
			for(let i = 0; i < sTex.length; ++i)
				gl.deleteTexture(sTex[i].ui);
		}

		// Release the effect[s] then the parser
		if(this.m_ppEffect[j])  this.m_ppEffect[j] = null;
		if(this.m_ppEffectParser[j])  this.m_ppEffectParser[j] = null;
	}

	m_Print3D.releaseTextures();
}

}

export default WebGLShaders

/*******************************************************************************
 * Function Name : NewDemo
 * Returns       : true if no problem
 * Description   : Register our demo class
 *******************************************************************************/
// PVRShell* NewDemo()
// {
// 	return new OGLES2Shaders();
// }

/***********************************************


/*****************************************************************************
 End of file (OGLES2Shaders.cpp)
*****************************************************************************/


