
	export const PVRSkybox = function(scale, adjustUV, textureSize)
    {
        const setVertex = function(vertices, index, x, y, z, s, t)
        {
            vertices[index*5+0] = x;
            vertices[index*5+1] = y;
            vertices[index*5+2] = z;
            vertices[index*5+3] = s;
            vertices[index*5+4] = t;
        }

        let vertices = [];
        let unit     = 1;
        let a0 = 0, a1 = unit;

        if(adjustUV)
        {
            let oneOver = 1.0 / textureSize;
            a0 = 4.0 * oneOver;
            a1 = unit - a0;
        }

        // Front
         setVertex(vertices, 0, -unit, +unit, -unit, a0, a1);
         setVertex(vertices, 1, +unit, +unit, -unit, a1, a1);
         setVertex(vertices, 2, -unit, -unit, -unit, a0, a0);
         setVertex(vertices, 3, +unit, -unit, -unit, a1, a0);

        // Right
         setVertex(vertices, 4, +unit, +unit, -unit, a0, a1);
         setVertex(vertices, 5, +unit, +unit, +unit, a1, a1);
         setVertex(vertices, 6, +unit, -unit, -unit, a0, a0);
         setVertex(vertices, 7, +unit, -unit, +unit, a1, a0);

        // Back
         setVertex(vertices, 8 , +unit, +unit, +unit, a0, a1);
         setVertex(vertices, 9 , -unit, +unit, +unit, a1, a1);
         setVertex(vertices, 10, +unit, -unit, +unit, a0, a0);
         setVertex(vertices, 11, -unit, -unit, +unit, a1, a0);

        // Left
         setVertex(vertices, 12, -unit, +unit, +unit, a0, a1);
         setVertex(vertices, 13, -unit, +unit, -unit, a1, a1);
         setVertex(vertices, 14, -unit, -unit, +unit, a0, a0);
         setVertex(vertices, 15, -unit, -unit, -unit, a1, a0);

        // Top
         setVertex(vertices, 16, -unit, +unit, +unit, a0, a1);
         setVertex(vertices, 17, +unit, +unit, +unit, a1, a1);
         setVertex(vertices, 18, -unit, +unit, -unit, a0, a0);
         setVertex(vertices, 19, +unit, +unit, -unit, a1, a0);

        // Bottom
         setVertex(vertices, 20, -unit, -unit, -unit, a0, a1);
         setVertex(vertices, 21, +unit, -unit, -unit, a1, a1);
         setVertex(vertices, 22, -unit, -unit, +unit, a0, a0);
         setVertex(vertices, 23, +unit, -unit, +unit, a1, a0);

        for (let i=0; i<24*5; i+=5)
        {
            vertices[i+0] = vertices[i+0] * scale;
            vertices[i+1] = vertices[i+1] * scale;
            vertices[i+2] = vertices[i+2] * scale;
        }

        return vertices;
	}

export default PVRSkybox;